import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {environment} from '../../environments/environment'
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  authorization:string|null=null;

  constructor(private httpClient: HttpClient) { }

  clearAuthorization(){
    this.authorization=null;
  }

  setAuthorization(newAuthorization){
    this.authorization=newAuthorization;
  }

  loadAuthorization(){
    if(!this.authorization){
      if(localStorage){
        this.authorization=localStorage.getItem("authorization");
      }
    }
  }

  getHeaders():HttpHeaders{
    this.loadAuthorization()
    var headers=new HttpHeaders();
    headers=headers.set("Content-Type", "application/json");
    if(this.authorization){
      headers=headers.set("authorization", this.authorization);
    }
    return headers;
  }

  get<T>(url:string,params?:any): Observable<T>{
    var httpOptions:Object={
      headers:this.getHeaders(),
      observe: 'response' as 'json',
      responseType: 'json' as 'json',
      params:params
    }
    return this.httpClient.get<HttpResponse<T>>(`${environment.baseUrl}${url}`,httpOptions).pipe(map((response:HttpResponse<T>) =>{
      var authorization= response.headers.get("authorization");
      if(authorization&&authorization!==this.authorization){
        this.authorization=authorization;
        localStorage.setItem("authorization",authorization);
      }
      return response.body;
    }))
  }

  post<T>(url:string,data:any,params?:any): Observable<T>{
    var httpOptions:Object={
      headers:this.getHeaders(),
      observe: 'response' as 'json',
      responseType: 'json' as 'json',
      params:params
    }
    return this.httpClient.post<HttpResponse<T>>(`${environment.baseUrl}${url}`,data,httpOptions).pipe(map((response:HttpResponse<T>) =>{
      var authorization= response.headers.get("Authorization");
      if(authorization&&authorization!==this.authorization){
        this.authorization=authorization;
        localStorage.setItem("authorization",authorization);
      }
      return response.body;
    }))
  }

  put<T>(url:string,data:any,params?:any): Observable<T>{
    var httpOptions:Object={
      headers:this.getHeaders(),
      observe: 'response' as 'json',
      responseType: 'json' as 'json',
      params:params
    }
    return this.httpClient.put<HttpResponse<T>>(`${environment.baseUrl}${url}`,data,httpOptions).pipe(map((response:HttpResponse<T>) =>{
      var authorization= response.headers.get("authorization");
      if(authorization&&authorization!==this.authorization){
        this.authorization=authorization;
        localStorage.setItem("authorization",authorization);
      }
      return response.body;
    }))
  }

  delete<T>(url:string,params?:any): Observable<T>{
    var httpOptions:Object={
      headers:this.getHeaders(),
      observe: 'response' as 'json',
      responseType: 'json' as 'json',
      params:params
    }
    return this.httpClient.delete<HttpResponse<T>>(`${environment.baseUrl}${url}`,httpOptions).pipe(map((response:HttpResponse<T>) =>{
      var authorization= response.headers.get("authorization");
      if(authorization&&authorization!==this.authorization){
        this.authorization=authorization;
        localStorage.setItem("authorization",authorization);
      }
      return response.body;
    }))
  }

  getDownload(url: string,params?:any): Observable<any> {
    var httpOptions: Object = {
      headers: this.getHeaders(),
      reportProgress: true,
      withCredentials: true,
      responseType: 'blob' as 'json',
      params:params
    };
    return this.httpClient.get(`${environment.baseUrl}${url}`, httpOptions);
  }

  postDownload(url: string, body: any,params?:any): Observable<any> {
    var httpOptions: Object = {
      headers: this.getHeaders(),
      reportProgress: true,
      withCredentials: true,
      responseType: 'blob' as 'json',
      params:params
    };
    return this.httpClient.post(`${environment.baseUrl}${url}`, body, httpOptions);
  }
}
