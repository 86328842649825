import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-sys-logging-detail',
  templateUrl: './sys-logging-detail.component.html',
  styleUrls: ['./sys-logging-detail.component.css']
})
export class SysLoggingDetailComponent implements OnInit {
	error=null
	loading = false
  detailForm: FormGroup=this.formBuilder.group({
    action: [null ],
    accountId: ['' ,[ Validators.maxLength(200)] ],
    createUsername: ['' ,[ Validators.maxLength(200)] ],
    createDate: ['' ,[ Validators.maxLength(200)] ],
    loggingData: ['' ,[ Validators.maxLength(2000)] ],
    description: ['' ,[ Validators.maxLength(2000)] ],
    status: [null ]
  });
  loggingId=null;
  loggingAction="create";

	loggingActionOptionList=[
		{ value : "SYS_RSA_KEY_INSERT",label : "Thêm key"},
		{ value : "SYS_RSA_KEY_UPDATE",label : "Cập nhật key"},
		{ value : "SYS_RSA_KEY_DELETE",label : "Xóa key"},
		{ value : "SYS_APPLICATION_INSERT",label : "Thêm ứng dụng" },
		{ value : "SYS_APPLICATION_UPDATE",label : "Cập nhật ứng dụng" },
		{ value : "SYS_APPLICATION_DELETE",label : "Xóa ứng dụng" },
		{value : "SYS_PERM_INSERT",label : "Thêm quyền"},
		{value : "SYS_PERM_UPDATE",label : "Cập nhật quyền"},
		{value : "SYS_PERM_MOVE_UP",label : "Cập nhật thứ tự quyền"},
		{value : "SYS_PERM_MOVE_DOWN",label : "Cập nhật thứ tự quyền"},
		{value : "SYS_PERM_DELETE",label : "Xóa quyền"},
		{value : "SYS_USER_INSERT",label : "Thêm người dùng"},
		{value : "SYS_USER_UPDATE",label : "Cập người dùng"},
		{value : "SYS_USER_CHANGE_PASSWORD",label : "Đổi mật khẩu người dùng"},
		{value : "SYS_BRANCH_INSERT",label : "Thêm chi nhánh"},
		{value : "SYS_BRANCH_UPDATE",label : "Cập nhật chi nhánh"},
		{value : "SYS_BRANCH_DELETE",label : "Xóa chi nhánh"},
		{value : "SYS_ROLE_INSERT",label : "Thêm role"},
		{value : "SYS_ROLE_UPDATE",label : "Cập role"},
		{value : "SYS_ROLE_DELETE",label : "Xóa role"}
	]
	loggingStatusOptionList=[
		{ value : 0, label : "Ngừng sử dụng" },
		{ value : 1, label : "Đang sử dụng" },
		{ value : 2, label : "Đã bị khóa" }
	]

  constructor(private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.detailForm.disable()
    this.route.params.subscribe(params=>{
      this.error=null;
      if(params.loggingId){
        this.loggingAction="view";
        if(params.loggingAction){
          this.loggingAction=params.loggingAction;
        }
          this.loggingId=params.loggingId;
          this.loading=true;
          this.httpService.get<any>(`/api/admin/sys/logging/${this.loggingId}`).subscribe(response=>{
            this.loading=false;
            if(response.result){
              this.detailForm.patchValue(response.result);
            }else if(response.errorCode){
              this.error=response.errorCode+":"+response.errorMessage;
            }
        },errorResponse=>{
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if(errorResponse.status>0){
            if(errorResponse.error?.errorCode){
              this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error="Lỗi kết nối! Vui lòng thử lại sau";
        })
      }
    })
  }

  cancel(){
      this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
