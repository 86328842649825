import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';
import * as cryptoJS from 'crypto-js';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.css']
})
export class UserPasswordComponent implements OnInit {
	error=null
	loading = false
  detailForm: FormGroup = this.formBuilder.group({
    fullname: [{value:"",disabled:true}, [Validators.required, Validators.maxLength(50)]],
    username: [{value:"",disabled:true}, [Validators.required, Validators.maxLength(50)]],
    oldPassword: ["", [Validators.required, Validators.maxLength(200)]],
    password: ["", [Validators.required, Validators.maxLength(200)]],
    confirmPassword: ["", [Validators.required, Validators.maxLength(200)]],
    status: [{value:0,disabled:true}, [Validators.required]]
  });

  statusOptionList = [
    { value: 0, label: "Ngừng sử dụng" },
    { value: 1, label: "Sử dụng" }
  ]

  constructor(private formBuilder: FormBuilder, private httpService: BaseHttpService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.loading=true;
    this.httpService.get<any>(`/api/auth/user-detail`).subscribe(response => {
			this.loading=false;
      if (response.result) {
        this.detailForm.patchValue(response.result);
      } else if (response.errorCode) {
        this.error = response.errorCode + ":" + response.errorMessage;
      }
    }, errorResponse => {
			this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if (errorResponse.status > 0) {
        if (errorResponse.error?.errorCode) {
          this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error = "Lỗi kết nối! Vui lòng thử lại sau";
    })
  }

  submit() {
		if(this.loading){
			return;
		}
    this.error = null;
    var detailData = this.detailForm.getRawValue();
    if (!detailData.oldPassword) {
      this.error = "Nhập mật khẩu cũ";
      return;
    }
    if (!detailData.password) {
      this.error = "Nhập mật khẩu";
      return;
    }
    if (!detailData.confirmPassword) {
      this.error = "Nhập lại mật khẩu";
      return;
    }
    if (detailData.password !== detailData.confirmPassword) {
      this.error = "Mật khẩu không trùng nhau";
      return;
    }
    var oldpasswordSHA256 = cryptoJS.HmacSHA256(detailData.username, detailData.oldPassword);
    detailData.oldPassword = `${oldpasswordSHA256}`;
    var passwordSHA256 = cryptoJS.HmacSHA256(detailData.username, detailData.password);
    detailData.password = `${passwordSHA256}`;
    detailData.confirmPassword = null;
    this.loading=true;
    this.httpService.post<any>(`/api/auth/user-password`, detailData).subscribe(response => {
			this.loading=false;
      if (response.result) {
        this.router.navigate(['/login', { action: 'password' }], { relativeTo: this.route });
      } else if (response.errorCode) {
        this.error = response.errorCode + ":" + response.errorMessage;
      }
    }, errorResponse => {
			this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if (errorResponse.status > 0) {
        if (errorResponse.error?.errorCode) {
          this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error = "Lỗi kết nối! Vui lòng thử lại sau";
    })
  }
}
