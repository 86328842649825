import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';
import {environment} from 'src/environments/environment'

@Component({
  selector: 'app-otp-request-detail',
  templateUrl: './otp-request-detail.component.html',
  styleUrls: ['./otp-request-detail.component.css']
})
export class OtpRequestDetailComponent implements OnInit {
	error=null
	loading = false
  hasSupervisorPerm = false;
	submitSuccess = false
  detailForm: FormGroup = this.formBuilder.group({
    action: ["", [Validators.required]],
    accountId: ["", [Validators.required, Validators.maxLength(50)]],
    createApplicationCode: ["", [Validators.required, Validators.maxLength(200)]],
    createDate: ["", [Validators.required, Validators.maxLength(200)]],
    note: ["", [Validators.maxLength(2000)]],
    status: ["", [Validators.required]]
  });
  requestId = null;
  requestAction = "create";
	requestActionOptionList = [
		{ value: "INITIALIZATION", label: "Kích hoạt Smart OTP" },
		{ value: "OTP_TOKEN_DISABLE", label: "Ngừng sử dụng Token" },
		{ value: "OTP_TOKEN_LOCK", label: "Khóa token" },
		{ value: "OTP_TOKEN_UNLOCK", label: "Mở khóa token" }
	]

  statusOptionList = [
		{ value: 0, label: "Mới tạo" },
		{ value: 1, label: "Chờ kiểm soát viên phê duyệt" },
		{ value: 2, label: "Kiểm soát viên đã phê duyệt" },
		{ value: 3, label: "Kiểm soát viên từ chối" }
  ]
  qrImageUrl=null;
  initializationCode=null;

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder, private httpService: BaseHttpService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.authService.hasAnyPerm(['OTP_SUPERVISOR']).subscribe(hasPerm => {
      this.hasSupervisorPerm = hasPerm;
    })
    this.detailForm.disable()
    this.route.params.subscribe(params => {
      this.error = null;
      if (params.requestId) {
        this.requestAction = "view";
        if (params.requestAction) {
          this.requestAction = params.requestAction;
        }
        if(this.requestAction!=="view"){
          this.detailForm.get('note').enable();
        }
        this.requestId = params.requestId;
        this.loading=true;
        this.httpService.get<any>(`/api/admin/otp/request/${this.requestId}`).subscribe(response => {
          this.loading=false;
          if (response.result) {
            this.detailForm.patchValue(response.result);
          } else if (response.errorCode) {
            this.error = response.errorCode + ":" + response.errorMessage;
          }
          if(this.requestAction==="view"&&response.result.status==2&&response.result.otpInitializationId){
            this.loading=true;
            this.httpService.get<any>(`/api/admin/otp/initialization/${response.result.otpInitializationId}`).subscribe(otpInitializationResponse => {
              this.loading=false;
              if (otpInitializationResponse.result) {
                this.initializationCode=otpInitializationResponse.result.initializationCode;
                this.qrImageUrl=`${environment.baseUrl}/api/qr/${otpInitializationResponse.result.initializationCode}`;
              } else if (otpInitializationResponse.errorCode) {
                this.error = otpInitializationResponse.errorCode + ":" + otpInitializationResponse.errorMessage;
              }
            }, errorResponse => {
              this.loading=false;
              if(errorResponse.status==403){
                this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
                return;
              }
              if (errorResponse.status > 0) {
                if (errorResponse.error?.errorCode) {
                  this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
                  return;
                }
                this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
                return;
              }
              this.error = "Lỗi kết nối! Vui lòng thử lại sau";
            })
          }
        }, errorResponse => {
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if (errorResponse.status > 0) {
            if (errorResponse.error?.errorCode) {
              this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
              return;
            }
            this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
            return;
          }
          this.error = "Lỗi kết nối! Vui lòng thử lại sau";
        })
      }
    })
  }

  submit() {
		if(this.loading){
			return;
		}
    this.error = null;
    var detailFormData=this.detailForm.getRawValue()
    if (this.requestAction === "teller-approve") {
      this.loading=true;
      this.httpService.post<any>(`/api/admin/otp/request/${this.requestId}/teller-approve`,{note:detailFormData["note"]}).subscribe(response => {
        this.loading=false;
        if (response.result) {
          this.submitSuccess=true;
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }
    if (this.requestAction === "supervisor-approve") {
      this.loading=true;
      this.httpService.post<any>(`/api/admin/otp/request/${this.requestId}/supervisor-approve`,{note:detailFormData["note"]}).subscribe(response => {
        this.loading=false;
        if (response.result) {
          this.submitSuccess=true;
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }
    if (this.requestAction === "supervisor-reject") {
      this.loading=true;
      this.httpService.post<any>(`/api/admin/otp/request/${this.requestId}/supervisor-reject`,{note:detailFormData["note"]}).subscribe(response => {
        this.loading=false;
        if (response.result) {
          this.submitSuccess=true;
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }
  }
  cancel() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
