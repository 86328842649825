import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-dev-logging-detail',
  templateUrl: './dev-logging-detail.component.html',
  styleUrls: ['./dev-logging-detail.component.css']
})
export class DevLoggingDetailComponent implements OnInit {
	error=null
	loading = false
  detailForm: FormGroup=this.formBuilder.group({
    action: [null ],
    accountId: ['' ,[ Validators.maxLength(200)] ],
    createApplicationCode: ['' ,[ Validators.maxLength(200)] ],
    createUsername: ['' ,[ Validators.maxLength(200)] ],
    createDate: ['' ,[ Validators.maxLength(200)] ],
    loggingData: ['' ,[ Validators.maxLength(2000)] ],
    description: ['' ,[ Validators.maxLength(2000)] ],
    status: [null ]
  });
  loggingId=null;
  loggingAction="create";

	loggingActionOptionList=[
		{ value : "DEV_DEVICE_INSERT", label : "Thêm thiết bị" },
		{ value : "DEV_DEVICE_UPDATE", label : "Cập nhật thiết bị" },
		{ value : "DEV_DEVICE_UNLOCK", label : "Mở khóa thiết bị" },
		{ value : "DEV_DEVICE_LOCK", label : "Khóa thiết bị" },
		{ value : "DEV_DEVICE_DISABLE", label : "Ngừng sử dụng thiết bị" },
		{ value : "DEV_MODEL_INSERT",label : "Thêm mẫu thiết bị" },
		{ value : "DEV_MODEL_UPDATE",label : "Cập nhật mẫu thiết bị" },
		{ value : "DEV_MODEL_DELETE",label : "Xóa mẫu thiết bị" },
		{ value : "DEV_BRAND_INSERT",label : "Thêm thương hiệu" },
		{ value : "DEV_BRAND_UPDATE",label : "Cập nhật thương hiệu" },
		{ value : "DEV_BRAND_DELETE",label : "Xóa thương hiệu" },
		{ value : "DEV_MANUFACTURER_INSERT",label : "Thêm nhà sản xuất" },
		{ value : "DEV_MANUFACTURER_UPDATE",label : "Cập nhật nhà sản xuất" },
		{ value : "DEV_MANUFACTURER_DELETE",label : "Xóa nhà sản xuất" },
		{ value : "DEV_SYSTEM_INSERT",label :"Thêm hệ điều hành" },
		{ value : "DEV_SYSTEM_UPDATE",label :"Cập nhật hệ điều hành" },
		{ value : "DEV_SYSTEM_DELETE",label :"Xóa hệ điều hành" },
		{ value : "DEV_SYSTEM_VERSION_INSERT",label :"Thêm phiên bản hệ điều hành" },
		{ value : "DEV_SYSTEM_VERSION_UPDATE",label :"Cập nhật phiên bản hệ điều hành" },
		{ value : "DEV_SYSTEM_VERSION_DELETE",label :"Xóa phiên bản hệ điều hành" }
	]
	loggingStatusOptionList=[
		{ value : 0, label : "Xác thực OTP lỗi" },
		{ value : 1, label : "Xác thực OTP thành công" }
	]

  constructor(private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.detailForm.disable()
    this.route.params.subscribe(params=>{
      this.error=null;
      if(params.loggingId){
        this.loggingAction="view";
        if(params.loggingAction){
          this.loggingAction=params.loggingAction;
        }
          this.loggingId=params.loggingId;
          this.loading=true;
          this.httpService.get<any>(`/api/admin/dev/logging/${this.loggingId}`).subscribe(response=>{
            this.loading=false;
            if(response.result){
              this.detailForm.patchValue(response.result);
            }else if(response.errorCode){
              this.error=response.errorCode+":"+response.errorMessage;
            }
        },errorResponse=>{
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if(errorResponse.status>0){
            if(errorResponse.error?.errorCode){
              this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error="Lỗi kết nối! Vui lòng thử lại sau";
        })
      }
    })
  }

  cancel(){
      this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
