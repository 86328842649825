import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  chartList=[
    {title:'Kích hoạt Smart OTP',statisticType:'otp_token_enroll',dataInterval:'day',chartType:'bar'},
    {title:'Xác thực lỗi',statisticType:'otp_token_verify_error',dataInterval:'day',chartType:'bar'},
    {title:'Token theo trạng thái',statisticType:'otp_token_status',dataInterval:'all',chartType:'pie'},
    {title:'Thiết bị theo hệ điều hành',statisticType:'dev_device_system',dataInterval:'all',chartType:'pie'},
    {title:'Thiết bị theo hãng',statisticType:'dev_device_brand',dataInterval:'all',chartType:'pie'},
    {title:'Thiết bị theo nhà sản xuất',statisticType:'dev_device_manufacturer',dataInterval:'all',chartType:'pie'}
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
