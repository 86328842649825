<div class="form-group" >
    <label *ngIf="label">{{label}}</label>
    <div class="input-group mb-3" *ngIf="!searching">
        <input class="form-control" type="text" [disabled]="disabled" [(ngModel)]="valueLabel" (focus)="inputFocus()"/>
        <div class="input-group-append" *ngIf="!disabled">
          <button class="btn btn-outline-secondary" type="button" (click)="clearValue()">X</button>
        </div>
    </div>
    <input #searchInput [ngStyle]="{display:searching?'block':'none'}" class="form-control" type="text" [disabled]="disabled" [(ngModel)]="searchValue" (ngModelChange)="searchChange($event)" (focusout)="inputFocusout()" />
   
    <div class="autocomplete-container" *ngIf="searching && searchOptionList" [ngStyle]="{width:inputWidth+'px'}">
        <div class="autocomplete-option" *ngFor="let option of searchOptionList" (click)="optionSelect(option)">
            {{option.label}}
        </div>
    </div>
    <ng-container *ngIf="formControl&& formControl.invalid">
        <small *ngIf="formControl.errors.required" class="form-text text-danger">
            Trường thông tin bắt buộc.
        </small>
    </ng-container>
</div>