import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  searchMap={};
  constructor() { }

  clear(){
    this.searchMap={};
  }

  save(name,formData,pagination){
    this.searchMap[name]={
      formData:formData,
      pagination:pagination
    };
  }

  get(name){
    return this.searchMap[name];
  }

  getFormData(name){
    var search= this.searchMap[name];
    if(search){
      return search.formData;
    }
    return {};
  }

  getPagination(name){
    var search= this.searchMap[name];
    if(search){
      return search.pagination;
    }
    return {
      currentPage: 1,
      pageSize: 10,
      pageCount: 0,
      rowCount: 0,
      pageList: null
    }
  }
}
