<div class="card" *ngIf="!submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông tin hệ điều hành</h5>
        <div class="alert alert-danger" role="alert" *ngIf="systemAction==='delete'">
          Bạn có chắc chắn muốn xóa hệ điều hành
        </div>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <app-input-text label="Tên" placeholder="Nhập tên" [formGroup]="detailForm" formControlName="name"></app-input-text>
            <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="detailForm" formControlName="status" [optionList]="statusOptionList"></app-input-select>
            <app-input-textarea label="Mô tả" placeholder="Nhập mô tả" [formGroup]="detailForm" formControlName="description" rows="5"></app-input-textarea>
            <app-input-textarea label="Ghi chú" placeholder="Nhập ghi chú" [formGroup]="detailForm" formControlName="note" rows="5"></app-input-textarea>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
                <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="hasEditPerm&&systemAction==='create'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Thêm mới</button>
                <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="hasEditPerm&&systemAction==='edit'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Cập nhật</button>
                <button type="button" class="btn btn-danger" (click)="submit()" *ngIf="hasEditPerm&&systemAction==='delete'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Xóa</button>
            </div>
            </div>
        </form>
    </div>
</div>
<div class="card" *ngIf="submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông báo</h5>
        <div class="alert alert-success" role="alert" *ngIf="systemAction==='create'">
          Thêm mới thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="systemAction==='edit'">
          Cập nhật thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="systemAction==='delete'">
          Xóa thành công
        </div>
        <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
            </div>
        </div>
    </div>
</div>