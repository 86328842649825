<div class="card" *ngIf="!submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông tin người dùng</h5>
        <div class="alert alert-danger" role="alert" *ngIf="userAction==='delete'">
          Bạn có chắc chắn muốn xóa người dùng
        </div>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <app-input-select label="Chi nhánh" placeholder="Chọn chi nhánh" [formGroup]="detailForm" formControlName="sysBranchId" [optionList]="sysBranchIdOptionList"></app-input-select>
            <app-input-text label="Tên đăng nhập" placeholder="Nhập tên đăng nhập" [formGroup]="detailForm" formControlName="username"></app-input-text>
            <app-input-text label="Họ tên" placeholder="Nhập họ tên" [formGroup]="detailForm" formControlName="fullname"></app-input-text>
            <app-input-text label="Thư điện tử" placeholder="Nhập thư điện tử" [formGroup]="detailForm" formControlName="email" *ngIf="userAction!=='password'"></app-input-text>
            <app-input-text label="Số điện thoại" placeholder="Nhập số điện thoại" [formGroup]="detailForm" formControlName="phoneNumber" *ngIf="userAction!=='password'"></app-input-text>
            <app-input-password label="Mật khẩu" placeholder="Nhập mật khẩu" [formGroup]="detailForm" formControlName="password"   *ngIf="userAction==='create' || userAction==='password'"></app-input-password>
            <app-input-password label="Nhập lại mật khẩu" placeholder="Nhập lại mật khẩu" [formGroup]="detailForm" formControlName="confirmPassword"   *ngIf="userAction==='create' || userAction==='password'"></app-input-password>
            <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="detailForm" formControlName="status" [optionList]="statusOptionList"  *ngIf="userAction!=='password'"></app-input-select>
            <app-input-textarea label="Ghi chú" placeholder="Nhập ghi chú" [formGroup]="detailForm" formControlName="note" rows="5"></app-input-textarea>
            <ng-container *ngIf="sysUserRoleList && userAction!=='password'">
                <div class="form-check" *ngFor="let sysUserRole of sysUserRoleList">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" [disabled]="userAction!=='create'&&userAction!=='edit'" [checked]="!sysUserRole.deletedEntity" (change)="sysUserRole.deletedEntity = !sysUserRole.deletedEntity">{{sysUserRole.sysRole.code}} - {{sysUserRole.sysRole.name}}
                    </label>
                  </div>
            </ng-container>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
                <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="userAction==='create'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Thêm mới</button>
                <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="userAction==='edit'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Cập nhật</button>
                <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="userAction==='password'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Đổi mật khẩu</button>
            </div>
            </div>
        </form>
    </div>
</div>
<div class="card" *ngIf="submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông báo</h5>
        <div class="alert alert-success" role="alert" *ngIf="userAction==='create'">
          Thêm mới thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="userAction==='edit'">
          Cập nhật thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="userAction==='password'">
          Đổi mật khẩu thành công
        </div>
        <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
            </div>
        </div>
    </div>
</div>