import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';
import { SearchService } from 'src/app/service/search.service';

@Component({
	selector: 'app-otp-request-list',
	templateUrl: './otp-request-list.component.html',
	styleUrls: ['./otp-request-list.component.css']
})
export class OtpRequestListComponent implements OnInit {
	error=null
	loading = false
	hasSupervisorPerm = false;
	otpRequestList = null
	otpRequestPagination = {
		currentPage: 1,
		pageSize: 10,
		pageCount: 0,
		rowCount: 0,
		pageList: null
	}
	searchForm: FormGroup = this.formBuilder.group({
		action: [null],
		accountIdLike: ['', [Validators.maxLength(200)]],
		status: [null]
	});
	requestActionOptionList = [
		{ value: null, label: "Tất cả" },
		{ value: "INITIALIZATION", label: "Kích hoạt Smart OTP" },
		{ value: "OTP_TOKEN_DISABLE", label: "Ngừng sử dụng Token" },
		{ value: "OTP_TOKEN_LOCK", label: "Khóa token" },
		{ value: "OTP_TOKEN_UNLOCK", label: "Mở khóa token" }
	]
	requestActionOptionMap = {}
	requestStatusOptionList = [
		{ value: null, label: "Tất cả" },
		{ value: 0, label: "Mới tạo" },
		{ value: 1, label: "Chờ kiểm soát viên phê duyệt" },
		{ value: 2, label: "Kiểm soát viên đã phê duyệt" },
		{ value: 3, label: "Kiểm soát viên từ chối" }
	]
	requestStatusOptionMap = {}
	constructor(private authService: AuthService,
		private formBuilder: FormBuilder,
		private httpService: BaseHttpService,
		private searchService:SearchService,
		private route: ActivatedRoute,
		private router: Router) { }
	ngOnInit(): void {
		this.authService.hasAnyPerm(['OTP_SUPERVISOR']).subscribe(hasPerm => {
			this.hasSupervisorPerm = hasPerm;
		})
		for (var requestActionOption of this.requestActionOptionList) {
			this.requestActionOptionMap[requestActionOption.value] = requestActionOption.label;
		}
		for (var requestStatusOption of this.requestStatusOptionList) {
			this.requestStatusOptionMap[requestStatusOption.value] = requestStatusOption.label;
		}
		var prevSearch=this.searchService.get("OtpRequestList");
		if(prevSearch){
			this.searchForm.patchValue(prevSearch.formData);
			this.otpRequestPagination=prevSearch.pagination
		}
		this.searchRequest();
	}

	otpRequestPageChange(page) {
		if(this.loading){
			return;
		}
		this.error = null;
		var searchFormData = this.searchForm.getRawValue()
		var findRequest: any = {}
		var action = searchFormData["action"];
		if (action) {
			findRequest.action = action;
		}
		var accountIdLike = searchFormData["accountIdLike"];
		if (accountIdLike) {
			findRequest.accountIdLike = `%${accountIdLike}%`;
		}
		var status = searchFormData["status"];
		if (status !== null) {
			findRequest.status = status;
		}
		if (page < 1) {
			page = 1
		}
		findRequest.queryOffset = (page - 1) * this.otpRequestPagination.pageSize;
		findRequest.queryLimit = this.otpRequestPagination.pageSize;
        this.loading=true;
		this.httpService.post<any>(`/api/admin/otp/request/search`, findRequest).subscribe(findOtpRequestResponse => {
			this.loading=false;
			var rowCount = findOtpRequestResponse.rowCount;
			var pageCount = Math.ceil(rowCount / this.otpRequestPagination.pageSize);
			var pageList = []
			for (var i = page - 2; i <= page + 2; i++) {
				if (i >= 1 && i <= pageCount) {
					pageList.push(i);
				}
			}
			this.otpRequestPagination.currentPage = page;
			this.otpRequestPagination.rowCount = rowCount;
			this.otpRequestPagination.pageCount = pageCount;
			this.otpRequestPagination.pageList = pageList;
			this.otpRequestList = findOtpRequestResponse.resultList;
			this.searchService.save("OtpLoggingList",searchFormData,this.otpRequestPagination)
		}, errorResponse => {
			this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
			if (errorResponse.status > 0) {
				if (errorResponse.error?.errorCode) {
					this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
					return;
				}
				this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
				return;
			}
			this.error = "Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchRequest() {
		this.otpRequestPageChange(1);
	}
	viewRequest(otpRequest) {
		this.router.navigate(['view', otpRequest.id], { relativeTo: this.route })
	}
	sendSupervisorRequest(otpRequest) {
		this.router.navigate(['teller-approve', otpRequest.id], { relativeTo: this.route })
	}
	supervisorApproveRequest(otpRequest) {
		this.router.navigate(['supervisor-approve', otpRequest.id], { relativeTo: this.route })
	}
	supervisorRejectRequest(otpRequest) {
		this.router.navigate(['supervisor-reject', otpRequest.id], { relativeTo: this.route })
	}
}


