<div class="form-group" >
    <label *ngIf="label">{{label}}</label>
    <select class="form-control" [attr.name]="formControlName" [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="valueChange()">
        <option *ngFor="let option of optionList" [ngValue]="option.value">
            {{option.label}}</option>
    </select>
    <ng-container *ngIf="formControl&& formControl.invalid">
        <small *ngIf="formControl.errors.required" class="form-text text-danger">
            Trường thông tin bắt buộc.
        </small>
    </ng-container>
</div>