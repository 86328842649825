import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { SearchService } from 'src/app/service/search.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  userdetail = null;
  userMenuList = null;
  menuAllList = [
    {
      "navigation": ['/otp'],
      "title": "Smart OTP",
      "permCodeList": ["OTP_SUPERVISOR","OTP_INITIALIZATION", "OTP_TOKEN", "OTP_TOKEN_VIEW", "OTP_LOGGING"]
    },
    {
      "navigation": ['/dev'],
      "title": "Thiết bị",
      "permCodeList": ["DEV_MODEL", "DEV_MODEL_VIEW", "DEV_BRAND", "DEV_BRAND_VIEW", "DEV_MANUFACTURER", "DEV_MANUFACTURER_VIEW", "DEV_APP_BUNDLE", "DEV_APP_BUNDLE_VIEW", "DEV_SYSTEM", "DEV_SYSTEM_VIEW", "DEV_DEVICE", "DEV_DEVICE_VIEW", "DEV_LOGGING"]
    },
    {
      "navigation": ['/system'],
      "title": "Hệ thống",
      "permCodeList": ["SYS_USER", "SYS_PERM", "SYS_APPLICATION", "SYS_APPLICATION_VIEW", "SYS_LOGGING", "SYS_RSA_KEY", "SYS_RSA_KEY_VIEW"]
    },
    {
      "navigation": ['/stat'],
      "title": "Báo cáo",
      "permCodeList": ["STAT_STATISTIC"]
    }
  ]
  constructor(private authService: AuthService,
		private searchService:SearchService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.authService.getUserDetail().subscribe(userdetailResponse => {
      if (userdetailResponse.result) {
        this.userdetail = userdetailResponse.result;
      }
    },errorResponse=>{
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
		})
    this.authService.getUserPerm().subscribe(userdetailResponse => {
      if (userdetailResponse.resultList) {
        var sysPermCodeList = userdetailResponse.resultList;
        if (sysPermCodeList != null) {
          var userMenuList = [];
          for (var menu of this.menuAllList) {
            for (var permCode of menu.permCodeList) {
              if (sysPermCodeList.includes(permCode)) {
                userMenuList.push(menu);
                break;
              }
            }
          }
          this.userMenuList = userMenuList;
        }
      }
    },errorResponse=>{
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
		})
  }

  navigation(routerLink){
    this.searchService.clear();
    this.router.navigate(routerLink, { relativeTo: this.route });
  }

}
