import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-sys-role-detail',
  templateUrl: './sys-role-detail.component.html',
  styleUrls: ['./sys-role-detail.component.css']
})
export class SysRoleDetailComponent implements OnInit {
	error=null
	loading = false
  hasEditPerm = false;
	submitSuccess = false
  detailForm: FormGroup = this.formBuilder.group({
    code: ["", [Validators.required, Validators.maxLength(50)]],
    name: ["", [Validators.required, Validators.maxLength(200)]],
    description: ["", [Validators.maxLength(2000)]],
    note: ["", [Validators.maxLength(2000)]],
    status: [0, [Validators.required]]
  });
  sysRolePermList = null;
  roleId=null;
  roleAction="create";

  statusOptionList=[
    {value:0,label:"Ngừng sử dụng"},
    {value:1,label:"Sử dụng"}
  ]

  constructor(private authService: AuthService,private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
		this.authService.hasAnyPerm(['SYS_ROLE']).subscribe(hasPerm => {
			this.hasEditPerm = hasPerm;
		})
    this.route.params.subscribe(params=>{
      this.error=null;
      if(params.roleId){
        this.roleAction="view";
        if(params.roleAction){
          this.roleAction=params.roleAction;
        }
        this.createForm();
          this.roleId=params.roleId;
          this.loading=true;
          this.httpService.get<any>(`/api/admin/sys/role/${this.roleId}`).subscribe(response=>{
            this.loading=false;
            if(response.result){
              this.detailForm.patchValue(response.result);
              if (response.result.sysRolePermList) {
                this.loading=true;
                this.httpService.get<any>(`/api/admin/sys/perm`,{type:"USER",status:1}).subscribe(sysPermListResponse => {
                  this.loading=false;
                  var sysPermList = sysPermListResponse.resultList;
                  var sysRolePermMap = {}
                  for (var sysRolePerm of response.result.sysRolePermList) {
                    sysRolePermMap[sysRolePerm.sysPermId] = sysRolePerm;
                  }
                  var sysRolePermList=[];
                  for (var sysPerm of sysPermList) {
                    var sysRolePerm=sysRolePermMap[sysPerm.id];
                    if(!sysRolePerm){
                      sysRolePerm={
                        sysPermId:sysPerm.id,
                        deletedEntity:true
                      }
                    }
                    sysRolePerm.sysPerm=sysPerm
                    sysRolePermList.push(sysRolePerm)
                  }
                  this.sysRolePermList=sysRolePermList;
                }, errorResponse => {
                  this.loading=false;
                  if(errorResponse.status==403){
                    this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
                    return;
                  }
                  if (errorResponse.status > 0) {
                    if (errorResponse.error?.errorCode) {
                      this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
                      return;
                    }
                    this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
                    return;
                  }
                  this.error = "Lỗi kết nối! Vui lòng thử lại sau";
                })
              }
            }else if(response.errorCode){
              this.error=response.errorCode+":"+response.errorMessage;
            }
        },errorResponse=>{
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if(errorResponse.status>0){
            if(errorResponse.error?.errorCode){
              this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error="Lỗi kết nối! Vui lòng thử lại sau";
        })
      }else{
        this.roleAction="create";
        this.createForm();
        this.loading=true;
        this.httpService.get<any>(`/api/admin/sys/perm`,{type:"USER",status:1}).subscribe(sysPermListResponse => {
          this.loading=false;
          var sysPermList = sysPermListResponse.resultList;
          var sysRolePermList=[];
          for (var sysPerm of sysPermList) {
            var sysRolePerm={
              sysPermId:sysPerm.id,
              sysPerm:sysPerm,
              deletedEntity:true
            }
            sysRolePermList.push(sysRolePerm)
          }
          this.sysRolePermList=sysRolePermList;
        }, errorResponse => {
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if (errorResponse.status > 0) {
            if (errorResponse.error?.errorCode) {
              this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error = "Lỗi kết nối! Vui lòng thử lại sau";
        })
      }

    })
  }
  createForm(){
    if(this.roleAction==="create"){
      this.detailForm.enable()
    }else if(this.roleAction==="edit"){
      this.detailForm.enable()
      this.detailForm.get('code').disable();
    }else{
      this.detailForm.disable()
    }
  }

  submit(){
		if(this.loading){
			return;
		}
    this.error=null;
    if(this.roleAction==="create"){
      var detailData=this.detailForm.getRawValue();
      detailData.sysRolePermList=this.sysRolePermList;
      this.loading=true;
      this.httpService.put<any>(`/api/admin/sys/role`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.roleAction==="edit"){
      var detailData=this.detailForm.getRawValue();
      detailData.sysRolePermList=this.sysRolePermList;
      this.loading=true;
      this.httpService.post<any>(`/api/admin/sys/role/${this.roleId}`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.roleAction==="delete"){
      this.loading=true;
      this.httpService.delete<any>(`/api/admin/sys/role/${this.roleId}`).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
  }
  cancel(){
    if(this.roleAction==="create"){
      this.router.navigate(['../'], { relativeTo: this.route });
    }else{
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }
}
