<div class="card" *ngIf="!otpRequestId">
    <div class="card-body">
        <h5 class="card-title">Tạo mã kích hoạt</h5>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="detailForm.errors">
                {{detailForm.errors}}
            </div>
            <app-input-text label="Tài khoản" placeholder="Nhập tài khoản" [formGroup]="detailForm" formControlName="accountId"></app-input-text>
            <app-input-text label="Số điện thoại" placeholder="Nhập số điện thoại" [formGroup]="detailForm" formControlName="phoneNumber"></app-input-text>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tạo mã kích hoạt</button>
            </div>
        </div>
        </form>
    </div>
</div>
<div class="card" *ngIf="otpRequestId">
    <div class="card-body">
        <div class="alert alert-success" role="alert">
            Tạo đề nghị tạo mã kích hoạt thành công
        </div>
        <!-- <h1 class="display-4 text-center">Mã kích hoạt của khách hàng là:</h1>
        <h1 class="display-4 text-center">{{initializationCode}}</h1>
        <ng-container *ngIf="qrImageUrl">
            <h1 class="display-4 text-center">Qr code:</h1>
            <div  class="text-center">
                <img [src]="qrImageUrl" [alt]="initializationCode"/>
            </div>
        </ng-container> -->
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="reset()" >Trở về</button>
            <button type="button" class="btn btn-success" (click)="sendSupervisor()">Gửi kiểm soát viên</button>
        </div>
    </div>
    </div>
</div>