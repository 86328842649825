import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-dev-system-version-list',
  templateUrl: './dev-system-version-list.component.html',
  styleUrls: ['./dev-system-version-list.component.css']
})
export class DevSystemVersionListComponent implements OnInit {
	error=null
	loading = false
	hasEditPerm = false;
  systemId=null;
	devSystemVersionList=null
	devSystemVersionPagination={
		currentPage:1,
		pageSize:10,
		pageCount:0,
		rowCount:0,
		pageList:null
	}
	searchForm: FormGroup=this.formBuilder.group({
		versionLike: ['' ,[ Validators.maxLength(200)] ],
		buildNumberLike: ['' ,[ Validators.maxLength(200)] ],
		status: [null ]
	});
	systemVersionStatusOptionList=[
		{ value : null, label : "Tất cả" },
		{ value : 0, label : "Ngừng sử dụng" },
		{ value : 1, label : "Sử dụng" }
	]
	systemVersionStatusOptionMap={}
	constructor(private authService: AuthService,private formBuilder: FormBuilder,
	private httpService:BaseHttpService,
	private route: ActivatedRoute,
	private router: Router ) { }
	ngOnInit(): void {
		this.authService.hasAnyPerm(['DEV_SYSTEM']).subscribe(hasPerm => {
		  this.hasEditPerm = hasPerm;
		})
		for(var systemVersionStatusOption of this.systemVersionStatusOptionList){
			this.systemVersionStatusOptionMap[systemVersionStatusOption.value]=systemVersionStatusOption.label;
		}
    this.route.params.subscribe(params=>{
      this.error=null;
      if(params.systemId){
        this.systemId=params.systemId
		this.searchSystemVersion();
      }
    });
	}

	devSystemVersionPageChange(page){
		if(this.loading){
			return;
		}
    this.error=null;
		var searchFormData=this.searchForm.getRawValue()
		var findRequest:any = {}
			findRequest.name= this.systemId;
		var versionLike = searchFormData["versionLike"];
		if(versionLike){
			findRequest.versionLike= `%${versionLike}%`;
		}
		var buildNumberLike = searchFormData["buildNumberLike"];
		if(buildNumberLike){
			findRequest.buildNumberLike= `%${buildNumberLike}%`;
		}
		var status = searchFormData["status"];
		if(status !== null){
			findRequest.status= status;
		}
		if(page<1){
			page=1
		}
		findRequest.queryOffset=(page-1)*this.devSystemVersionPagination.pageSize;
		findRequest.queryLimit=this.devSystemVersionPagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/dev/system-version/search`,findRequest).subscribe(findOtpSystemVersionResponse=>{
            this.loading=false;
			var rowCount = findOtpSystemVersionResponse.rowCount;
			var pageCount = Math.ceil(rowCount/this.devSystemVersionPagination.pageSize);
			var pageList=[]
			for(var i=page-2;i<=page+2;i++){
				if(i>=1&&i<=pageCount){
					pageList.push(i);
				}
			}
			this.devSystemVersionPagination.currentPage=page;
			this.devSystemVersionPagination.rowCount=rowCount;
			this.devSystemVersionPagination.pageCount=pageCount;
			this.devSystemVersionPagination.pageList=pageList;
			this.devSystemVersionList= findOtpSystemVersionResponse.resultList;
		},errorResponse=>{
            this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchSystemVersion(){
		this.devSystemVersionPageChange(1);
	}
	viewSystemVersion(devSystemVersion){
		this.router.navigate(['view',devSystemVersion.id],{ relativeTo: this.route })
	}
	insertSystemVersion(){
		this.router.navigate(['create'],{ relativeTo: this.route })
	}
	editSystemVersion(devSystemVersion){
		this.router.navigate(['edit',devSystemVersion.id],{ relativeTo: this.route })
	}
	deleteSystemVersion(devSystemVersion){
		this.router.navigate(['delete',devSystemVersion.id],{ relativeTo: this.route })
	}
  cancel(){
      this.router.navigate(['../../'], { relativeTo: this.route });
  }
}


