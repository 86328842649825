<div class="card">
    <div class="card-body">
        <h5 class="card-title">Tìm kiếm quyền</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-text label="Mã" placeholder="Nhập mã" [formGroup]="searchForm" formControlName="codeLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-text label="Tên" placeholder="Nhập tên" [formGroup]="searchForm" formControlName="nameLike"></app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input-select label="Loại quyền" placeholder="Chọn loại quyền" [formGroup]="searchForm" formControlName="type" [optionList]="permTypeOptionList"></app-input-select>
                </div>
                <div class="col">
                    <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="searchForm" formControlName="status" [optionList]="permStatusOptionList"></app-input-select>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="searchPerm()" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tìm kiếm</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Kết quả tìm kiếm</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Loại quyền</th>
                    <th scope="col">Mã</th>
                    <th scope="col">Tên</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary" (click)="insertPerm()">Thêm</button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="sysPermList && !loading">
                <tr *ngFor="let sysPerm of sysPermList;index as sysPermIndex">
                    <td scope="col">{{(sysPermPagination.currentPage-1)*sysPermPagination.pageSize + sysPermIndex+1}}</td>
                    <td scope="col">{{permTypeOptionMap[sysPerm.type]}}</td>
                    <td scope="col">{{sysPerm.code}}</td>
                    <td scope="col">{{sysPerm.name}}</td>
                    <td scope="col">{{permStatusOptionMap[sysPerm.status]}}</td>
                    <td scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-info" (click)="viewPerm(sysPerm)">Xem</button>
                            <button type="button" class="btn btn-primary" (click)="moveUpPerm(sysPerm)" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Lên trên</button>
                            <button type="button" class="btn btn-primary" (click)="moveDownPerm(sysPerm)" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Xuống dưới</button>
                            <button type="button" class="btn btn-primary" (click)="editPerm(sysPerm)">Sửa</button>
                            <button type="button" class="btn btn-danger"  (click)="deletePerm(sysPerm)">Xóa</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="d-flex justify-content-between align-items-center" *ngIf="sysPermPagination.pageCount>1 && !loading">
            <nav>
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{'disabled':sysPermPagination.currentPage == 1}">
                      <a class="page-link" (click)="sysPermPageChange(sysPermPagination.currentPage-1)">Trước</a>
                    </li>
                    <li class="page-item" *ngIf="sysPermPagination.currentPage > 3">
                      <a class="page-link" (click)="sysPermPageChange(1)">1</a>
                    </li>
                  <li class="page-item disabled" *ngIf="sysPermPagination.currentPage > 3">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngFor="let page of sysPermPagination.pageList"  [ngClass]="{'active':sysPermPagination.currentPage == page}">
                    <a class="page-link" (click)="sysPermPageChange(page)">{{page}}</a>
                  </li>
                  <li class="page-item disabled" *ngIf="sysPermPagination.currentPage < sysPermPagination.pageCount - 2">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngIf="sysPermPagination.currentPage < sysPermPagination.pageCount - 2">
                    <a class="page-link" (click)="sysPermPageChange(sysPermPagination.pageCount)">{{sysPermPagination.pageCount}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled':sysPermPagination.currentPage == sysPermPagination.pageCount}">
                    <a class="page-link"  (click)="sysPermPageChange(sysPermPagination.currentPage+1)">Sau</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>
</div>

