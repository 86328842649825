import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-sys-perm-list',
  templateUrl: './sys-perm-list.component.html',
  styleUrls: ['./sys-perm-list.component.css']
})
export class SysPermListComponent implements OnInit {
	error=null
	loading = false
	sysPermList=null
	sysPermPagination={
		currentPage:1,
		pageSize:10,
		pageCount:0,
		rowCount:0,
		pageList:null
	}
	searchForm: FormGroup=this.formBuilder.group({
		type: [null ,[ Validators.maxLength(200)] ],
		codeLike: ['' ,[ Validators.maxLength(200)] ],
		nameLike: ['' ,[ Validators.maxLength(200)] ],
		status: [null ]
	});
	permTypeOptionList=[
		{ value : null, label : "Tất cả" },
		{value:"USER",label:"Người dùng"},
		{value:"APPLICATION",label:"Ứng dụng"}
	]
	permTypeOptionMap={}
	permStatusOptionList=[
		{ value : null, label : "Tất cả" },
		{ value : 0, label : "Ngừng sử dụng" },
		{ value : 1, label : "Sử dụng" }
	]
	permStatusOptionMap={}
	constructor(private formBuilder: FormBuilder,
	private httpService:BaseHttpService,
	private route: ActivatedRoute,
	private router: Router ) { }
	ngOnInit(): void {
		for(var permTypeOption of this.permTypeOptionList){
			this.permTypeOptionMap[permTypeOption.value]=permTypeOption.label;
		}
		for(var permStatusOption of this.permStatusOptionList){
			this.permStatusOptionMap[permStatusOption.value]=permStatusOption.label;
		}
		this.searchPerm();
	}

	sysPermPageChange(page){
		if(this.loading){
			return;
		}
		this.error=null;
		var searchFormData=this.searchForm.getRawValue()
		var findRequest:any = {}
		var type = searchFormData["type"];
		if(type){
			findRequest.type= type;
		}
		var codeLike = searchFormData["codeLike"];
		if(codeLike){
			findRequest.codeLike= `%${codeLike}%`;
		}
		var nameLike = searchFormData["nameLike"];
		if(nameLike){
			findRequest.nameLike= `%${nameLike}%`;
		}
		var status = searchFormData["status"];
		if(status !== null){
			findRequest.status= status;
		}
		if(page<1){
			page=1
		}
		findRequest.queryOffset=(page-1)*this.sysPermPagination.pageSize;
		findRequest.queryLimit=this.sysPermPagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/sys/perm/search`,findRequest).subscribe(findOtpPermResponse=>{
			this.loading=false;
			var rowCount = findOtpPermResponse.rowCount;
			var pageCount = Math.ceil(rowCount/this.sysPermPagination.pageSize);
			var pageList=[]
			for(var i=page-2;i<=page+2;i++){
				if(i>=1&&i<=pageCount){
					pageList.push(i);
				}
			}
			this.sysPermPagination.currentPage=page;
			this.sysPermPagination.rowCount=rowCount;
			this.sysPermPagination.pageCount=pageCount;
			this.sysPermPagination.pageList=pageList;
			this.sysPermList= findOtpPermResponse.resultList;
		},errorResponse=>{
			this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchPerm(){
		this.sysPermPageChange(1);
	}
	viewPerm(sysPerm){
		this.router.navigate(['view',sysPerm.id],{ relativeTo: this.route })
	}
	insertPerm(){
		this.router.navigate(['create'],{ relativeTo: this.route })
	}
	editPerm(sysPerm){
		this.router.navigate(['edit',sysPerm.id],{ relativeTo: this.route })
	}

	moveUpPerm(sysPerm){
		if(this.loading){
			return;
		}
	  this.error=null;
	  this.loading=true;
		this.httpService.post<any>(`/api/admin/sys/perm/${sysPerm.id}/up`,{}).subscribe(response=>{
			this.loading=false;
		if(response.result){
			this.sysPermPageChange(this.sysPermPagination.currentPage);
		}else if(response.errorCode){
		  this.error=response.errorCode+":"+response.errorMessage;
		}
	  },errorResponse=>{
		this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
		if(errorResponse.status>0){
		  if(errorResponse.error?.errorCode){
			this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
			return;
		  }
		  this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
		  return;
		}
		this.error="Lỗi kết nối! Vui lòng thử lại sau";
	  })
	}

	moveDownPerm(sysPerm){
		if(this.loading){
			return;
		}
	  this.error=null;
	  this.loading=true;
		this.httpService.post<any>(`/api/admin/sys/perm/${sysPerm.id}/down`,{}).subscribe(response=>{
			this.loading=false;
		if(response.result){
			this.sysPermPageChange(this.sysPermPagination.currentPage);
		}else if(response.errorCode){
		  this.error=response.errorCode+":"+response.errorMessage;
		}
	  },errorResponse=>{
		this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
		if(errorResponse.status>0){
		  if(errorResponse.error?.errorCode){
			this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
			return;
		  }
		  this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
		  return;
		}
		this.error="Lỗi kết nối! Vui lòng thử lại sau";
	  })
	}
	deletePerm(sysPerm){
		this.router.navigate(['delete',sysPerm.id],{ relativeTo: this.route })
	}
}


