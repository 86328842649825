import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-dev-brand-list',
  templateUrl: './dev-brand-list.component.html',
  styleUrls: ['./dev-brand-list.component.css']
})
export class DevBrandListComponent implements OnInit {
	error=null
	loading = false
	hasEditPerm = false;
	devBrandList=null
	devBrandPagination={
		currentPage:1,
		pageSize:10,
		pageCount:0,
		rowCount:0,
		pageList:null
	}
	searchForm: FormGroup=this.formBuilder.group({
		nameLike: ['' ,[ Validators.maxLength(200)] ],
		status: [null ]
	});
	brandStatusOptionList=[
		{ value : null, label : "Tất cả" },
		{ value : 0, label : "Ngừng sử dụng" },
		{ value : 1, label : "Sử dụng" }
	]
	brandStatusOptionMap={}
	constructor(private authService: AuthService,private formBuilder: FormBuilder,
	private httpService:BaseHttpService,
	private route: ActivatedRoute,
	private router: Router ) { }
	ngOnInit(): void {
		this.authService.hasAnyPerm(['DEV_BRAND']).subscribe(hasPerm => {
		  this.hasEditPerm = hasPerm;
		})
		for(var brandStatusOption of this.brandStatusOptionList){
			this.brandStatusOptionMap[brandStatusOption.value]=brandStatusOption.label;
		}
		this.searchBrand();
	}

	devBrandPageChange(page){
		if(this.loading){
			return;
		}
    this.error=null;
		var searchFormData=this.searchForm.getRawValue()
		var findRequest:any = {}
		var nameLike = searchFormData["nameLike"];
		if(nameLike){
			findRequest.nameLike= `%${nameLike}%`;
		}
		var status = searchFormData["status"];
		if(status !== null){
			findRequest.status= status;
		}
		if(page<1){
			page=1
		}
		findRequest.queryOffset=(page-1)*this.devBrandPagination.pageSize;
		findRequest.queryLimit=this.devBrandPagination.pageSize;
        this.loading=true;
		this.httpService.post<any>(`/api/admin/dev/brand/search`,findRequest).subscribe(findOtpBrandResponse=>{
			this.loading=false;
			var rowCount = findOtpBrandResponse.rowCount;
			var pageCount = Math.ceil(rowCount/this.devBrandPagination.pageSize);
			var pageList=[]
			for(var i=page-2;i<=page+2;i++){
				if(i>=1&&i<=pageCount){
					pageList.push(i);
				}
			}
			this.devBrandPagination.currentPage=page;
			this.devBrandPagination.rowCount=rowCount;
			this.devBrandPagination.pageCount=pageCount;
			this.devBrandPagination.pageList=pageList;
			this.devBrandList= findOtpBrandResponse.resultList;
		},errorResponse=>{
			this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchBrand(){
		this.devBrandPageChange(1);
	}
	viewBrand(devBrand){
		this.router.navigate(['view',devBrand.id],{ relativeTo: this.route })
	}
	insertBrand(){
		this.router.navigate(['create'],{ relativeTo: this.route })
	}
	editBrand(devBrand){
		this.router.navigate(['edit',devBrand.id],{ relativeTo: this.route })
	}
	deleteBrand(devBrand){
		this.router.navigate(['delete',devBrand.id],{ relativeTo: this.route })
	}
}


