import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-otp-token-detail',
  templateUrl: './otp-token-detail.component.html',
  styleUrls: ['./otp-token-detail.component.css']
})
export class OtpTokenDetailComponent implements OnInit {
	error=null
	loading = false
  hasEditPerm = false;
  hasDevicePerm = false;
	submitSuccess = false
  detailForm: FormGroup = this.formBuilder.group({
    id: ["", [Validators.required, Validators.maxLength(50)]],
    accountId: ["", [Validators.required, Validators.maxLength(50)]],
    phoneNumber: ["", [Validators.required, Validators.maxLength(200)]],
    createApplicationCode: ["", [Validators.required, Validators.maxLength(200)]],
    createDate: ["", [Validators.required, Validators.maxLength(200)]],
    note: ["", [Validators.maxLength(2000)]],
    status: ["", [Validators.required]],
    devDeviceId: ["", [Validators.required]]
  });
  tokenId = null;
  tokenAction = "create";
  otpRequestId=null;
  devDeviceId=null;
  showDeviceButton=false;
  statusOptionList = [
    { value: 0, label: "Ngừng sử dụng" },
    { value: 1, label: "Đang sử dụng" },
    { value: 2, label: "Đã bị khóa" }
  ]

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder, private httpService: BaseHttpService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.authService.hasAnyPerm(['OTP_TOKEN']).subscribe(hasPerm => {
      this.hasEditPerm = hasPerm;
    })
    this.authService.hasAnyPerm(['DEV_DEVICE','DEV_DEVICE_VIEW']).subscribe(hasPerm => {
      this.hasDevicePerm = hasPerm;
    })
    this.detailForm.disable()
    this.route.params.subscribe(params => {
      this.error = null;
      this.otpRequestId=null;
      this.devDeviceId=null;
      this.showDeviceButton=true;
      if(params.deviceId){
        this.showDeviceButton=false;
      }
      if (params.tokenId) {
        this.tokenAction = "view";
        if (params.tokenAction) {
          this.tokenAction = params.tokenAction;
        }
        if(this.tokenAction!=="view"){
          this.detailForm.get('note').enable();
        }
        this.detailForm.patchValue({note:null});
        this.tokenId = params.tokenId;
        this.loading=true;
        this.httpService.get<any>(`/api/admin/otp/token/${this.tokenId}`).subscribe(response => {
          this.loading=false;
          if (response.result) {
            this.detailForm.patchValue(response.result);
            this.devDeviceId=response.result.devDeviceId;
          } else if (response.errorCode) {
            this.error = response.errorCode + ":" + response.errorMessage;
          }
        }, errorResponse => {
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if (errorResponse.status > 0) {
            if (errorResponse.error?.errorCode) {
              this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
              return;
            }
            this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
            return;
          }
          this.error = "Lỗi kết nối! Vui lòng thử lại sau";
        })
      }
    })
  }

  submit() {
		if(this.loading){
			return;
		}
    this.error = null;
    var detailFormData=this.detailForm.getRawValue()
    if (this.tokenAction === "lock") {
      this.loading=true;
      this.httpService.post<any>(`/api/admin/otp/request/token/${this.tokenId}/lock`, {note:detailFormData["note"]}).subscribe(response => {
        this.loading=false;
        if (response.result) {
          this.otpRequestId=response.result;
          this.submitSuccess=true;
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }
    if (this.tokenAction === "unlock") {
      this.loading=true;
      this.httpService.post<any>(`/api/admin/otp/request/token/${this.tokenId}/unlock`, {note:detailFormData["note"]}).subscribe(response => {
        this.loading=false;
        if (response.result) {
          this.otpRequestId=response.result;
          this.submitSuccess=true;
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }
    if (this.tokenAction === "disable") {
      this.loading=true;
      this.httpService.post<any>(`/api/admin/otp/request/token/${this.tokenId}/disable`, {note:detailFormData["note"]}).subscribe(response => {
        this.loading=false;
        if (response.result) {
          this.otpRequestId=response.result;
          this.submitSuccess=true;
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }
  }

  device(){
    this.router.navigate(['view', this.devDeviceId], { relativeTo: this.route });
  }
  cancel() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
  sendSupervisor(){
		this.router.navigate(['/otp/request/teller-approve', this.otpRequestId])
	}
}
