import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-dev-model-list',
  templateUrl: './dev-model-list.component.html',
  styleUrls: ['./dev-model-list.component.css']
})
export class DevModelListComponent implements OnInit {
	error=null
	loading = false
	hasEditPerm = false;
	devModelList=null
	devModelPagination={
		currentPage:1,
		pageSize:10,
		pageCount:0,
		rowCount:0,
		pageList:null
	}
	searchForm: FormGroup=this.formBuilder.group({
		nameLike: ['' ,[ Validators.maxLength(200)] ],
		status: [null ]
	});
	modelStatusOptionList=[
		{ value : null, label : "Tất cả" },
		{ value : 0, label : "Ngừng sử dụng" },
		{ value : 1, label : "Sử dụng" }
	]
	modelStatusOptionMap={}
	constructor(private authService: AuthService,private formBuilder: FormBuilder,
	private httpService:BaseHttpService,
	private route: ActivatedRoute,
	private router: Router ) { }
	ngOnInit(): void {
		this.authService.hasAnyPerm(['DEV_MODEL']).subscribe(hasPerm => {
		  this.hasEditPerm = hasPerm;
		})
		for(var modelStatusOption of this.modelStatusOptionList){
			this.modelStatusOptionMap[modelStatusOption.value]=modelStatusOption.label;
		}
		this.searchModel();
	}

	devModelPageChange(page){
		if(this.loading){
			return;
		}
    this.error=null;
		var searchFormData=this.searchForm.getRawValue()
		var findRequest:any = {}
		var nameLike = searchFormData["nameLike"];
		if(nameLike){
			findRequest.nameLike= `%${nameLike}%`;
		}
		var status = searchFormData["status"];
		if(status !== null){
			findRequest.status= status;
		}
		if(page<1){
			page=1
		}
		findRequest.queryOffset=(page-1)*this.devModelPagination.pageSize;
		findRequest.queryLimit=this.devModelPagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/dev/model/search`,findRequest).subscribe(findOtpModelResponse=>{
            this.loading=false;
			var rowCount = findOtpModelResponse.rowCount;
			var pageCount = Math.ceil(rowCount/this.devModelPagination.pageSize);
			var pageList=[]
			for(var i=page-2;i<=page+2;i++){
				if(i>=1&&i<=pageCount){
					pageList.push(i);
				}
			}
			this.devModelPagination.currentPage=page;
			this.devModelPagination.rowCount=rowCount;
			this.devModelPagination.pageCount=pageCount;
			this.devModelPagination.pageList=pageList;
			this.devModelList= findOtpModelResponse.resultList;
		},errorResponse=>{
            this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchModel(){
		this.devModelPageChange(1);
	}
	viewModel(devModel){
		this.router.navigate(['view',devModel.id],{ relativeTo: this.route })
	}
	insertModel(){
		this.router.navigate(['create'],{ relativeTo: this.route })
	}
	editModel(devModel){
		this.router.navigate(['edit',devModel.id],{ relativeTo: this.route })
	}
	deleteModel(devModel){
		this.router.navigate(['delete',devModel.id],{ relativeTo: this.route })
	}
}


