import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-sys-logging-list',
  templateUrl: './sys-logging-list.component.html',
  styleUrls: ['./sys-logging-list.component.css']
})
export class SysLoggingListComponent implements OnInit {
	error=null
	loading = false
	sysLoggingList=null
	sysLoggingPagination={
		currentPage:1,
		pageSize:10,
		pageCount:0,
		rowCount:0,
		pageList:null
	}
	searchForm: FormGroup=this.formBuilder.group({
		action: [null ],
		createApplicationCodeLike: ['' ,[ Validators.maxLength(200)] ],
		createUsernameLike: ['' ,[ Validators.maxLength(200)] ],
		loggingDataLike: ['', [Validators.maxLength(200)]],
		createDateGt: [null],
		createDateLt: [null]
	});
	loggingActionOptionList=[
		{ value : null, label : "Tất cả" },
		{ value : "SYS_RSA_KEY_INSERT",label : "Thêm key"},
		{ value : "SYS_RSA_KEY_UPDATE",label : "Cập nhật key"},
		{ value : "SYS_RSA_KEY_DELETE",label : "Xóa key"},
		{ value : "SYS_APPLICATION_INSERT",label : "Thêm ứng dụng" },
		{ value : "SYS_APPLICATION_UPDATE",label : "Cập nhật ứng dụng" },
		{ value : "SYS_APPLICATION_DELETE",label : "Xóa ứng dụng" },
		{value : "SYS_PERM_INSERT",label : "Thêm quyền"},
		{value : "SYS_PERM_UPDATE",label : "Cập nhật quyền"},
		{value : "SYS_PERM_MOVE_UP",label : "Cập nhật thứ tự quyền"},
		{value : "SYS_PERM_MOVE_DOWN",label : "Cập nhật thứ tự quyền"},
		{value : "SYS_PERM_DELETE",label : "Xóa quyền"},
		{value : "SYS_USER_INSERT",label : "Thêm người dùng"},
		{value : "SYS_USER_UPDATE",label : "Cập người dùng"},
		{value : "SYS_USER_CHANGE_PASSWORD",label : "Đổi mật khẩu người dùng"},
		{value : "SYS_BRANCH_INSERT",label : "Thêm chi nhánh"},
		{value : "SYS_BRANCH_UPDATE",label : "Cập nhật chi nhánh"},
		{value : "SYS_BRANCH_DELETE",label : "Xóa chi nhánh"},
		{value : "SYS_ROLE_INSERT",label : "Thêm role"},
		{value : "SYS_ROLE_UPDATE",label : "Cập role"},
		{value : "SYS_ROLE_DELETE",label : "Xóa role"},
	]
	loggingActionOptionMap={}
	loggingStatusOptionList=[
		{ value : null, label : "Tất cả" }
	]
	loggingStatusOptionMap={}
	constructor(private formBuilder: FormBuilder,
	private httpService:BaseHttpService,
	private route: ActivatedRoute,
	private router: Router ) { }
	ngOnInit(): void {
		for(var loggingActionOption of this.loggingActionOptionList){
			this.loggingActionOptionMap[loggingActionOption.value]=loggingActionOption.label;
		}
		for(var loggingStatusOption of this.loggingStatusOptionList){
			this.loggingStatusOptionMap[loggingStatusOption.value]=loggingStatusOption.label;
		}
		this.searchLogging();
	}

	sysLoggingPageChange(page){
		if(this.loading){
			return;
		}
		this.error=null;
		var searchFormData=this.searchForm.getRawValue()
		var findRequest:any = {}
		var action = searchFormData["action"];
		if(action !== null){
			findRequest.action= action;
		}
		var createApplicationCodeLike = searchFormData["createApplicationCodeLike"];
		if(createApplicationCodeLike){
			findRequest.createApplicationCodeLike= `%${createApplicationCodeLike}%`;
		}
		var createUsernameLike = searchFormData["createUsernameLike"];
		if(createUsernameLike){
			findRequest.createUsernameLike= `%${createUsernameLike}%`;
		}
		var loggingDataLike = searchFormData["loggingDataLike"];
		if (loggingDataLike) {
			findRequest.loggingDataLike = `%${loggingDataLike}%`;
		}
		var createDateGt = searchFormData["createDateGt"];
		if (createDateGt) {
			findRequest.createDateGt = createDateGt;
		}
		var createDateLt = searchFormData["createDateLt"];
		if (createDateLt) {
			findRequest.createDateLt = createDateLt;
		}
		if(page<1){
			page=1
		}
		findRequest.queryOffset=(page-1)*this.sysLoggingPagination.pageSize;
		findRequest.queryLimit=this.sysLoggingPagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/sys/logging/search`,findRequest).subscribe(findOtpLoggingResponse=>{
			this.loading=false;
			var rowCount = findOtpLoggingResponse.rowCount;
			var pageCount = Math.ceil(rowCount/this.sysLoggingPagination.pageSize);
			var pageList=[]
			for(var i=page-2;i<=page+2;i++){
				if(i>=1&&i<=pageCount){
					pageList.push(i);
				}
			}
			this.sysLoggingPagination.currentPage=page;
			this.sysLoggingPagination.rowCount=rowCount;
			this.sysLoggingPagination.pageCount=pageCount;
			this.sysLoggingPagination.pageList=pageList;
			this.sysLoggingList= findOtpLoggingResponse.resultList;
		},errorResponse=>{
			this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchLogging(){
		this.sysLoggingPageChange(1);
	}
	viewLogging(sysLogging){
		this.router.navigate(['view',sysLogging.id],{ relativeTo: this.route })
	}
}


