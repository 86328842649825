import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-dev-device-list',
  templateUrl: './dev-device-list.component.html',
  styleUrls: ['./dev-device-list.component.css']
})
export class DevDeviceListComponent implements OnInit {
	error=null
	loading = false
	hasEditPerm = false;
	devDeviceList=null
	devDevicePagination={
		currentPage:1,
		pageSize:10,
		pageCount:0,
		rowCount:0,
		pageList:null
	}
	searchForm: FormGroup=this.formBuilder.group({
		idLike: ['' ,[ Validators.maxLength(2000)] ],
		nameLike: ['' ,[ Validators.maxLength(2000)] ],
		devModelIdLike: ['' ,[ Validators.maxLength(2000)] ],
		devBrandLike: ['' ,[ Validators.maxLength(2000)] ],
		devManufacturerLike: ['' ,[ Validators.maxLength(2000)] ],
		devSystemNameLike: ['' ,[ Validators.maxLength(2000)] ],
		devSystemVersionLike: ['' ,[ Validators.maxLength(2000)] ],
		status: [null ]
	});
	deviceStatusOptionList=[
		{ value : null, label : "Tất cả" },
		{ value : 1, label : "Đang sử dụng" },
		{ value : 2, label : "Đã bị khóa" }
	]
	deviceStatusOptionMap={}
	constructor(private authService: AuthService,private formBuilder: FormBuilder,
	private httpService:BaseHttpService,
	private route: ActivatedRoute,
	private router: Router ) { }
	ngOnInit(): void {
		this.authService.hasAnyPerm(['DEV_DEVICE']).subscribe(hasPerm => {
		  this.hasEditPerm = hasPerm;
		})
		for(var deviceStatusOption of this.deviceStatusOptionList){
			this.deviceStatusOptionMap[deviceStatusOption.value]=deviceStatusOption.label;
		}
		this.searchDevice();
	}

	devDevicePageChange(page){
		if(this.loading){
			return;
		}
		this.error=null;
		var searchFormData=this.searchForm.getRawValue()
		var findRequest:any = {}
		var idLike = searchFormData["idLike"];
		if(idLike){
			findRequest.idLike= `%${idLike}%`;
		}
		var nameLike = searchFormData["nameLike"];
		if(nameLike){
			findRequest.nameLike= `%${nameLike}%`;
		}
		var devModelIdLike = searchFormData["devModelIdLike"];
		if(devModelIdLike){
			findRequest.devModelIdLike= `%${devModelIdLike}%`;
		}
		var devBrandLike = searchFormData["devBrandLike"];
		if(devBrandLike){
			findRequest.devBrandLike= `%${devBrandLike}%`;
		}
		var devManufacturerLike = searchFormData["devManufacturerLike"];
		if(devManufacturerLike){
			findRequest.devManufacturerLike= `%${devManufacturerLike}%`;
		}
		var devSystemNameLike = searchFormData["devSystemNameLike"];
		if(devSystemNameLike){
			findRequest.devSystemNameLike= `%${devSystemNameLike}%`;
		}
		var devSystemVersionLike = searchFormData["devSystemVersionLike"];
		if(devSystemVersionLike){
			findRequest.devSystemVersionLike= `%${devSystemVersionLike}%`;
		}

		var searchStatus = searchFormData["status"];
		if(searchStatus !== null){
			findRequest.status= searchStatus;
		}
		if(page<1){
			page=1
		}
		findRequest.queryOffset=(page-1)*this.devDevicePagination.pageSize;
		findRequest.queryLimit=this.devDevicePagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/dev/device/search`,findRequest).subscribe(findOtpDeviceResponse=>{
            this.loading=false;
			var rowCount = findOtpDeviceResponse.rowCount;
			var pageCount = Math.ceil(rowCount/this.devDevicePagination.pageSize);
			var pageList=[]
			for(var i=page-2;i<=page+2;i++){
				if(i>=1&&i<=pageCount){
					pageList.push(i);
				}
			}
			this.devDevicePagination.currentPage=page;
			this.devDevicePagination.rowCount=rowCount;
			this.devDevicePagination.pageCount=pageCount;
			this.devDevicePagination.pageList=pageList;
			this.devDeviceList= findOtpDeviceResponse.resultList;
		},errorResponse=>{
            this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchDevice(){
		this.devDevicePageChange(1);
	}
	viewDevice(devDevice){
		this.router.navigate(['view',devDevice.id],{ relativeTo: this.route })
	}
	disableDevice(devDevice){
		this.router.navigate(['disable',devDevice.id],{ relativeTo: this.route })
	}
	lockDevice(devDevice){
		this.router.navigate(['lock',devDevice.id],{ relativeTo: this.route })
	}
	unlockDevice(devDevice){
		this.router.navigate(['unlock',devDevice.id],{ relativeTo: this.route })
	}
}


