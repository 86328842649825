import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, catchError, tap,map } from 'rxjs/operators';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  permCodeList=null;
  constructor(private baseHttpService: BaseHttpService) { }
  login(data: any): Observable<any> {
    return this.baseHttpService.post<any>(`/api/auth/login`, data).pipe(
      tap(response => {
        if(response.result){
          var authorization=response.result;
          localStorage.setItem("authorization",authorization);
          this.baseHttpService.setAuthorization(authorization);
        }
      })
    );
  }
  getUserDetail(): Observable<any> {
    return this.baseHttpService.get<any>(`/api/auth/user-detail`);
  }


  getUserPerm(): Observable<any> {
    if(this.permCodeList!=null){
      return of({resultList:this.permCodeList});
    }
    return this.baseHttpService.get<any>(`/api/auth/user-perm`).pipe(
      tap(userPermResponse=>{
        if(userPermResponse.resultList){
          this.permCodeList=userPermResponse.resultList;
        }
      })
    );
  }

  hasAnyPerm(permCodeList): Observable<boolean>{
    if(!permCodeList||permCodeList.length==0){
      return of(true);
    }
    return this.getUserPerm().pipe(map(
      userdetailResponse => {
      if (userdetailResponse.resultList) {
        var sysPermCodeList = userdetailResponse.resultList;
        if (sysPermCodeList != null) {
            for (var permCode of permCodeList) {
              if (sysPermCodeList.includes(permCode)) {
                return true;
              }
            }
        }
      }
      return false;
    }))
  }

  logout(): Observable<any> {
    return this.baseHttpService.post<any>(`/api/auth/logout`, {}).pipe(
      tap(response => {
        if(response.result){
          this.permCodeList=null;
          localStorage.removeItem("authorization");
          this.baseHttpService.clearAuthorization();
        }
      })
    );
  }

  isAuthenticated(){
    this.baseHttpService.loadAuthorization();
    if(this.baseHttpService.authorization){
      return true;
    }
    return false
  }
}
