<div class="card" *ngIf="!submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông tin token</h5>
        <div class="alert alert-warning" role="alert" *ngIf="tokenAction==='lock'">
          Bạn có chắc chắn muốn khóa token
        </div>
        <div class="alert alert-success" role="alert" *ngIf="tokenAction==='unlock'">
          Bạn có chắc chắn muốn mở khóa token
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="tokenAction==='disable'">
          Bạn có chắc chắn muốn ngừng sử dụng token
        </div>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <app-input-text label="Id" placeholder="Nhập Id" [formGroup]="detailForm" formControlName="id"></app-input-text>
            <app-input-text label="Tài khoản" placeholder="Nhập tài khoản" [formGroup]="detailForm" formControlName="accountId"></app-input-text>
            <app-input-text label="Số điện thoại" placeholder="Nhập số điện thoại" [formGroup]="detailForm" formControlName="phoneNumber"></app-input-text>
            <app-input-text label="Ứng dụng" placeholder="Nhập ứng dụng" [formGroup]="detailForm" formControlName="createApplicationCode"></app-input-text>
            <app-input-date label="Ngày tạo" placeholder="Nhập ngày tạo" [formGroup]="detailForm" formControlName="createDate"></app-input-date>
            <app-input-text label="Thiết bị Id" placeholder="Nhập thiết bị Id" [formGroup]="detailForm" formControlName="devDeviceId"></app-input-text>
            <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="detailForm" formControlName="status" [optionList]="statusOptionList"></app-input-select>
            <app-input-textarea label="Ghi chú" placeholder="Nhập ghi chú" [formGroup]="detailForm" formControlName="note"  *ngIf="tokenAction!=='view'" rows="5"></app-input-textarea>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
                <button type="button" class="btn btn-info" (click)="device()" *ngIf="hasDevicePerm&&showDeviceButton" >Thiết bị</button>
                <button type="button" class="btn btn-success" (click)="submit()" *ngIf="hasEditPerm&&tokenAction==='unlock'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tạo đề nghị mở khóa</button>
                <button type="button" class="btn btn-warning" (click)="submit()" *ngIf="hasEditPerm&&tokenAction==='lock'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tạo đề nghị khóa</button>
                <button type="button" class="btn btn-danger" (click)="submit()" *ngIf="hasEditPerm&&tokenAction==='disable'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tạo đề nghị ngừng sử dụng</button>
            </div>
        </div>
        </form>
    </div>
</div>
<div class="card" *ngIf="submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông báo</h5>
        <div class="alert alert-success" role="alert" *ngIf="tokenAction==='unlock'">
          Tạo đề nghị mở khóa thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="tokenAction==='lock'">
          Tạo đề nghị khóa thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="tokenAction==='disable'">
          Tạo đề nghị ngừng sử dụng thành công
        </div>
        <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
                <button type="button" class="btn btn-success" (click)="sendSupervisor()">Gửi kiểm soát viên</button>
            </div>
        </div>
    </div>
</div>