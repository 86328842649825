import { Component, ElementRef, forwardRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

export const EDITOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputAutocompleteComponent),
  multi: true
};
@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.css'],
  providers: [EDITOR_VALUE_ACCESSOR]
})
export class InputAutocompleteComponent implements OnInit, OnChanges {
  @ViewChild('searchInput') searchInputElement: ElementRef;


  @Input("formGroup") formGroup: FormGroup;
  @Input("formControlName") formControlName: string;
  @Input("label") label: string;
  @Input("placeholder") placeholder: string;
  @Input("disabled") disabled: boolean = false;
  @Input("optionList") optionList;
  searchValue = null;
  searchOptionList = null;
  value = null;
  valueLabel = null;
  formControl = null;
  searching = false;
  inputWidth = 100;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    var formGroup = null;
    if (changes.formGroup) {
      formGroup = changes.formGroup.currentValue;
    }
    var formControlName = null;
    if (changes.formControlName) {
      formControlName = changes.formControlName.currentValue;
    }
    if (formGroup && formControlName) {
      this.formControl = formGroup.get(formControlName);
    }
    if (changes.optionList && this.value) {
      for (var option of changes.optionList.currentValue) {
        if (this.value === option.value) {
          this.valueLabel = option.label;
          break;
        }
      }
    }
  }
  @HostListener('window:resize', ['$event.target'])
  onResize(event) {
    this.inputFocusout();
  }
  valueChange(value) {
    this.value = value;
    this.onModelChange(value);
  }

  clearValue(){
    if(this.value!=null){
      this.writeValue(null);
      this.onModelChange(null);
    }
  }

  inputFocus() {
    this.searchValue = null;
    this.searching = true;
    this.searchChange(null);
    if (this.searchInputElement) {
      setTimeout(() => {
        this.inputWidth = this.searchInputElement.nativeElement.offsetWidth;
        this.searchInputElement.nativeElement.focus()
      }, 100);
    }
  }

  inputFocusout() {
    setTimeout(() => {
      this.searching = false;
    }, 200);
  }

  searchChange(value) {
    var searchOptionList = null;
    if (this.optionList) {
      for (var option of this.optionList) {
        if (!value || option.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
          if (!searchOptionList) {
            searchOptionList = [];
          }
          searchOptionList.push(option)
          if (searchOptionList.length > 5) {
            break;
          }
        }
      }
    }
    this.searchOptionList = searchOptionList;
  }

  optionSelect(option) {
    if (option) {
      if (this.value !== option.value) {
        this.onModelChange(option.value);
        this.writeValue(option.value)
      }
    } else if (!this.value) {
      this.onModelChange(null);
      this.writeValue(null)
    }
  }

  writeValue(value: any): void {
    if (this.value !== value) {
      this.value = value;
      this.valueLabel = null;
      if (this.optionList) {
        for (var option of this.optionList) {
          if (value === option.value) {
            this.valueLabel = option.label;
            break;
          }
        }
      }
    }
  }
  onModelChange: Function = () => {
  };

  onModelTouched: Function = () => {
  };

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
