<div class="card"  *ngIf="!submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông tin thiết bị</h5>
        <div class="alert alert-warning" role="alert" *ngIf="deviceAction==='lock'">
          Bạn có chắc chắn muốn khóa thiết bị
        </div>
        <div class="alert alert-success" role="alert" *ngIf="deviceAction==='unlock'">
          Bạn có chắc chắn muốn mở khóa thiết bị
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="deviceAction==='disable'">
          Bạn có chắc chắn muốn ngừng sử dụng thiết bị
        </div>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <app-input-text label="Id" placeholder="Nhập id" [formGroup]="detailForm" formControlName="id"></app-input-text>
            <app-input-text label="Tên thiết bị" placeholder="Nhập tên thiết bị" [formGroup]="detailForm" formControlName="name"></app-input-text>
            <app-input-text label="Mẫu thiết bị" placeholder="Nhập mẫu thiết bị" [formGroup]="detailForm" formControlName="devModelId"></app-input-text>
            <app-input-text label="Thương hiệu" placeholder="Nhập thương hiệu" [formGroup]="detailForm" formControlName="devBrand"></app-input-text>
            <app-input-text label="Nhà sản xuất" placeholder="Nhập nhà sản xuất" [formGroup]="detailForm" formControlName="devManufacturer"></app-input-text>
            <app-input-text label="Hệ điều hành" placeholder="Nhập hệ điều hành" [formGroup]="detailForm" formControlName="devSystemName"></app-input-text>
            <app-input-text label="Phiên bản hệ điều hành" placeholder="Nhập phiên bản hệ điều hành" [formGroup]="detailForm" formControlName="devSystemVersion"></app-input-text>
            <app-input-text label="Giả lập" placeholder="Nhập giả lập" [formGroup]="detailForm" formControlName="devEmulator"></app-input-text>
            <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="detailForm" formControlName="status" [optionList]="statusOptionList"></app-input-select>
            <app-input-textarea label="Ghi chú" placeholder="Nhập ghi chú" [formGroup]="detailForm" formControlName="note" rows="5"></app-input-textarea>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
              <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
              <button type="button" class="btn btn-info" (click)="token()" *ngIf="hasTokenPerm&&showTokenButton">Token</button>
                <button type="button" class="btn btn-success" (click)="submit()" *ngIf="hasEditPerm&&deviceAction==='unlock'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Mở khóa</button>
                <button type="button" class="btn btn-warning" (click)="submit()" *ngIf="hasEditPerm&&deviceAction==='lock'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Khóa</button>
                <button type="button" class="btn btn-danger" (click)="submit()" *ngIf="hasEditPerm&&deviceAction==='disable'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Ngừng sử dụng</button>
            </div>
        </div>
        </form>
    </div>
</div>
<div class="card" *ngIf="submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông báo</h5>
        <div class="alert alert-success" role="alert" *ngIf="deviceAction==='unlock'">
          Mở khóa thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="deviceAction==='lock'">
          Khóa thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="deviceAction==='disable'">
          Ngừng sử dụng thành công
        </div>
        <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
            </div>
        </div>
    </div>
</div>