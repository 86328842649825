import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';
import { RoleGuard } from './guard/role.guard';

import { OtpInitializationComponent } from './components/otp/otp-initialization/otp-initialization.component';
import { OtpLoggingDetailComponent } from './components/otp/otp-logging/otp-logging-detail/otp-logging-detail.component';
import { OtpLoggingListComponent } from './components/otp/otp-logging/otp-logging-list/otp-logging-list.component';
import { OtpMenuComponent } from './components/otp/otp-menu/otp-menu.component';
import { OtpTokenDetailComponent } from './components/otp/otp-token/otp-token-detail/otp-token-detail.component';
import { OtpTokenListComponent } from './components/otp/otp-token/otp-token-list/otp-token-list.component';
import { DashboardComponent } from './components/system/dashboard/dashboard.component';
import { LoginComponent } from './components/system/login/login.component';
import { MenuComponent } from './components/system/menu/menu.component';
import { SysPermDetailComponent } from './components/system/sys-perm/sys-perm-detail/sys-perm-detail.component';
import { SysPermListComponent } from './components/system/sys-perm/sys-perm-list/sys-perm-list.component';
import { SysUserDetailComponent } from './components/system/sys-user/sys-user-detail/sys-user-detail.component';
import { SysUserListComponent } from './components/system/sys-user/sys-user-list/sys-user-list.component';
import { SystemMenuComponent } from './components/system/system-menu/system-menu.component';
import { SysLoggingDetailComponent } from './components/system/sys-logging/sys-logging-detail/sys-logging-detail.component';
import { SysLoggingListComponent } from './components/system/sys-logging/sys-logging-list/sys-logging-list.component';
import { DeviceMenuComponent } from './components/dev/device-menu/device-menu.component';
import { DevDeviceListComponent } from './components/dev/dev-device/dev-device-list/dev-device-list.component';
import { DevDeviceDetailComponent } from './components/dev/dev-device/dev-device-detail/dev-device-detail.component';
import { DevLoggingListComponent } from './components/dev/dev-logging/dev-logging-list/dev-logging-list.component';
import { DevLoggingDetailComponent } from './components/dev/dev-logging/dev-logging-detail/dev-logging-detail.component';
import { DevModelListComponent } from './components/dev/dev-model/dev-model-list/dev-model-list.component';
import { DevModelDetailComponent } from './components/dev/dev-model/dev-model-detail/dev-model-detail.component';
import { DevBrandListComponent } from './components/dev/dev-brand/dev-brand-list/dev-brand-list.component';
import { DevBrandDetailComponent } from './components/dev/dev-brand/dev-brand-detail/dev-brand-detail.component';
import { DevManufacturerListComponent } from './components/dev/dev-manufacturer/dev-manufacturer-list/dev-manufacturer-list.component';
import { DevManufacturerDetailComponent } from './components/dev/dev-manufacturer/dev-manufacturer-detail/dev-manufacturer-detail.component';
import { DevSystemListComponent } from './components/dev/dev-system/dev-system-list/dev-system-list.component';
import { DevSystemDetailComponent } from './components/dev/dev-system/dev-system-detail/dev-system-detail.component';
import { DevSystemVersionListComponent } from './components/dev/dev-system-version/dev-system-version-list/dev-system-version-list.component';
import { DevSystemVersionDetailComponent } from './components/dev/dev-system-version/dev-system-version-detail/dev-system-version-detail.component';
import { SysApplicationListComponent } from './components/system/sys-application/sys-application-list/sys-application-list.component';
import { SysApplicationDetailComponent } from './components/system/sys-application/sys-application-detail/sys-application-detail.component';
import { SysRsaKeyListComponent } from './components/system/sys-rsa-key/sys-rsa-key-list/sys-rsa-key-list.component';
import { SysRsaKeyDetailComponent } from './components/system/sys-rsa-key/sys-rsa-key-detail/sys-rsa-key-detail.component';
import { UserPasswordComponent } from './components/system/user-password/user-password.component';
import { SysRoleListComponent } from './components/system/sys-role/sys-role-list/sys-role-list.component';
import { SysRoleDetailComponent } from './components/system/sys-role/sys-role-detail/sys-role-detail.component';
import { SysBranchListComponent } from './components/system/sys-branch/sys-branch-list/sys-branch-list.component';
import { SysBranchDetailComponent } from './components/system/sys-branch/sys-branch-detail/sys-branch-detail.component';
import { OtpRequestListComponent } from './components/otp/otp-request/otp-request-list/otp-request-list.component';
import { OtpRequestDetailComponent } from './components/otp/otp-request/otp-request-detail/otp-request-detail.component';
import { StatMenuComponent } from './components/stat/stat-menu/stat-menu.component';
import { StatTokenStatusComponent } from './components/stat/stat-token-status/stat-token-status.component';
import { StatTokenEnrollComponent } from './components/stat/stat-token-enroll/stat-token-enroll.component';
import { StatTokenVerifyErrorComponent } from './components/stat/stat-token-verify-error/stat-token-verify-error.component';
import { StatDeviceSystemComponent } from './components/stat/stat-device-system/stat-device-system.component';
import { StatDeviceBrandComponent } from './components/stat/stat-device-brand/stat-device-brand.component';
import { StatDeviceManufacturerComponent } from './components/stat/stat-device-manufacturer/stat-device-manufacturer.component';


const routes: Routes = [
  { path: "login", component: LoginComponent },
  {
    path: "", component: MenuComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", pathMatch: 'full', redirectTo: 'dashboard' },
      { path: "dashboard", component: DashboardComponent },
      { path: "user-password", component: UserPasswordComponent },
      {
        path: "otp", component: OtpMenuComponent,
        children: [
          { path: "initialization", component: OtpInitializationComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["OTP_INITIALIZATION"] } },
          { path: "request", component: OtpRequestListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["OTP_INITIALIZATION","OTP_TOKEN","OTP_SUPERVISOR"] } },
          { path: "request/:requestAction/:requestId", component: OtpRequestDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["OTP_INITIALIZATION","OTP_TOKEN","OTP_SUPERVISOR"] } },
          { path: "token", component: OtpTokenListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["OTP_TOKEN", "OTP_TOKEN_VIEW"] } },
          { path: "token/:tokenAction/:tokenId", component: OtpTokenDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["OTP_TOKEN", "OTP_TOKEN_VIEW"] } },
          { path: "token/:tokenAction/:tokenId/:deviceAction/:deviceId", component: DevDeviceDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_DEVICE", "DEV_DEVICE_VIEW"] } },
          { path: "logging", component: OtpLoggingListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["OTP_LOGGING"] } },
          { path: "logging/:loggingAction/:loggingId", component: OtpLoggingDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["OTP_LOGGING"] } },
        ]
      },
      {
        path: "dev", component: DeviceMenuComponent,
        children: [
          { path: "device", component: DevDeviceListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_DEVICE", "DEV_DEVICE_VIEW"] } },
          { path: "device/:deviceAction/:deviceId", component: DevDeviceDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_DEVICE", "DEV_DEVICE_VIEW"] } },
          { path: "device/:deviceAction/:deviceId/token", component: OtpTokenListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_DEVICE", "DEV_DEVICE_VIEW"] } },
          { path: "device/:deviceAction/:deviceId/token/:tokenAction/:tokenId", component: OtpTokenDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_DEVICE", "DEV_DEVICE_VIEW"] } },
          { path: "model", component: DevModelListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_MODEL", "DEV_MODEL_VIEW"] } },
          { path: "model/create", component: DevModelDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_MODEL", "DEV_MODEL_VIEW"] } },
          { path: "model/:modelAction/:modelId", component: DevModelDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_MODEL", "DEV_MODEL_VIEW"] } },
          { path: "brand", component: DevBrandListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_BRAND", "DEV_BRAND_VIEW"] } },
          { path: "brand/create", component: DevBrandDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_BRAND", "DEV_BRAND_VIEW"] } },
          { path: "brand/:brandAction/:brandId", component: DevBrandDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_BRAND", "DEV_BRAND_VIEW"] } },
          { path: "manufacturer", component: DevManufacturerListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_MANUFACTURER", "DEV_MANUFACTURER_VIEW"] } },
          { path: "manufacturer/create", component: DevManufacturerDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_MANUFACTURER", "DEV_MANUFACTURER_VIEW"] } },
          { path: "manufacturer/:manufacturerAction/:manufacturerId", component: DevManufacturerDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_MANUFACTURER", "DEV_MANUFACTURER_VIEW"] } },
          { path: "system/version/:systemId", component: DevSystemVersionListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_SYSTEM", "DEV_SYSTEM_VIEW"] } },
          { path: "system/version/:systemId/create", component: DevSystemVersionDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_SYSTEM", "DEV_SYSTEM_VIEW"] } },
          { path: "system/version/:systemId/:systemVersionAction/:systemVersionId", component: DevSystemVersionDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_SYSTEM", "DEV_SYSTEM_VIEW"] } },
          { path: "system", component: DevSystemListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_SYSTEM", "DEV_SYSTEM_VIEW"] } },
          { path: "system/create", component: DevSystemDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_SYSTEM", "DEV_SYSTEM_VIEW"] } },
          { path: "system/:systemAction/:systemId", component: DevSystemDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["DEV_SYSTEM", "DEV_SYSTEM_VIEW"] } },
          { path: "logging", component: DevLoggingListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["OTP_LOGGING"] } },
          { path: "logging/:loggingAction/:loggingId", component: DevLoggingDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["OTP_LOGGING"] } }
        ]
      },
      {
        path: "system", component: SystemMenuComponent,
        children: [
          { path: "application", component: SysApplicationListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_APPLICATION","SYS_APPLICATION_VIEW"] } },
          { path: "application/create", component: SysApplicationDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_APPLICATION","SYS_APPLICATION_VIEW"] } },
          { path: "application/:applicationAction/:applicationId", component: SysApplicationDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_APPLICATION","SYS_APPLICATION_VIEW"] } },
          { path: "role", component: SysRoleListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_ROLE","SYS_ROLE_VIEW"] } },
          { path: "role/create", component: SysRoleDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_ROLE","SYS_ROLE_VIEW"] } },
          { path: "role/:roleAction/:roleId", component: SysRoleDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_ROLE","SYS_ROLE_VIEW"] } },
          { path: "branch", component: SysBranchListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_BRANCH","SYS_BRANCH_VIEW"] } },
          { path: "branch/create", component: SysBranchDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_BRANCH","SYS_BRANCH_VIEW"] } },
          { path: "branch/:branchAction/:branchId", component: SysBranchDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_BRANCH","SYS_BRANCH_VIEW"] } },
          { path: "rsa-key", component: SysRsaKeyListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_RSA_KEY", "SYS_RSA_KEY_VIEW"] } },
          { path: "rsa-key/create", component: SysRsaKeyDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_RSA_KEY", "SYS_RSA_KEY_VIEW"] } },
          { path: "rsa-key/:rsaKeyAction/:rsaKeyId", component: SysRsaKeyDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_RSA_KEY", "SYS_RSA_KEY_VIEW"] } },
          { path: "user", component: SysUserListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_USER"] } },
          { path: "user/create", component: SysUserDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_USER"] } },
          { path: "user/:userAction/:userId", component: SysUserDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_USER"] } },
          { path: "perm", component: SysPermListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_PERM"] } },
          { path: "perm/create", component: SysPermDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_PERM"] } },
          { path: "perm/:permAction/:permId", component: SysPermDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_PERM"] } },
          { path: "logging", component: SysLoggingListComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_LOGGING"] } },
          { path: "logging/:loggingAction/:loggingId", component: SysLoggingDetailComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["SYS_LOGGING"] } },
        ]
      },
      {
        path: "stat", component: StatMenuComponent,
        children: [
          { path: "stat-token-status", component: StatTokenStatusComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-token-enroll", component: StatTokenEnrollComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-token-verify-error", component: StatTokenVerifyErrorComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-device-system", component: StatDeviceSystemComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-device-brand", component: StatDeviceBrandComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-device-manufacturer", component: StatDeviceManufacturerComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
