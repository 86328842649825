import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-dev-system-list',
  templateUrl: './dev-system-list.component.html',
  styleUrls: ['./dev-system-list.component.css']
})
export class DevSystemListComponent implements OnInit {
	error=null
	loading = false
	hasEditPerm = false;
	devSystemList=null
	devSystemPagination={
		currentPage:1,
		pageSize:10,
		pageCount:0,
		rowCount:0,
		pageList:null
	}
	searchForm: FormGroup=this.formBuilder.group({
		nameLike: ['' ,[ Validators.maxLength(200)] ],
		status: [null ]
	});
	systemStatusOptionList=[
		{ value : null, label : "Tất cả" },
		{ value : 0, label : "Ngừng sử dụng" },
		{ value : 1, label : "Sử dụng" }
	]
	systemStatusOptionMap={}
	constructor(private authService: AuthService,private formBuilder: FormBuilder,
	private httpService:BaseHttpService,
	private route: ActivatedRoute,
	private router: Router ) { }
	ngOnInit(): void {
		this.authService.hasAnyPerm(['DEV_SYSTEM']).subscribe(hasPerm => {
		  this.hasEditPerm = hasPerm;
		})
		for(var systemStatusOption of this.systemStatusOptionList){
			this.systemStatusOptionMap[systemStatusOption.value]=systemStatusOption.label;
		}
		this.searchSystem();
	}

	devSystemPageChange(page){
		if(this.loading){
			return;
		}
    this.error=null;
		var searchFormData=this.searchForm.getRawValue()
		var findRequest:any = {}
		var nameLike = searchFormData["nameLike"];
		if(nameLike){
			findRequest.nameLike= `%${nameLike}%`;
		}
		var status = searchFormData["status"];
		if(status !== null){
			findRequest.status= status;
		}
		if(page<1){
			page=1
		}
		findRequest.queryOffset=(page-1)*this.devSystemPagination.pageSize;
		findRequest.queryLimit=this.devSystemPagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/dev/system/search`,findRequest).subscribe(findOtpSystemResponse=>{
            this.loading=false;
			var rowCount = findOtpSystemResponse.rowCount;
			var pageCount = Math.ceil(rowCount/this.devSystemPagination.pageSize);
			var pageList=[]
			for(var i=page-2;i<=page+2;i++){
				if(i>=1&&i<=pageCount){
					pageList.push(i);
				}
			}
			this.devSystemPagination.currentPage=page;
			this.devSystemPagination.rowCount=rowCount;
			this.devSystemPagination.pageCount=pageCount;
			this.devSystemPagination.pageList=pageList;
			this.devSystemList= findOtpSystemResponse.resultList;
		},errorResponse=>{
            this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchSystem(){
		this.devSystemPageChange(1);
	}
	viewSystem(devSystem){
		this.router.navigate(['view',devSystem.id],{ relativeTo: this.route })
	}
	versionSystem(devSystem){
		this.router.navigate(['version',devSystem.id],{ relativeTo: this.route })
	}
	insertSystem(){
		this.router.navigate(['create'],{ relativeTo: this.route })
	}
	editSystem(devSystem){
		this.router.navigate(['edit',devSystem.id],{ relativeTo: this.route })
	}
	deleteSystem(devSystem){
		this.router.navigate(['delete',devSystem.id],{ relativeTo: this.route })
	}
}


