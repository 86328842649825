import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';
import { SearchService } from 'src/app/service/search.service';

@Component({
	selector: 'app-otp-token-list',
	templateUrl: './otp-token-list.component.html',
	styleUrls: ['./otp-token-list.component.css']
})
export class OtpTokenListComponent implements OnInit {
	error=null
	loading = false
	hasEditPerm = false;
	otpTokenList = null
	otpTokenPagination = {
		currentPage: 1,
		pageSize: 10,
		pageCount: 0,
		rowCount: 0,
		pageList: null
	}
	searchForm: FormGroup = this.formBuilder.group({
		idLike: ['', [Validators.maxLength(50)]],
		accountIdLike: ['', [Validators.maxLength(200)]],
		phoneNumberLike: ['', [Validators.maxLength(200)]],
		status: [null]
	});
	tokenStatusOptionList = [
		{ value: null, label: "Tất cả" },
		{ value: 0, label: "Ngừng sử dụng" },
		{ value: 1, label: "Đang sử dụng" },
		{ value: 2, label: "Đã bị khóa" }
	]
	tokenStatusOptionMap = {}
	devDeviceId=null;
	constructor(private authService: AuthService,
		private formBuilder: FormBuilder,
		private httpService: BaseHttpService,
		private searchService:SearchService,
		private route: ActivatedRoute,
		private router: Router) { }
	ngOnInit(): void {
		this.authService.hasAnyPerm(['OTP_TOKEN']).subscribe(hasPerm => {
			this.hasEditPerm = hasPerm;
		})
		for (var tokenStatusOption of this.tokenStatusOptionList) {
			this.tokenStatusOptionMap[tokenStatusOption.value] = tokenStatusOption.label;
		}
		var prevSearch=this.searchService.get("OtpTokenList");
		if(prevSearch){
			this.searchForm.patchValue(prevSearch.formData);
			this.otpTokenPagination=prevSearch.pagination
		}
		this.route.params.subscribe(params => {
			if(params.deviceId){
				this.devDeviceId=params.deviceId;
			}
			this.searchToken();
		})
	}

	otpTokenPageChange(page) {
		if(this.loading){
			return;
		}
		this.error = null;
		var searchFormData = this.searchForm.getRawValue()
		var findRequest: any = {}
		var idLike = searchFormData["idLike"];
		if (idLike) {
			findRequest.idLike = `%${idLike}%`;
		}
		var accountIdLike = searchFormData["accountIdLike"];
		if (accountIdLike) {
			findRequest.accountIdLike = `%${accountIdLike}%`;
		}
		var phoneNumberLike = searchFormData["phoneNumberLike"];
		if (phoneNumberLike) {
			findRequest.phoneNumberLike = `${phoneNumberLike}`;
		}
		var status = searchFormData["status"];
		if (status !== null) {
			findRequest.status = status;
		}
		if(this.devDeviceId){
			findRequest.devDeviceId = this.devDeviceId;
		}
		if (page < 1) {
			page = 1
		}
		findRequest.queryOffset = (page - 1) * this.otpTokenPagination.pageSize;
		findRequest.queryLimit = this.otpTokenPagination.pageSize;
        this.loading=true;
		this.httpService.post<any>(`/api/admin/otp/token/search`, findRequest).subscribe(findOtpTokenResponse => {
			this.loading=false;
			var rowCount = findOtpTokenResponse.rowCount;
			var pageCount = Math.ceil(rowCount / this.otpTokenPagination.pageSize);
			var pageList = []
			for (var i = page - 2; i <= page + 2; i++) {
				if (i >= 1 && i <= pageCount) {
					pageList.push(i);
				}
			}
			this.otpTokenPagination.currentPage = page;
			this.otpTokenPagination.rowCount = rowCount;
			this.otpTokenPagination.pageCount = pageCount;
			this.otpTokenPagination.pageList = pageList;
			this.otpTokenList = findOtpTokenResponse.resultList;
			this.searchService.save("OtpTokenList",searchFormData,this.otpTokenPagination)
		}, errorResponse => {
			this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
			if (errorResponse.status > 0) {
				if (errorResponse.error?.errorCode) {
					this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
					return;
				}
				this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
				return;
			}
			this.error = "Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchToken() {
		this.otpTokenPageChange(1);
	}
	viewToken(otpToken) {
		this.router.navigate(['view', otpToken.id], { relativeTo: this.route })
	}
	disableToken(otpToken) {
		this.router.navigate(['disable', otpToken.id], { relativeTo: this.route })
	}
	lockToken(otpToken) {
		this.router.navigate(['lock', otpToken.id], { relativeTo: this.route })
	}
	unlockToken(otpToken) {
		this.router.navigate(['unlock', otpToken.id], { relativeTo: this.route })
	}
	cancel(){
		this.router.navigate(['../'], { relativeTo: this.route });
	}
}


