import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-dev-system-detail',
  templateUrl: './dev-system-detail.component.html',
  styleUrls: ['./dev-system-detail.component.css']
})
export class DevSystemDetailComponent implements OnInit {
	error=null
	loading = false
  hasEditPerm = false;
	submitSuccess = false
  detailForm: FormGroup = this.formBuilder.group({
    name: ["", [Validators.required, Validators.maxLength(200)]],
    description: ["", [Validators.maxLength(2000)]],
    note: ["", [Validators.maxLength(2000)]],
    status: [0, [Validators.required]]
  });
  systemId=null;
  systemAction="create";

  statusOptionList=[
    {value:0,label:"Ngừng sử dụng"},
    {value:1,label:"Sử dụng"}
  ]

  constructor(private authService: AuthService,private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.authService.hasAnyPerm(['DEV_SYSTEM']).subscribe(hasPerm => {
      this.hasEditPerm = hasPerm;
    })
    this.route.params.subscribe(params=>{
      this.error=null;
      if(params.systemId){
        this.systemAction="view";
        if(params.systemAction){
          this.systemAction=params.systemAction;
        }
        this.createForm();
          this.systemId=params.systemId;
          this.loading=true;
          this.httpService.get<any>(`/api/admin/dev/system/${this.systemId}`).subscribe(response=>{
            this.loading=false;
            if(response.result){
              this.detailForm.patchValue(response.result);
            }else if(response.errorCode){
              this.error=response.errorCode+":"+response.errorMessage;
            }
        },errorResponse=>{
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if(errorResponse.status>0){
            if(errorResponse.error?.errorCode){
              this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error="Lỗi kết nối! Vui lòng thử lại sau";
        })
      }else{
        this.systemAction="create";
        this.createForm();
      }

    })
  }
  createForm(){
    if(this.systemAction==="create"){
      this.detailForm.enable()
    }else if(this.systemAction==="edit"){
      this.detailForm.enable()
      this.detailForm.get('name').disable();
    }else{
      this.detailForm.disable()
    }
  }

  submit(){
		if(this.loading){
			return;
		}
    this.error=null;
    if(this.systemAction==="create"){
      var detailData=this.detailForm.getRawValue();
      this.loading=true;
      this.httpService.put<any>(`/api/admin/dev/system`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.systemAction==="edit"){
      var detailData=this.detailForm.getRawValue();
      this.loading=true;
      this.httpService.post<any>(`/api/admin/dev/system/${this.systemId}`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.systemAction==="delete"){
      this.loading=true;
      this.httpService.delete<any>(`/api/admin/dev/system/${this.systemId}`).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
  }
  cancel(){
    if(this.systemAction==="create"){
      this.router.navigate(['../'], { relativeTo: this.route });
    }else{
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }
}
