<div class="row">
    <div class="col-md-3 col-lg-2 col-sm-12 d-md-block bg-light sidebar">
        <ul class="nav flex-column">
          <li class="nav-item" routerLinkActive="active" *ngFor="let menu of userMenuList">
            <a class="nav-link"   (click)="navigation(menu.navigation)">{{menu.title}}</a>
          </li>
          </ul>
    </div>
    <div class="col-md-9 ml-sm-auto col-lg-10 col-sm-12 px-4">
        <router-outlet></router-outlet>
    </div>
  </div>