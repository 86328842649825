<div *ngIf="hasData">
  <div style="display: block;" *ngIf="chartType==='pie'">
    <canvas baseChart 
      [data]="pieChartData" 
      [labels]="pieChartLabels" 
      [chartType]="pieChartType"
      [options]="pieChartOptions"
      [plugins]="pieChartPlugins"
      [colors]="pieChartColors"
      [legend]="pieChartLegend">
    </canvas>
  </div>
  <div style="display: block;" *ngIf="chartType==='bar'">
    <canvas baseChart 
      [datasets]="barChartData"
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [chartType]="barChartType">
    </canvas>
  </div>
</div>
<div *ngIf="!hasData">
  Không có dữ liệu
</div>
