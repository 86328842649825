import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';
import * as cryptoJS from 'crypto-js';

@Component({
  selector: 'app-sys-user-detail',
  templateUrl: './sys-user-detail.component.html',
  styleUrls: ['./sys-user-detail.component.css']
})
export class SysUserDetailComponent implements OnInit {
	error=null
	loading = false
	submitSuccess = false
  detailForm: FormGroup = this.formBuilder.group({
    sysBranchId:[null, [Validators.required]],
    fullname: ["", [Validators.required, Validators.maxLength(50)]],
    username: ["", [Validators.required, Validators.maxLength(50)]],
    email: ["", [Validators.maxLength(200)]],
    phoneNumber: ["", [ Validators.maxLength(200)]],
    password: ["", [Validators.required, Validators.maxLength(200)]],
    confirmPassword: ["", [Validators.required, Validators.maxLength(200)]],
    note: ["", [Validators.maxLength(2000)]],
    status: [0, [Validators.required]]
  });
  sysUserRoleList = null;
  userId = null;
  userAction = "create";

  statusOptionList = [
    { value: 0, label: "Ngừng sử dụng" },
    { value: 1, label: "Sử dụng" }
  ]

  sysBranchIdOptionList = null;

  constructor(private formBuilder: FormBuilder, private httpService: BaseHttpService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    
    this.httpService.get<any>(`/api/admin/sys/branch`,{status:1}).subscribe(sysbranchListResponse => {
      var sysbranchList = sysbranchListResponse.resultList;
      var sysBranchIdOptionList=[];
      for (var sysbranch of sysbranchList) {
        sysBranchIdOptionList.push({value:sysbranch.id,label:sysbranch.code+" - "+sysbranch.name});
      }
      this.sysBranchIdOptionList=sysBranchIdOptionList;
    }, errorResponse => {
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if (errorResponse.status > 0) {
        if (errorResponse.error?.errorCode) {
          this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error = "Lỗi kết nối! Vui lòng thử lại sau";
    })

    this.route.params.subscribe(params => {
      this.error = null;
      if (params.userId) {
        this.userAction = "view";
        if (params.userAction) {
          this.userAction = params.userAction;
        }
        this.createForm();
        this.userId = params.userId;
        this.loading=true;
        this.httpService.get<any>(`/api/admin/sys/user/${this.userId}`).subscribe(response => {
          this.loading=false;
          if (response.result) {
            this.detailForm.patchValue(response.result);
            if (response.result.sysUserRoleList) {
              this.loading=true;
              this.httpService.get<any>(`/api/admin/sys/role`,{status:1}).subscribe(sysRoleListResponse => {
                this.loading=false;
                var sysRoleList = sysRoleListResponse.resultList;
                var sysUserRoleMap = {}
                for (var sysUserRole of response.result.sysUserRoleList) {
                  sysUserRoleMap[sysUserRole.sysRoleId] = sysUserRole;
                }
                var sysUserRoleList=[];
                for (var sysRole of sysRoleList) {
                  var sysUserRole=sysUserRoleMap[sysRole.id];
                  if(!sysUserRole){
                    sysUserRole={
                      sysRoleId:sysRole.id,
                      deletedEntity:true
                    }
                  }
                  sysUserRole.sysRole=sysRole
                  sysUserRoleList.push(sysUserRole)
                }
                this.sysUserRoleList=sysUserRoleList;
              }, errorResponse => {
                this.loading=false;
                if(errorResponse.status==403){
                  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
                  return;
                }
                if (errorResponse.status > 0) {
                  if (errorResponse.error?.errorCode) {
                    this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
                    return;
                  }
                  this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
                  return;
                }
                this.error = "Lỗi kết nối! Vui lòng thử lại sau";
              })
            }
          } else if (response.errorCode) {
            this.error = response.errorCode + ":" + response.errorMessage;
          }
        }, errorResponse => {
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if (errorResponse.status > 0) {
            if (errorResponse.error?.errorCode) {
              this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error = "Lỗi kết nối! Vui lòng thử lại sau";
        })
      } else {
        this.userAction = "create";
        this.createForm();
        this.loading=true;
        this.httpService.get<any>(`/api/admin/sys/role`,{status:1}).subscribe(sysRoleListResponse => {
          this.loading=false;
          var sysRoleList = sysRoleListResponse.resultList;
          var sysUserRoleList=[];
          for (var sysRole of sysRoleList) {
            var sysUserRole={
              sysRoleId:sysRole.id,
              sysRole:sysRole,
              deletedEntity:true
            }
            sysUserRoleList.push(sysUserRole)
          }
          this.sysUserRoleList=sysUserRoleList;
        }, errorResponse => {
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if (errorResponse.status > 0) {
            if (errorResponse.error?.errorCode) {
              this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error = "Lỗi kết nối! Vui lòng thử lại sau";
        })
      }

    })
  }
  createForm() {
    if (this.userAction === "create") {
      this.detailForm.enable()
    } else if (this.userAction === "edit") {
      this.detailForm.enable()
      this.detailForm.get('username').disable();
      this.detailForm.get('password').disable();
      this.detailForm.get('confirmPassword').disable();
    } else if (this.userAction === "password") {
      this.detailForm.enable()
      this.detailForm.get('fullname').disable();
      this.detailForm.get('username').disable();
      this.detailForm.get('email').disable();
      this.detailForm.get('phoneNumber').disable();
    } else {
      this.detailForm.disable()
    }
  }

  submit() {
		if(this.loading){
			return;
		}
    this.error = null;
    if (this.userAction === "create") {
      var detailData = this.detailForm.getRawValue();
      if (!detailData.password) {
        this.error = "Nhập mật khẩu";
        return;
      }
      if (!detailData.confirmPassword) {
        this.error = "Nhập lại mật khẩu";
        return;
      }
      if (detailData.password !== detailData.confirmPassword) {
        this.error = "Mật khẩu không trùng nhau";
        return;
      }
      var passwordSHA256 = cryptoJS.HmacSHA256(detailData.username, detailData.password);
      detailData.password = `${passwordSHA256}`;
      detailData.confirmPassword = null;
      detailData.sysUserRoleList=this.sysUserRoleList;
			this.loading=false;
      this.httpService.put<any>(`/api/admin/sys/user`, detailData).subscribe(response => {
        this.loading=false;
        if (response.result) {
          this.submitSuccess=true;
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }
    if (this.userAction === "edit") {
      var detailData = this.detailForm.getRawValue();
      detailData.sysUserRoleList=this.sysUserRoleList;
			this.loading=true;
      this.httpService.post<any>(`/api/admin/sys/user/${this.userId}`, detailData).subscribe(response => {
        this.loading=false;
        if (response.result) {
          this.submitSuccess=true;
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }
    if (this.userAction === "password") {
      var detailData = this.detailForm.getRawValue();
      if (!detailData.password) {
        this.error = "Nhập mật khẩu";
        return;
      }
      if (!detailData.confirmPassword) {
        this.error = "Nhập lại mật khẩu";
        return;
      }
      if (detailData.password !== detailData.confirmPassword) {
        this.error = "Mật khẩu không trùng nhau";
        return;
      }
      var passwordSHA256 = cryptoJS.HmacSHA256(detailData.username, detailData.password);
      detailData.password = `${passwordSHA256}`;
      detailData.confirmPassword = null;
			this.loading=true;
      this.httpService.post<any>(`/api/admin/sys/user/${this.userId}/password`, detailData).subscribe(response => {
        this.loading=false;
        if (response.result) {
          this.submitSuccess=true;
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading=false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }
  }
  cancel() {
    if (this.userAction === "create") {
      this.router.navigate(['../'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }
}
