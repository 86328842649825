<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" [routerLink]="['/']">Smart OTP Admin</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav  mr-auto mt-2 mt-lg-0">
      <li class="nav-item" routerLinkActive="active" *ngFor="let menu of userMenuList">
        <a class="nav-link"  (click)="navigation(menu.navigation)">{{menu.title}}</a>
      </li>
    </ul>
      <ul class="navbar-nav my-2 my-lg-0" *ngIf="userdetail">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown">
            Xin chào, {{userdetail.fullname}}
          </a>
          <ul class="dropdown-menu" >
            <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="['/user-password']">Đổi mật khẩu</a>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="['/login', { action: 'logout' }]">Thoát</a>
            </li>
          </ul>
        </li>
      </ul>
  </div>
</nav>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>