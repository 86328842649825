<div class="card">
    <div class="card-body">
        <h5 class="card-title">Tìm kiếm ứng dụng</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-text label="Mã" placeholder="Nhập mã" [formGroup]="searchForm" formControlName="codeLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-text label="Tên" placeholder="Nhập tên" [formGroup]="searchForm" formControlName="nameLike"></app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="searchForm" formControlName="status" [optionList]="applicationStatusOptionList"></app-input-select>
                </div>
                <div class="col">
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="searchApplication()" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tìm kiếm</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Kết quả tìm kiếm</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Mã</th>
                    <th scope="col">Tên</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary" *ngIf="hasEditPerm" (click)="insertApplication()">Thêm</button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="sysApplicationList && !loading">
                <tr *ngFor="let sysApplication of sysApplicationList;index as sysApplicationIndex">
                    <td scope="col">{{(sysApplicationPagination.currentPage-1)*sysApplicationPagination.pageSize + sysApplicationIndex+1}}</td>
                    <td scope="col">{{sysApplication.code}}</td>
                    <td scope="col">{{sysApplication.name}}</td>
                    <td scope="col">{{applicationStatusOptionMap[sysApplication.status]}}</td>
                    <td scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-info" (click)="viewApplication(sysApplication)">Xem</button>
                            <button type="button" class="btn btn-primary" *ngIf="hasEditPerm" (click)="editApplication(sysApplication)">Sửa</button>
                            <button type="button" class="btn btn-danger"  *ngIf="hasEditPerm" (click)="deleteApplication(sysApplication)">Xóa</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="d-flex justify-content-between align-items-center" *ngIf="sysApplicationPagination.pageCount>1 && !loading">
            <nav>
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{'disabled':sysApplicationPagination.currentPage == 1}">
                      <a class="page-link" (click)="sysApplicationPageChange(sysApplicationPagination.currentPage-1)">Trước</a>
                    </li>
                    <li class="page-item" *ngIf="sysApplicationPagination.currentPage > 3">
                      <a class="page-link" (click)="sysApplicationPageChange(1)">1</a>
                    </li>
                  <li class="page-item disabled" *ngIf="sysApplicationPagination.currentPage > 3">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngFor="let page of sysApplicationPagination.pageList"  [ngClass]="{'active':sysApplicationPagination.currentPage == page}">
                    <a class="page-link" (click)="sysApplicationPageChange(page)">{{page}}</a>
                  </li>
                  <li class="page-item disabled" *ngIf="sysApplicationPagination.currentPage < sysApplicationPagination.pageCount - 2">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngIf="sysApplicationPagination.currentPage < sysApplicationPagination.pageCount - 2">
                    <a class="page-link" (click)="sysApplicationPageChange(sysApplicationPagination.pageCount)">{{sysApplicationPagination.pageCount}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled':sysApplicationPagination.currentPage == sysApplicationPagination.pageCount}">
                    <a class="page-link"  (click)="sysApplicationPageChange(sysApplicationPagination.currentPage+1)">Sau</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>
</div>

