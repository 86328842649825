<div class="card">
    <div class="card-body">
        <h5 class="card-title">Số lượng thiết bị theo hệ điều hành</h5>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="showStatisticChart()" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Biểu đồ</button>
            <button type="button" class="btn btn-primary" (click)="downloadStatisticExcel()" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Excel</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Biểu đồ</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div *ngIf="showChart">
            <div style="display: block;">
                <canvas baseChart 
                  [data]="pieChartData" 
                  [labels]="pieChartLabels" 
                  [chartType]="pieChartType"
                  [options]="pieChartOptions"
                  [plugins]="pieChartPlugins"
                  [colors]="pieChartColors"
                  [legend]="pieChartLegend">
                </canvas>
              </div>
        </div>
    </div>
</div>