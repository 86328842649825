<div class="card">
    <div class="card-body">
        <h5 class="card-title">Thông tin nhật ký</h5>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <app-input-select label="Thao tác" placeholder="Chọn thao tác" [formGroup]="detailForm" formControlName="action" [optionList]="loggingActionOptionList"></app-input-select>
            <app-input-text label="Tài khoản" placeholder="Nhập tài khoản" [formGroup]="detailForm" formControlName="accountId"></app-input-text>
            <app-input-text label="Từ ứng dụng" placeholder="Nhập ứng dụng" [formGroup]="detailForm" formControlName="createApplicationCode"></app-input-text>
            <app-input-text label="Người thực hiện" placeholder="Nhập người thực hiện" [formGroup]="detailForm" formControlName="createUsername"></app-input-text>
            <app-input-date label="Từ ngày" placeholder="Nhập từ ngày" [formGroup]="detailForm" formControlName="createDate"></app-input-date>
            <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="detailForm" formControlName="status" [optionList]="loggingStatusOptionList"></app-input-select>
            <app-input-textarea label="Dữ liệu" placeholder="Nhập dữ liệu" [formGroup]="detailForm" formControlName="loggingData"></app-input-textarea>
            <app-input-textarea label="Mô tả" placeholder="Nhập mô tả" [formGroup]="detailForm" formControlName="description" rows="5"></app-input-textarea>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
            </div>
        </div>
        </form>
    </div>
</div>