import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
	selector: 'app-sys-application-list',
	templateUrl: './sys-application-list.component.html',
	styleUrls: ['./sys-application-list.component.css']
})
export class SysApplicationListComponent implements OnInit {
	error=null
	loading = false
	hasEditPerm = false;
	sysApplicationList = null
	sysApplicationPagination = {
		currentPage: 1,
		pageSize: 10,
		pageCount: 0,
		rowCount: 0,
		pageList: null
	}
	searchForm: FormGroup = this.formBuilder.group({
		codeLike: ['', [Validators.maxLength(200)]],
		nameLike: ['', [Validators.maxLength(200)]],
		status: [null]
	});
	applicationStatusOptionList = [
		{ value: null, label: "Tất cả" },
		{ value: 0, label: "Ngừng sử dụng" },
		{ value: 1, label: "Sử dụng" }
	]
	applicationStatusOptionMap = {}
	constructor(private authService: AuthService,
		private formBuilder: FormBuilder,
		private httpService: BaseHttpService,
		private route: ActivatedRoute,
		private router: Router) { }
	ngOnInit(): void {
		this.authService.hasAnyPerm(['SYS_APPLICATION']).subscribe(hasPerm => {
			this.hasEditPerm = hasPerm;
		})
		for (var applicationStatusOption of this.applicationStatusOptionList) {
			this.applicationStatusOptionMap[applicationStatusOption.value] = applicationStatusOption.label;
		}
		this.searchApplication();
	}

	sysApplicationPageChange(page) {
		if(this.loading){
			return;
		}
		this.error = null;
		var searchFormData = this.searchForm.getRawValue()
		var findRequest: any = {}
		var codeLike = searchFormData["codeLike"];
		if (codeLike) {
			findRequest.codeLike = `%${codeLike}%`;
		}
		var nameLike = searchFormData["nameLike"];
		if (nameLike) {
			findRequest.nameLike = `%${nameLike}%`;
		}
		var status = searchFormData["status"];
		if (status !== null) {
			findRequest.status = status;
		}
		if (page < 1) {
			page = 1
		}
		findRequest.queryOffset = (page - 1) * this.sysApplicationPagination.pageSize;
		findRequest.queryLimit = this.sysApplicationPagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/sys/application/search`, findRequest).subscribe(findOtpApplicationResponse => {
			this.loading=false;
			var rowCount = findOtpApplicationResponse.rowCount;
			var pageCount = Math.ceil(rowCount / this.sysApplicationPagination.pageSize);
			var pageList = []
			for (var i = page - 2; i <= page + 2; i++) {
				if (i >= 1 && i <= pageCount) {
					pageList.push(i);
				}
			}
			this.sysApplicationPagination.currentPage = page;
			this.sysApplicationPagination.rowCount = rowCount;
			this.sysApplicationPagination.pageCount = pageCount;
			this.sysApplicationPagination.pageList = pageList;
			this.sysApplicationList = findOtpApplicationResponse.resultList;
		}, errorResponse => {
			this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
			if (errorResponse.status > 0) {
				if (errorResponse.error?.errorCode) {
					this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
					return;
				}
				this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
				return;
			}
			this.error = "Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchApplication() {
		this.sysApplicationPageChange(1);
	}
	viewApplication(sysApplication) {
		this.router.navigate(['view', sysApplication.id], { relativeTo: this.route })
	}
	insertApplication() {
		this.router.navigate(['create'], { relativeTo: this.route })
	}
	editApplication(sysApplication) {
		this.router.navigate(['edit', sysApplication.id], { relativeTo: this.route })
	}
	deleteApplication(sysApplication) {
		this.router.navigate(['delete', sysApplication.id], { relativeTo: this.route })
	}
}


