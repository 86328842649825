import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';
import * as moment from 'moment';
import { SearchService } from 'src/app/service/search.service';
@Component({
	selector: 'app-otp-logging-list',
	templateUrl: './otp-logging-list.component.html',
	styleUrls: ['./otp-logging-list.component.css']
})
export class OtpLoggingListComponent implements OnInit {
	error = null
	loading = false
	otpLoggingList = null
	otpLoggingPagination = {
		currentPage: 1,
		pageSize: 10,
		pageCount: 0,
		rowCount: 0,
		pageList: null
	}
	searchForm: FormGroup = this.formBuilder.group({
		action: [null],
		accountIdLike: ['', [Validators.maxLength(200)]],
		createApplicationCodeLike: ['', [Validators.maxLength(200)]],
		createUsernameLike: ['', [Validators.maxLength(200)]],
		loggingDataLike: ['', [Validators.maxLength(200)]],
		createDateGt: [null],
		createDateLt: [null],
		status: [null]
	});
	loggingActionOptionList = [
		{ value: null, label: "Tất cả" },
		{ value: "OTP_INITIALIZATION_REQUEST",label:"Tạo đề nghị tạo mã kích hoạt Smart OTP" },
		{ value: "OTP_TOKEN_UNLOCK_REQUEST",label:"Tạo đề nghị mở khóa token" },
		{ value: "OTP_TOKEN_LOCK_REQUEST",label:"Tạo đề nghị khóa token" },
		{ value: "OTP_TOKEN_DISABLE_REQUEST",label:"Tạo đề nghị ngừng sử dụng token" },
		{ value: "OTP_REQUEST_TELLER_APPROVE",label:"Giao dịch viên gửi đề nghị"},
		{ value: "OTP_REQUEST_SUPERVISOR_APPROVE",label:"Kiểm soát viên phê duyệt đề nghị"},
		{ value: "OTP_REQUEST_SUPERVISOR_REJECT",label:"Kiểm soát viên từ chối đề nghị"},
		{ value: "OTP_INITIALIZATION", label: "Tạo mã kích hoạt Smart OTP" },
		{ value: "OTP_ENROLL", label: "Kích hoạt Smart OTP" },
		{ value: "OTP_VERIFY", label: "Xác thực OTP" },
		{ value: "OTP_TOKEN_UNLOCK", label: "Mở khóa Token" },
		{ value: "OTP_TOKEN_LOCK", label: "Khóa Token" },
		{ value: "OTP_TOKEN_CHANGE_PIN", label: "Đổi PIN Token" },
		{ value: "OTP_TOKEN_RESTORE_PIN", label: "Khởi tạo lại PIN Token" },
		{ value: "OTP_TOKEN_DISABLE", label: "Ngừng sử dụng Token" }
	]
	loggingActionOptionMap = {}
	loggingStatusOptionList = [
		{ value: null, label: "Tất cả" },
		{ value: 0, label: "Xác thực OTP lỗi" },
		{ value: 1, label: "Xác thực OTP thành công" }
	]
	loggingStatusOptionMap = {}
	constructor(private formBuilder: FormBuilder,
		private httpService: BaseHttpService,
		private searchService:SearchService,
		private route: ActivatedRoute,
		private router: Router) { }
	ngOnInit(): void {
		for (var loggingActionOption of this.loggingActionOptionList) {
			this.loggingActionOptionMap[loggingActionOption.value] = loggingActionOption.label;
		}
		for (var loggingStatusOption of this.loggingStatusOptionList) {
			this.loggingStatusOptionMap[loggingStatusOption.value] = loggingStatusOption.label;
		}
		var prevSearch=this.searchService.get("OtpLoggingList");
		if(prevSearch){
			this.searchForm.patchValue(prevSearch.formData);
			this.otpLoggingPagination=prevSearch.pagination
		}
		this.searchLogging();
	}
	otpLoggingPageChange(page) {
		if(this.loading){
			return;
		}
		this.error = null;
		var searchFormData = this.searchForm.getRawValue()
		var findRequest: any = {}
		var action = searchFormData["action"];
		if (action !== null) {
			findRequest.action = action;
		}
		var accountIdLike = searchFormData["accountIdLike"];
		if (accountIdLike) {
			findRequest.accountIdLike = `%${accountIdLike}%`;
		}
		var createApplicationCodeLike = searchFormData["createApplicationCodeLike"];
		if (createApplicationCodeLike) {
			findRequest.createApplicationCodeLike = `%${createApplicationCodeLike}%`;
		}
		var createUsernameLike = searchFormData["createUsernameLike"];
		if (createUsernameLike) {
			findRequest.createUsernameLike = `%${createUsernameLike}%`;
		}
		var loggingDataLike = searchFormData["loggingDataLike"];
		if (loggingDataLike) {
			findRequest.loggingDataLike = `%${loggingDataLike}%`;
		}
		var createDateGt = searchFormData["createDateGt"];
		if (createDateGt) {
			findRequest.createDateGt = createDateGt;
		}
		var createDateLt = searchFormData["createDateLt"];
		if (createDateLt) {
			findRequest.createDateLt = createDateLt;
		}
		var status = searchFormData["status"];
		if (status !== null) {
			findRequest.status = status;
		}
		if (page < 1) {
			page = 1
		}
		findRequest.queryOffset = (page - 1) * this.otpLoggingPagination.pageSize;
		findRequest.queryLimit = this.otpLoggingPagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/otp/logging/search`, findRequest).subscribe(findOtpLoggingResponse => {
			this.loading=false;
			var rowCount = findOtpLoggingResponse.rowCount;
			var pageCount = Math.ceil(rowCount / this.otpLoggingPagination.pageSize);
			var pageList = []
			for (var i = page - 2; i <= page + 2; i++) {
				if (i >= 1 && i <= pageCount) {
					pageList.push(i);
				}
			}
			this.otpLoggingPagination.currentPage = page;
			this.otpLoggingPagination.rowCount = rowCount;
			this.otpLoggingPagination.pageCount = pageCount;
			this.otpLoggingPagination.pageList = pageList;
			this.otpLoggingList = findOtpLoggingResponse.resultList;
			this.searchService.save("OtpLoggingList",searchFormData,this.otpLoggingPagination)
		}, errorResponse => {
			this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
			if (errorResponse.status > 0) {
				if (errorResponse.error?.errorCode) {
					this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
					return;
				}
				this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
				return;
			}
			this.error = "Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchLogging() {
		this.otpLoggingPageChange(1);
	}
	viewLogging(otpLogging) {
		this.router.navigate(['view', otpLogging.id], { relativeTo: this.route })
	}
}


