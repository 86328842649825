import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-sys-application-detail',
  templateUrl: './sys-application-detail.component.html',
  styleUrls: ['./sys-application-detail.component.css']
})
export class SysApplicationDetailComponent implements OnInit {
	error=null
	loading = false
  hasEditPerm = false;
	submitSuccess = false
  detailForm: FormGroup = this.formBuilder.group({
    code: ["", [Validators.required, Validators.maxLength(50)]],
    name: ["", [Validators.required, Validators.maxLength(200)]],
    ocraSuiteLowValue: ["", [Validators.required, Validators.maxLength(200)]],
    ocraSuiteHighValue: ["", [Validators.required, Validators.maxLength(200)]],
    publicKey: ["", [Validators.required]],
    description: ["", [Validators.maxLength(2000)]],
    note: ["", [Validators.maxLength(2000)]],
    status: [0, [Validators.required]],
    updateStatusUrl: [""],
    authenticationKeyId: [""],
    syncStatus: [0],
  });
  sysApplicationPermList = null;
  applicationId=null;
  applicationAction="create";

  statusOptionList=[
    {value:0,label:"Ngừng sử dụng"},
    {value:1,label:"Sử dụng"}
  ]

  syncStatusOptionList=[
    {value:0,label:"Ngừng đồng bộ"},
    {value:1,label:"Đồng bộ"}
  ]
  sysRsaKeyOptionList=null;

  constructor(private authService: AuthService,private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
		this.authService.hasAnyPerm(['SYS_APPLICATION']).subscribe(hasPerm => {
			this.hasEditPerm = hasPerm;
		})
		this.httpService.post<any>(`/api/admin/sys/rsa-key/search`,{type:"API_CALL_KEY",status:1}).subscribe(findOtpRsaKeyResponse=>{
      var sysRsaKeyOptionList=[];
      for(var sysRsaKey of findOtpRsaKeyResponse.resultList){
        sysRsaKeyOptionList.push({value:sysRsaKey.id,label:sysRsaKey.name})
      }
			this.sysRsaKeyOptionList= sysRsaKeyOptionList;
		},errorResponse=>{
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
    this.route.params.subscribe(params=>{
      this.error=null;
      if(params.applicationId){
        this.applicationAction="view";
        if(params.applicationAction){
          this.applicationAction=params.applicationAction;
        }
        this.createForm();
          this.applicationId=params.applicationId;
          this.loading=true;
          this.httpService.get<any>(`/api/admin/sys/application/${this.applicationId}`).subscribe(response=>{
            this.loading=false;
            if(response.result){
              this.detailForm.patchValue(response.result);
              if (response.result.sysApplicationPermList) {
                this.loading=true;
                this.httpService.get<any>(`/api/admin/sys/perm`,{type:"APPLICATION",status:1}).subscribe(sysPermListResponse => {
                  this.loading=false;
                  var sysPermList = sysPermListResponse.resultList;
                  var sysApplicationPermMap = {}
                  for (var sysApplicationPerm of response.result.sysApplicationPermList) {
                    sysApplicationPermMap[sysApplicationPerm.sysPermId] = sysApplicationPerm;
                  }
                  var sysApplicationPermList=[];
                  for (var sysPerm of sysPermList) {
                    var sysApplicationPerm=sysApplicationPermMap[sysPerm.id];
                    if(!sysApplicationPerm){
                      sysApplicationPerm={
                        sysPermId:sysPerm.id,
                        deletedEntity:true
                      }
                    }
                    sysApplicationPerm.sysPerm=sysPerm
                    sysApplicationPermList.push(sysApplicationPerm)
                  }
                  this.sysApplicationPermList=sysApplicationPermList;
                }, errorResponse => {
                  this.loading=false;
                  if(errorResponse.status==403){
                    this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
                    return;
                  }
                  if (errorResponse.status > 0) {
                    if (errorResponse.error?.errorCode) {
                      this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
                      return;
                    }
                    this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
                    return;
                  }
                  this.error = "Lỗi kết nối! Vui lòng thử lại sau";
                })
              }
            }else if(response.errorCode){
              this.error=response.errorCode+":"+response.errorMessage;
            }
        },errorResponse=>{
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if(errorResponse.status>0){
            if(errorResponse.error?.errorCode){
              this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error="Lỗi kết nối! Vui lòng thử lại sau";
        })
      }else{
        this.applicationAction="create";
        this.createForm();
        this.loading=true;
        this.httpService.get<any>(`/api/admin/sys/perm`,{type:"APPLICATION",status:1}).subscribe(sysPermListResponse => {
          this.loading=false;
          var sysPermList = sysPermListResponse.resultList;
          var sysApplicationPermList=[];
          for (var sysPerm of sysPermList) {
            var sysApplicationPerm={
              sysPermId:sysPerm.id,
              sysPerm:sysPerm,
              deletedEntity:true
            }
            sysApplicationPermList.push(sysApplicationPerm)
          }
          this.sysApplicationPermList=sysApplicationPermList;
        }, errorResponse => {
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if (errorResponse.status > 0) {
            if (errorResponse.error?.errorCode) {
              this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error = "Lỗi kết nối! Vui lòng thử lại sau";
        })
      }

    })
  }
  createForm(){
    if(this.applicationAction==="create"){
      this.detailForm.enable()
    }else if(this.applicationAction==="edit"){
      this.detailForm.enable()
      this.detailForm.get('code').disable();
    }else{
      this.detailForm.disable()
    }
  }

  submit(){
		if(this.loading){
			return;
		}
    this.error=null;
    if(this.applicationAction==="create"){
      var detailData=this.detailForm.getRawValue();
      detailData.sysApplicationPermList=this.sysApplicationPermList;
      this.loading=true;
      this.httpService.put<any>(`/api/admin/sys/application`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.applicationAction==="edit"){
      var detailData=this.detailForm.getRawValue();
      detailData.sysApplicationPermList=this.sysApplicationPermList;
      this.loading=true;
      this.httpService.post<any>(`/api/admin/sys/application/${this.applicationId}`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.applicationAction==="delete"){
      this.loading=true;
      this.httpService.delete<any>(`/api/admin/sys/application/${this.applicationId}`).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
  }
  cancel(){
    if(this.applicationAction==="create"){
      this.router.navigate(['../'], { relativeTo: this.route });
    }else{
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }
}
