import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-sys-rsa-key-detail',
  templateUrl: './sys-rsa-key-detail.component.html',
  styleUrls: ['./sys-rsa-key-detail.component.css']
})
export class SysRsaKeyDetailComponent implements OnInit {
	error=null
	loading = false
  hasEditPerm = false;
	submitSuccess = false
  detailForm: FormGroup = this.formBuilder.group({
    id: ["", [Validators.required, Validators.maxLength(50)]],
    type: ["SLAVE", [Validators.required]],
    name: ["", [Validators.required, Validators.maxLength(200)]],
    notBefore:[null, [Validators.required]],
    notAfter:[null, [Validators.required]],
    publicKey: ["", [Validators.required]],
    description: ["", [Validators.maxLength(2000)]],
    note: ["", [Validators.maxLength(2000)]],
    status: [0, [Validators.required]],
    signatureKeyId:[null]
  });
  rsaKeyId=null;
  rsaKeyAction="create";

  typeOptionList=[
    {value:"MASTER",label:"Key chính"},
    {value:"SLAVE",label:"Key phụ"},
    {value:"API_CALL_KEY",label:"Key xác thực gọi API"}
  ]

  statusOptionList=[
    {value:0,label:"Ngừng sử dụng"},
    {value:1,label:"Sử dụng"}
  ]

  showSelectMasterKey=true;
  sysRsaKeyOptionList=null;

  constructor(private authService: AuthService,private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
		this.authService.hasAnyPerm(['SYS_RSA_KEY']).subscribe(hasPerm => {
		  this.hasEditPerm = hasPerm;
		})
		this.httpService.post<any>(`/api/admin/sys/rsa-key/search`,{type:"MASTER",status:1}).subscribe(findOtpRsaKeyResponse=>{
      var sysRsaKeyOptionList=[];
      for(var sysRsaKey of findOtpRsaKeyResponse.resultList){
        sysRsaKeyOptionList.push({value:sysRsaKey.id,label:sysRsaKey.name})
      }
			this.sysRsaKeyOptionList= sysRsaKeyOptionList;
		},errorResponse=>{
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
    this.route.params.subscribe(params=>{
      this.error=null;
      if(params.rsaKeyId){
        this.rsaKeyAction="view";
        if(params.rsaKeyAction){
          this.rsaKeyAction=params.rsaKeyAction;
        }
        this.createForm();
          this.rsaKeyId=params.rsaKeyId;
          this.loading=true;
          this.httpService.get<any>(`/api/admin/sys/rsa-key/${this.rsaKeyId}`).subscribe(response=>{
            this.loading=false;
            if(response.result){
              if(response.result.type&&response.result.type==='MASTER'){
                this.showSelectMasterKey = false;
              }else{
                this.showSelectMasterKey = true;
              }
              this.detailForm.patchValue(response.result);
            }else if(response.errorCode){
              this.error=response.errorCode+":"+response.errorMessage;
            }
        },errorResponse=>{
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if(errorResponse.status>0){
            if(errorResponse.error?.errorCode){
              this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error="Lỗi kết nối! Vui lòng thử lại sau";
        })
      }else{
        this.rsaKeyAction="create";
        this.showSelectMasterKey=true;
        this.createForm();
      }

    })
  }
  createForm(){
    if(this.rsaKeyAction==="create"){
      this.detailForm.enable()
      this.detailForm.get('id').disable();
      this.detailForm.get('publicKey').disable();
    }else if(this.rsaKeyAction==="edit"){
      this.detailForm.enable()
      this.detailForm.get('id').disable();
      this.detailForm.get('type').disable();
      this.detailForm.get('publicKey').disable();
      this.detailForm.get('signatureKeyId').disable();
    }else{
      this.detailForm.disable()
    }
  }

  typeChange(type){
    if(type&&type==='SLAVE'){
      this.showSelectMasterKey = true;
    }else{
      this.detailForm.patchValue({signatureKeyId:null})
      this.showSelectMasterKey = false;
    }
  }

  submit(){
		if(this.loading){
			return;
		}
    this.error=null;
    if(this.rsaKeyAction==="create"){
      var detailData=this.detailForm.getRawValue();
      this.loading=true;
      this.httpService.put<any>(`/api/admin/sys/rsa-key`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
			this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.rsaKeyAction==="edit"){
      var detailData=this.detailForm.getRawValue();
      this.loading=true;
      this.httpService.post<any>(`/api/admin/sys/rsa-key/${this.rsaKeyId}`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
			this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.rsaKeyAction==="delete"){
      this.loading=true;
      this.httpService.delete<any>(`/api/admin/sys/rsa-key/${this.rsaKeyId}`).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
			this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
  }
  cancel(){
    if(this.rsaKeyAction==="create"){
      this.router.navigate(['../'], { relativeTo: this.route });
    }else{
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }
}
