import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-otp-logging-detail',
  templateUrl: './otp-logging-detail.component.html',
  styleUrls: ['./otp-logging-detail.component.css']
})
export class OtpLoggingDetailComponent implements OnInit {
	error=null
	loading = false
  detailForm: FormGroup=this.formBuilder.group({
    action: [null ],
    accountId: ['' ,[ Validators.maxLength(200)] ],
    createApplicationCode: ['' ,[ Validators.maxLength(200)] ],
    createUsername: ['' ,[ Validators.maxLength(200)] ],
    createDate: ['' ,[ Validators.maxLength(200)] ],
    loggingData: ['' ,[ Validators.maxLength(2000)] ],
    description: ['' ,[ Validators.maxLength(2000)] ],
    status: [null ]
  });
  loggingId=null;
  loggingAction="create";

	loggingActionOptionList=[
		{ value: "OTP_INITIALIZATION_REQUEST",label:"Tạo đề nghị tạo mã kích hoạt Smart OTP" },
		{ value: "OTP_TOKEN_UNLOCK_REQUEST",label:"Tạo đề nghị mở khóa token" },
		{ value: "OTP_TOKEN_LOCK_REQUEST",label:"Tạo đề nghị khóa token" },
		{ value: "OTP_TOKEN_DISABLE_REQUEST",label:"Tạo đề nghị ngừng sử dụng token" },
    { value: "OTP_REQUEST_TELLER_APPROVE",label:"Giao dịch viên gửi đề nghị"},
    { value: "OTP_REQUEST_SUPERVISOR_APPROVE",label:"Kiểm soát viên phê duyệt đề nghị"},
    { value: "OTP_REQUEST_SUPERVISOR_REJECT",label:"Kiểm soát viên từ chối đề nghị"},
		{ value: "OTP_INITIALIZATION", label: "Tạo mã kích hoạt Smart OTP" },
		{ value: "OTP_ENROLL", label: "Kích hoạt Smart OTP" },
		{ value: "OTP_VERIFY", label: "Xác thực OTP" },
		{ value: "OTP_TOKEN_UNLOCK", label: "Mở khóa Token" },
		{ value: "OTP_TOKEN_LOCK", label: "Khóa Token" },
		{ value: "OTP_TOKEN_CHANGE_PIN", label: "Đổi PIN Token" },
		{ value: "OTP_TOKEN_RESTORE_PIN", label: "Khởi tạo lại PIN Token" },
		{ value: "OTP_TOKEN_DISABLE", label: "Ngừng sử dụng Token" }
	]
	loggingStatusOptionList=[
		{ value : 0, label : "Xác thực OTP lỗi" },
		{ value : 1, label : "Xác thực OTP thành công" }
	]

  constructor(private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.detailForm.disable()
    this.route.params.subscribe(params=>{
      this.error=null;
      if(params.loggingId){
        this.loggingAction="view";
        if(params.loggingAction){
          this.loggingAction=params.loggingAction;
        }
          this.loggingId=params.loggingId;
          this.loading=true;
          this.httpService.get<any>(`/api/admin/otp/logging/${this.loggingId}`).subscribe(response=>{
            this.loading=false;
            if(response.result){
              this.detailForm.patchValue(response.result);
            }else if(response.errorCode){
              this.error=response.errorCode+":"+response.errorMessage;
            }
        },errorResponse=>{
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if(errorResponse.status>0){
            if(errorResponse.error?.errorCode){
              this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error="Lỗi kết nối! Vui lòng thử lại sau";
        })
      }
    })
  }

  cancel(){
      this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
