<div class="card">
    <div class="card-body">
        <h5 class="card-title">Tìm kiếm thiết bị</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-text label="Id" placeholder="Nhập id" [formGroup]="searchForm" formControlName="idLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="searchForm" formControlName="status" [optionList]="deviceStatusOptionList"></app-input-select>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input-text label="Tên" placeholder="Nhập tên" [formGroup]="searchForm" formControlName="nameLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-text label="Mẫu thiết bị" placeholder="Nhập mẫu thiết bị" [formGroup]="searchForm" formControlName="devModelIdLike"></app-input-text>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="searchDevice()" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tìm kiếm</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Kết quả tìm kiếm</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Id</th>
                        <th scope="col">Tên thiết bị</th>
                        <th scope="col">Mẫu thiết bị</th>
                        <th scope="col">Trạng thái</th>
                        <th scope="col">
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="devDeviceList && !loading">
                    <tr *ngFor="let devDevice of devDeviceList;index as devDeviceIndex">
                        <td scope="col">{{(devDevicePagination.currentPage-1)*devDevicePagination.pageSize + devDeviceIndex+1}}</td>
                        <td scope="col">{{devDevice.id}}</td>
                        <td scope="col">{{devDevice.name}}</td>
                        <td scope="col">{{devDevice.devModelId}}</td>
                        <td scope="col">{{deviceStatusOptionMap[devDevice.status]}}</td>
                        <td scope="col">
                            <div class="btn-group">
                                <button type="button" class="btn btn-info" (click)="viewDevice(devDevice)">Xem</button>
                                <button type="button" class="btn btn-success" *ngIf="hasEditPerm&&devDevice.status === 2" (click)="unlockDevice(devDevice)">Mở khóa</button>
                                <button type="button" class="btn btn-warning" *ngIf="hasEditPerm&&devDevice.status === 1" (click)="lockDevice(devDevice)">Khóa</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-between align-items-center" *ngIf="devDevicePagination.pageCount>1 && !loading">
            <nav>
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{'disabled':devDevicePagination.currentPage == 1}">
                      <a class="page-link" (click)="devDevicePageChange(devDevicePagination.currentPage-1)">Trước</a>
                    </li>
                    <li class="page-item" *ngIf="devDevicePagination.currentPage > 3">
                      <a class="page-link" (click)="devDevicePageChange(1)">1</a>
                    </li>
                  <li class="page-item disabled" *ngIf="devDevicePagination.currentPage > 3">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngFor="let page of devDevicePagination.pageList"  [ngClass]="{'active':devDevicePagination.currentPage == page}">
                    <a class="page-link" (click)="devDevicePageChange(page)">{{page}}</a>
                  </li>
                  <li class="page-item disabled" *ngIf="devDevicePagination.currentPage < devDevicePagination.pageCount - 2">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngIf="devDevicePagination.currentPage < devDevicePagination.pageCount - 2">
                    <a class="page-link" (click)="devDevicePageChange(devDevicePagination.pageCount)">{{devDevicePagination.pageCount}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled':devDevicePagination.currentPage == devDevicePagination.pageCount}">
                    <a class="page-link"  (click)="devDevicePageChange(devDevicePagination.currentPage+1)">Sau</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>
</div>

