<div class="card">
    <div class="card-body">
        <h5 class="card-title">Số lượng token kích hoạt</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-select label="Định kỳ" placeholder="Chọn định kỳ" [formGroup]="searchForm" formControlName="dataInterval" [optionList]="reportDataIntervalOptionList"  (ngModelChange)="dataIntervalChange($event)"></app-input-select>
                </div>
                <div class="col">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input-date label="Từ ngày" placeholder="Nhập từ ngày"[formGroup]="searchForm" formControlName="dataDateGte" *ngIf="showByTime"></app-input-date>
                </div>
                <div class="col">
                    <app-input-date label="Đến ngày" placeholder="Nhập đến ngày"[formGroup]="searchForm" formControlName="dataDateLte" *ngIf="showByTime"></app-input-date>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="showStatisticChart()" [disabled]="loading || searchForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Biểu đồ</button>
            <button type="button" class="btn btn-primary" (click)="downloadStatisticExcel()" [disabled]="loading || searchForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Excel</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Biểu đồ</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div *ngIf="showChart">
            <div style="display: block;" *ngIf="chartType==='pie'">
                <canvas baseChart 
                  [data]="pieChartData" 
                  [labels]="pieChartLabels" 
                  [chartType]="pieChartType"
                  [options]="pieChartOptions"
                  [plugins]="pieChartPlugins"
                  [colors]="pieChartColors"
                  [legend]="pieChartLegend">
                </canvas>
              </div>
              <div style="display: block;" *ngIf="chartType==='bar'">
                <canvas baseChart 
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  [chartType]="barChartType">
                </canvas>
              </div>
        </div>
    </div>
</div>