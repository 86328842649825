<div class="card">
    <div class="card-body">
        <h5 class="card-title">Tìm kiếm nhà sản xuất</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-text label="Tên" placeholder="Nhập tên" [formGroup]="searchForm" formControlName="nameLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="searchForm" formControlName="status" [optionList]="manufacturerStatusOptionList"></app-input-select>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="searchManufacturer()" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tìm kiếm</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Kết quả tìm kiếm</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tên</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary" *ngIf="hasEditPerm" (click)="insertManufacturer()">Thêm</button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="devManufacturerList && !loading">
                <tr *ngFor="let devManufacturer of devManufacturerList;index as devManufacturerIndex">
                    <td scope="col">{{(devManufacturerPagination.currentPage-1)*devManufacturerPagination.pageSize + devManufacturerIndex+1}}</td>
                    <td scope="col">{{devManufacturer.name}}</td>
                    <td scope="col">{{manufacturerStatusOptionMap[devManufacturer.status]}}</td>
                    <td scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-info" (click)="viewManufacturer(devManufacturer)">Xem</button>
                            <button type="button" class="btn btn-primary" *ngIf="hasEditPerm" (click)="editManufacturer(devManufacturer)">Sửa</button>
                            <button type="button" class="btn btn-danger" *ngIf="hasEditPerm"  (click)="deleteManufacturer(devManufacturer)">Xóa</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="d-flex justify-content-between align-items-center" *ngIf="devManufacturerPagination.pageCount>1 && !loading">
            <nav>
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{'disabled':devManufacturerPagination.currentPage == 1}">
                      <a class="page-link" (click)="devManufacturerPageChange(devManufacturerPagination.currentPage-1)">Trước</a>
                    </li>
                    <li class="page-item" *ngIf="devManufacturerPagination.currentPage > 3">
                      <a class="page-link" (click)="devManufacturerPageChange(1)">1</a>
                    </li>
                  <li class="page-item disabled" *ngIf="devManufacturerPagination.currentPage > 3">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngFor="let page of devManufacturerPagination.pageList"  [ngClass]="{'active':devManufacturerPagination.currentPage == page}">
                    <a class="page-link" (click)="devManufacturerPageChange(page)">{{page}}</a>
                  </li>
                  <li class="page-item disabled" *ngIf="devManufacturerPagination.currentPage < devManufacturerPagination.pageCount - 2">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngIf="devManufacturerPagination.currentPage < devManufacturerPagination.pageCount - 2">
                    <a class="page-link" (click)="devManufacturerPageChange(devManufacturerPagination.pageCount)">{{devManufacturerPagination.pageCount}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled':devManufacturerPagination.currentPage == devManufacturerPagination.pageCount}">
                    <a class="page-link"  (click)="devManufacturerPageChange(devManufacturerPagination.currentPage+1)">Sau</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>
</div>

