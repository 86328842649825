import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';
import {environment} from 'src/environments/environment'
@Component({
  selector: 'app-otp-initialization',
  templateUrl: './otp-initialization.component.html',
  styleUrls: ['./otp-initialization.component.css']
})
export class OtpInitializationComponent implements OnInit {
	error=null
	loading = false
  detailForm: FormGroup = this.formBuilder.group({
    accountId: ["", [Validators.required, Validators.maxLength(50)]],
    phoneNumber: ["", [Validators.required, Validators.maxLength(200)]]
  });
  otpRequestId=null;
  // qrImageUrl=null;
  // initializationCode=null;

  statusOptionList=[
		{ value : 0, label : "Ngừng sử dụng" },
		{ value : 1, label : "Đang sử dụng" },
		{ value : 2, label : "Đã bị khóa" }
  ]

  constructor(private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
  }

  submit(){
		if(this.loading){
			return;
		}
    this.error=null;
    var detailData=this.detailForm.getRawValue();
    this.loading=true;
      this.httpService.post<any>(`/api/admin/otp/initialization`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.otpRequestId=response.result
        // this.initializationCode=response.result;
        // this.qrImageUrl=`${environment.baseUrl}/api/qr/${response.result}`;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
  }

  reset(){
    this.error=null;
    // this.qrImageUrl=null;
    // this.initializationCode=null;
    this.otpRequestId=null;
    this.detailForm.patchValue({
      accountId:'',phoneNumber:''
    })
  }
  sendSupervisor(){
		this.router.navigate(['/otp/request/teller-approve', this.otpRequestId])
	}
}
