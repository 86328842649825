<div class="card">
    <div class="card-body">
        <h5 class="card-title">Tìm kiếm người dùng</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-text label="Họ tên" placeholder="Nhập họ tên" [formGroup]="searchForm" formControlName="fullnameLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-text label="Tên đăng nhập" placeholder="Nhập tên đăng nhập" [formGroup]="searchForm" formControlName="usernameLike"></app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input-text label="Thư điện tử" placeholder="Nhập thư điện tử" [formGroup]="searchForm" formControlName="emailLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-text label="Số điện thoại" placeholder="Nhập số điện thoại" [formGroup]="searchForm" formControlName="phoneNumberLike"></app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="searchForm" formControlName="status" [optionList]="userStatusOptionList"></app-input-select>
                </div>
                <div class="col">
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="searchUser()" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tìm kiếm</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Kết quả tìm kiếm</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Họ tên</th>
                    <th scope="col">Tên đăng nhập</th>
                    <th scope="col">Thư điện tử</th>
                    <th scope="col">Số điện thoại</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary" (click)="insertUser()">Thêm</button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="sysUserList && !loading">
                <tr *ngFor="let sysUser of sysUserList;index as sysUserIndex">
                    <td scope="col">{{(sysUserPagination.currentPage-1)*sysUserPagination.pageSize + sysUserIndex+1}}</td>
                    <td scope="col">{{sysUser.fullname}}</td>
                    <td scope="col">{{sysUser.username}}</td>
                    <td scope="col">{{sysUser.email}}</td>
                    <td scope="col">{{sysUser.phoneNumber}}</td>
                    <td scope="col">{{userStatusOptionMap[sysUser.status]}}</td>
                    <td scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-info" (click)="viewUser(sysUser)">Xem</button>
                            <button type="button" class="btn btn-primary" (click)="editUser(sysUser)">Sửa</button>
                            <button type="button" class="btn btn-primary" (click)="passwordUser(sysUser)">Đổi mật khẩu</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="d-flex justify-content-between align-items-center" *ngIf="sysUserPagination.pageCount>1 && !loading">
            <nav>
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{'disabled':sysUserPagination.currentPage == 1}">
                      <a class="page-link" (click)="sysUserPageChange(sysUserPagination.currentPage-1)">Trước</a>
                    </li>
                    <li class="page-item" *ngIf="sysUserPagination.currentPage > 3">
                      <a class="page-link" (click)="sysUserPageChange(1)">1</a>
                    </li>
                  <li class="page-item disabled" *ngIf="sysUserPagination.currentPage > 3">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngFor="let page of sysUserPagination.pageList"  [ngClass]="{'active':sysUserPagination.currentPage == page}">
                    <a class="page-link" (click)="sysUserPageChange(page)">{{page}}</a>
                  </li>
                  <li class="page-item disabled" *ngIf="sysUserPagination.currentPage < sysUserPagination.pageCount - 2">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngIf="sysUserPagination.currentPage < sysUserPagination.pageCount - 2">
                    <a class="page-link" (click)="sysUserPageChange(sysUserPagination.pageCount)">{{sysUserPagination.pageCount}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled':sysUserPagination.currentPage == sysUserPagination.pageCount}">
                    <a class="page-link"  (click)="sysUserPageChange(sysUserPagination.currentPage+1)">Sau</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>
</div>

