<div class="card">
    <div class="card-body">
        <h5 class="card-title">Tìm kiếm token</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-text label="Id" placeholder="Nhập id" [formGroup]="searchForm" formControlName="idLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-text label="Tài khoản" placeholder="Nhập tài khoản" [formGroup]="searchForm" formControlName="accountIdLike"></app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="searchForm" formControlName="status" [optionList]="tokenStatusOptionList"></app-input-select>
                </div>
                <div class="col">
                    <app-input-text label="Số điện thoại" placeholder="Nhập số điện thoại" [formGroup]="searchForm" formControlName="phoneNumberLike"></app-input-text>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-secondary" (click)="cancel()" *ngIf="devDeviceId">Trở về</button>
            <button type="button" class="btn btn-primary" (click)="searchToken()"[disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tìm kiếm</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Kết quả tìm kiếm</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Id</th>
                    <th scope="col">Tài khoản</th>
                    <th scope="col">Số điện thoại</th>
                    <th scope="col">Ngày tạo</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="otpTokenList && !loading">
                <tr *ngFor="let otpToken of otpTokenList;index as otpTokenIndex">
                    <td scope="col">{{(otpTokenPagination.currentPage-1)*otpTokenPagination.pageSize + otpTokenIndex+1}}</td>
                    <td scope="col">{{otpToken.id}}</td>
                    <td scope="col">{{otpToken.accountId}}</td>
                    <td scope="col">{{otpToken.phoneNumber}}</td>
                    <td scope="col">{{otpToken.createDate | date:'dd/MM/yyyy, HH:mm:ss'}}</td>
                    <td scope="col">{{tokenStatusOptionMap[otpToken.status]}}</td>
                    <td scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-info" (click)="viewToken(otpToken)">Xem</button>
                            <button type="button" class="btn btn-success" *ngIf="hasEditPerm&& otpToken.status === 2" (click)="unlockToken(otpToken)">Mở khóa</button>
                            <button type="button" class="btn btn-danger" *ngIf="hasEditPerm&&(otpToken.status === 1 || otpToken.status === 2)" (click)="disableToken(otpToken)">Ngừng sử dụng</button>
                            <button type="button" class="btn btn-warning" *ngIf="hasEditPerm&&otpToken.status === 1" (click)="lockToken(otpToken)">Khóa</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="d-flex justify-content-between align-items-center" *ngIf="otpTokenPagination.pageCount > 1 && !loading">
            <nav>
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{'disabled':otpTokenPagination.currentPage == 1}">
                      <a class="page-link" (click)="otpTokenPageChange(otpTokenPagination.currentPage-1)">Trước</a>
                    </li>
                    <li class="page-item" *ngIf="otpTokenPagination.currentPage > 3">
                      <a class="page-link" (click)="otpTokenPageChange(1)">1</a>
                    </li>
                  <li class="page-item disabled" *ngIf="otpTokenPagination.currentPage > 3">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngFor="let page of otpTokenPagination.pageList"  [ngClass]="{'active':otpTokenPagination.currentPage == page}">
                    <a class="page-link" (click)="otpTokenPageChange(page)">{{page}}</a>
                  </li>
                  <li class="page-item disabled" *ngIf="otpTokenPagination.currentPage < otpTokenPagination.pageCount - 2">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngIf="otpTokenPagination.currentPage < otpTokenPagination.pageCount - 2">
                    <a class="page-link" (click)="otpTokenPageChange(otpTokenPagination.pageCount)">{{otpTokenPagination.pageCount}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled':otpTokenPagination.currentPage == otpTokenPagination.pageCount}">
                    <a class="page-link"  (click)="otpTokenPageChange(otpTokenPagination.currentPage+1)">Sau</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>
</div>

