import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { SearchService } from 'src/app/service/search.service';

@Component({
  selector: 'app-system-menu',
  templateUrl: './system-menu.component.html',
  styleUrls: ['./system-menu.component.css']
})
export class SystemMenuComponent implements OnInit {
  userMenuList = null;
  menuAllList = [
    {
      "navigation": ['/system/rsa-key'],
      "title": "Key",
      "permCodeList": [ "SYS_RSA_KEY", "SYS_RSA_KEY_VIEW"]
    },
    {
      "navigation": ['/system/perm'],
      "title": "Quyền",
      "permCodeList": ["SYS_PERM"]
    },
    {
      "navigation": ['/system/role'],
      "title": "Role",
      "permCodeList": ["SYS_ROLE"]
    },
    {
      "navigation": ['/system/application'],
      "title": "Ứng dụng",
      "permCodeList": ["SYS_APPLICATION","SYS_APPLICATION_VIEW"]
    },
    {
      "navigation": ['/system/branch'],
      "title": "Chi nhánh",
      "permCodeList": ["SYS_BRANCH","SYS_BRANCH_VIEW"]
    },
    {
      "navigation": ['/system/user'],
      "title": "Người dùng",
      "permCodeList": ["SYS_USER"]
    },
    {
      "navigation": ['/system/logging'],
      "title": "Nhật ký",
      "permCodeList": [ "SYS_LOGGING"]
    }
  ]
  constructor(private authService: AuthService,
		private searchService:SearchService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.authService.getUserPerm().subscribe(userdetailResponse => {
      if (userdetailResponse.resultList) {
        var sysPermCodeList = userdetailResponse.resultList;
        if (sysPermCodeList != null) {
          var userMenuList = [];
          for (var menu of this.menuAllList) {
            for (var permCode of menu.permCodeList) {
              if (sysPermCodeList.includes(permCode)) {
                userMenuList.push(menu);
                break;
              }
            }
          }
          this.userMenuList = userMenuList;
        }
      }
    },errorResponse=>{
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
		})
  }

  navigation(routerLink){
    this.searchService.clear();
    this.router.navigate(routerLink, { relativeTo: this.route });
  }

}
