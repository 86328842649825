<div class="card" *ngIf="!submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông tin key</h5>
        <div class="alert alert-danger" role="alert" *ngIf="rsaKeyAction==='delete'">
          Bạn có chắc chắn muốn xóa key
        </div>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <app-input-text label="Id" placeholder="Nhập id" [formGroup]="detailForm" formControlName="id" *ngIf="rsaKeyAction!=='create'" ></app-input-text>
            <app-input-select label="Loại key" placeholder="Chọn loại key" [formGroup]="detailForm" formControlName="type" [optionList]="typeOptionList"  (ngModelChange)="typeChange($event)"></app-input-select>
            <app-input-select label="Key chính" placeholder="Chọn key chính" [formGroup]="detailForm" formControlName="signatureKeyId" [optionList]="sysRsaKeyOptionList" *ngIf="showSelectMasterKey&&sysRsaKeyOptionList"></app-input-select>
            <app-input-text label="Tên" placeholder="Nhập tên" [formGroup]="detailForm" formControlName="name"></app-input-text>
            <app-input-textarea label="Public key" placeholder="Nhập public key" [formGroup]="detailForm" formControlName="publicKey" rows="8" *ngIf="rsaKeyAction!=='create'"></app-input-textarea>
            <app-input-date label="Từ ngày" placeholder="Nhập từ ngày"[formGroup]="detailForm" formControlName="notBefore"></app-input-date>
            <app-input-date label="Đến ngày" placeholder="Nhập đến ngày"[formGroup]="detailForm" formControlName="notAfter"></app-input-date>
            <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="detailForm" formControlName="status" [optionList]="statusOptionList"></app-input-select>
            <app-input-textarea label="Mô tả" placeholder="Nhập mô tả" [formGroup]="detailForm" formControlName="description" rows="5"></app-input-textarea>
            <app-input-textarea label="Ghi chú" placeholder="Nhập ghi chú" [formGroup]="detailForm" formControlName="note" rows="5"></app-input-textarea>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
                <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="hasEditPerm&& rsaKeyAction==='create'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Thêm mới</button>
                <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="hasEditPerm&& rsaKeyAction==='edit'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Cập nhật</button>
                <button type="button" class="btn btn-danger" (click)="submit()" *ngIf="hasEditPerm&& rsaKeyAction==='delete'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Xóa</button>
            </div>
            </div>
        </form>
    </div>
</div>
<div class="card" *ngIf="submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông báo</h5>
        <div class="alert alert-success" role="alert" *ngIf="rsaKeyAction==='create'">
          Thêm mới thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="rsaKeyAction==='edit'">
          Cập nhật thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="rsaKeyAction==='delete'">
          Xóa thành công
        </div>
        <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
            </div>
        </div>
    </div>
</div>