import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { SearchService } from 'src/app/service/search.service';

@Component({
  selector: 'app-device-menu',
  templateUrl: './device-menu.component.html',
  styleUrls: ['./device-menu.component.css']
})
export class DeviceMenuComponent implements OnInit {
  userMenuList = null;
  menuAllList = [
    {
      "navigation": ['/dev/device'],
      "title": "Thiết bị",
      "permCodeList": ["DEV_DEVICE", "DEV_DEVICE_VIEW"]
    },
    {
      "navigation": ['/dev/manufacturer'],
      "title": "Nhà sản xuất",
      "permCodeList": ["DEV_MANUFACTURER", "DEV_MANUFACTURER_VIEW"]
    },
    {
      "navigation": ['/dev/brand'],
      "title": "Thương hiệu",
      "permCodeList": ["DEV_BRAND", "DEV_BRAND_VIEW"]
    },
    {
      "navigation": ['/dev/model'],
      "title": "Mẫu thiết bị",
      "permCodeList": ["DEV_MODEL", "DEV_MODEL_VIEW"]
    },
    {
      "navigation": ['/dev/system'],
      "title": "Hệ điều hành",
      "permCodeList": ["DEV_SYSTEM", "DEV_SYSTEM_VIEW"]
    },
    {
      "navigation": ['/dev/logging'],
      "title": "Nhật ký",
      "permCodeList": ["DEV_LOGGING"]
    }
  ]
  constructor(private authService: AuthService,
		private searchService:SearchService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.authService.getUserPerm().subscribe(userdetailResponse => {
      if (userdetailResponse.resultList) {
        var sysPermCodeList = userdetailResponse.resultList;
        if (sysPermCodeList != null) {
          var userMenuList = [];
          for (var menu of this.menuAllList) {
            for (var permCode of menu.permCodeList) {
              if (sysPermCodeList.includes(permCode)) {
                userMenuList.push(menu);
                break;
              }
            }
          }
          this.userMenuList = userMenuList;
        }
      }
    },errorResponse=>{
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
		})
  }

  navigation(routerLink){
    this.searchService.clear();
    this.router.navigate(routerLink, { relativeTo: this.route });
  }

}
