<div class="card">
    <div class="card-body">
        <h5 class="card-title">Tìm kiếm hệ điều hành</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-text label="Tên" placeholder="Nhập tên" [formGroup]="searchForm" formControlName="nameLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="searchForm" formControlName="status" [optionList]="systemStatusOptionList"></app-input-select>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="searchSystem()" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tìm kiếm</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Kết quả tìm kiếm</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tên</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-primary" *ngIf="hasEditPerm" (click)="insertSystem()">Thêm</button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="devSystemList && !loading">
                <tr *ngFor="let devSystem of devSystemList;index as devSystemIndex">
                    <td scope="col">{{(devSystemPagination.currentPage-1)*devSystemPagination.pageSize + devSystemIndex+1}}</td>
                    <td scope="col">{{devSystem.name}}</td>
                    <td scope="col">{{systemStatusOptionMap[devSystem.status]}}</td>
                    <td scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-info" (click)="versionSystem(devSystem)">Phiên bản</button>
                            <button type="button" class="btn btn-info" (click)="viewSystem(devSystem)">Xem</button>
                            <button type="button" class="btn btn-primary" *ngIf="hasEditPerm" (click)="editSystem(devSystem)">Sửa</button>
                            <button type="button" class="btn btn-danger" *ngIf="hasEditPerm"  (click)="deleteSystem(devSystem)">Xóa</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="d-flex justify-content-between align-items-center" *ngIf="devSystemPagination.pageCount>1 && !loading">
            <nav>
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{'disabled':devSystemPagination.currentPage == 1}">
                      <a class="page-link" (click)="devSystemPageChange(devSystemPagination.currentPage-1)">Trước</a>
                    </li>
                    <li class="page-item" *ngIf="devSystemPagination.currentPage > 3">
                      <a class="page-link" (click)="devSystemPageChange(1)">1</a>
                    </li>
                  <li class="page-item disabled" *ngIf="devSystemPagination.currentPage > 3">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngFor="let page of devSystemPagination.pageList"  [ngClass]="{'active':devSystemPagination.currentPage == page}">
                    <a class="page-link" (click)="devSystemPageChange(page)">{{page}}</a>
                  </li>
                  <li class="page-item disabled" *ngIf="devSystemPagination.currentPage < devSystemPagination.pageCount - 2">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngIf="devSystemPagination.currentPage < devSystemPagination.pageCount - 2">
                    <a class="page-link" (click)="devSystemPageChange(devSystemPagination.pageCount)">{{devSystemPagination.pageCount}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled':devSystemPagination.currentPage == devSystemPagination.pageCount}">
                    <a class="page-link"  (click)="devSystemPageChange(devSystemPagination.currentPage+1)">Sau</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>
</div>

