import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

export const EDITOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputDateComponent),
  multi: true
};
@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.css'],
  providers: [EDITOR_VALUE_ACCESSOR]
})
export class InputDateComponent implements OnInit,OnChanges {

	dpDayPickerDateConfig = {
		format:'DD/MM/YYYY',
		theme:"dp-material",
		showTwentyFourHours:true,
		locale:'vi'
	}
	dpDayPickerDateTimeConfig = {
		format:'DD/MM/YYYY HH:mm',
		theme:"dp-material",
		showTwentyFourHours:true,
		locale:'vi'
	}
  @Input("formGroup")formGroup:FormGroup;
  @Input("formControlName")formControlName:string;
  @Input("label")label:string;
  @Input("placeholder")placeholder:string;
  @Input("mode")mode:string='daytime';
  @Input("disabled")disabled:boolean=false;
  value=null;
  formControl = null;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    var formGroup=null;
    if(changes.formGroup){
      formGroup=changes.formGroup.currentValue;
    }
    var formControlName=null;
    if(changes.formControlName){
      formControlName=changes.formControlName.currentValue;
    }
    if(formGroup&&formControlName){
      this.formControl=formGroup.get(formControlName);
    }
  }
  valueChange(value){
    this.value=value
    this.onModelChange(moment(value, "DD/MM/YYYY HH:mm").toISOString(true));
  }

  writeValue(value: any): void {
    if (this.value !== value) {
        var momentValue=moment(value)
        this.value = momentValue.format('DD/MM/YYYY HH:mm');
    }
  }
  onModelChange: Function = () => {
  };

  onModelTouched: Function = () => {
  };

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
  this.disabled = isDisabled; 
  }
}
