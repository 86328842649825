<div class="row">
    <div class="col-md-6 col-lg-6" *ngFor="let chart of chartList">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{chart.title}}</h5>
          <app-stat-statistic-chart [statisticType]="chart.statisticType" [dataInterval]="chart.dataInterval" [chartType]="chart.chartType"></app-stat-statistic-chart>
        </div>
      </div>
    </div>
</div>
  