import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';
@Component({
  selector: 'app-sys-perm-detail',
  templateUrl: './sys-perm-detail.component.html',
  styleUrls: ['./sys-perm-detail.component.css']
})
export class SysPermDetailComponent implements OnInit {
	error=null
	loading = false
	submitSuccess = false
  detailForm: FormGroup = this.formBuilder.group({
    type: ["USER", [Validators.required]],
    code: ["", [Validators.required, Validators.maxLength(50)]],
    name: ["", [Validators.required, Validators.maxLength(200)]],
    description: ["", [Validators.maxLength(2000)]],
    note: ["", [Validators.maxLength(2000)]],
    status: [0, [Validators.required]]
  });
  permId=null;
  permAction="create";

  typeOptionList=[
    {value:"USER",label:"Người dùng"},
    {value:"APPLICATION",label:"Ứng dụng"}
  ]

  statusOptionList=[
    {value:0,label:"Ngừng sử dụng"},
    {value:1,label:"Sử dụng"}
  ]

  constructor(private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      this.error=null;
      if(params.permId){
        this.permAction="view";
        if(params.permAction){
          this.permAction=params.permAction;
        }
        this.createForm();
          this.permId=params.permId;
          this.loading=true;
          this.httpService.get<any>(`/api/admin/sys/perm/${this.permId}`).subscribe(response=>{
            this.loading=false;
            if(response.result){
              this.detailForm.patchValue(response.result);
            }else if(response.errorCode){
              this.error=response.errorCode+":"+response.errorMessage;
            }
        },errorResponse=>{
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if(errorResponse.status>0){
            if(errorResponse.error?.errorCode){
              this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error="Lỗi kết nối! Vui lòng thử lại sau";
        })
      }else{
        this.permAction="create";
        this.createForm();
      }

    })
  }
  createForm(){
    if(this.permAction==="create"){
      this.detailForm.enable()
    }else if(this.permAction==="edit"){
      this.detailForm.enable()
      this.detailForm.get('code').disable();
    }else{
      this.detailForm.disable()
    }
  }

  submit(){
		if(this.loading){
			return;
		}
    this.error=null;
    if(this.permAction==="create"){
      var detailData=this.detailForm.getRawValue();
      this.loading=true;
      this.httpService.put<any>(`/api/admin/sys/perm`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.permAction==="edit"){
      var detailData=this.detailForm.getRawValue();
      this.loading=true;
      this.httpService.post<any>(`/api/admin/sys/perm/${this.permId}`,detailData).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.permAction==="delete"){
      this.loading=true;
      this.httpService.delete<any>(`/api/admin/sys/perm/${this.permId}`).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
  }
  cancel(){
    if(this.permAction==="create"){
      this.router.navigate(['../'], { relativeTo: this.route });
    }else{
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }
}
