import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-dev-logging-list',
  templateUrl: './dev-logging-list.component.html',
  styleUrls: ['./dev-logging-list.component.css']
})
export class DevLoggingListComponent implements OnInit {
	error=null
	loading = false
	devLoggingList=null
	devLoggingPagination={
		currentPage:1,
		pageSize:10,
		pageCount:0,
		rowCount:0,
		pageList:null
	}
	searchForm: FormGroup=this.formBuilder.group({
		action: [null ],
		createApplicationCodeLike: ['' ,[ Validators.maxLength(200)] ],
		createUsernameLike: ['' ,[ Validators.maxLength(200)] ],
		loggingDataLike: ['', [Validators.maxLength(200)]],
		createDateGt: [null],
		createDateLt: [null]
	});
	loggingActionOptionList=[
		{ value : null, label : "Tất cả" },
		{ value : "DEV_DEVICE_INSERT", label : "Thêm thiết bị" },
		{ value : "DEV_DEVICE_UPDATE", label : "Cập nhật thiết bị" },
		{ value : "DEV_DEVICE_UNLOCK", label : "Mở khóa thiết bị" },
		{ value : "DEV_DEVICE_LOCK", label : "Khóa thiết bị" },
		{ value : "DEV_DEVICE_DISABLE", label : "Ngừng sử dụng thiết bị" },
		{ value : "DEV_MODEL_INSERT",label : "Thêm mẫu thiết bị" },
		{ value : "DEV_MODEL_UPDATE",label : "Cập nhật mẫu thiết bị" },
		{ value : "DEV_MODEL_DELETE",label : "Xóa mẫu thiết bị" },
		{ value : "DEV_BRAND_INSERT",label : "Thêm thương hiệu" },
		{ value : "DEV_BRAND_UPDATE",label : "Cập nhật thương hiệu" },
		{ value : "DEV_BRAND_DELETE",label : "Xóa thương hiệu" },
		{ value : "DEV_MANUFACTURER_INSERT",label : "Thêm nhà sản xuất" },
		{ value : "DEV_MANUFACTURER_UPDATE",label : "Cập nhật nhà sản xuất" },
		{ value : "DEV_MANUFACTURER_DELETE",label : "Xóa nhà sản xuất" },
		{ value : "DEV_SYSTEM_INSERT",label :"Thêm hệ điều hành" },
		{ value : "DEV_SYSTEM_UPDATE",label :"Cập nhật hệ điều hành" },
		{ value : "DEV_SYSTEM_DELETE",label :"Xóa hệ điều hành" },
		{ value : "DEV_SYSTEM_VERSION_INSERT",label :"Thêm phiên bản hệ điều hành" },
		{ value : "DEV_SYSTEM_VERSION_UPDATE",label :"Cập nhật phiên bản hệ điều hành" },
		{ value : "DEV_SYSTEM_VERSION_DELETE",label :"Xóa phiên bản hệ điều hành" }
	]
	loggingActionOptionMap={}
	constructor(private formBuilder: FormBuilder,
	private httpService:BaseHttpService,
	private route: ActivatedRoute,
	private router: Router ) { }
	ngOnInit(): void {
		for(var loggingActionOption of this.loggingActionOptionList){
			this.loggingActionOptionMap[loggingActionOption.value]=loggingActionOption.label;
		}
		this.searchLogging();
	}

	devLoggingPageChange(page){
		if(this.loading){
			return;
		}
		this.error=null;
		var searchFormData=this.searchForm.getRawValue()
		var findRequest:any = {}
		var action = searchFormData["action"];
		if(action !== null){
			findRequest.action= action;
		}
		var createApplicationCodeLike = searchFormData["createApplicationCodeLike"];
		if(createApplicationCodeLike){
			findRequest.createApplicationCodeLike= `%${createApplicationCodeLike}%`;
		}
		var createUsernameLike = searchFormData["createUsernameLike"];
		if(createUsernameLike){
			findRequest.createUsernameLike= `%${createUsernameLike}%`;
		}
		var loggingDataLike = searchFormData["loggingDataLike"];
		if (loggingDataLike) {
			findRequest.loggingDataLike = `%${loggingDataLike}%`;
		}
		var createDateGt = searchFormData["createDateGt"];
		if (createDateGt) {
			findRequest.createDateGt = createDateGt;
		}
		var createDateLt = searchFormData["createDateLt"];
		if (createDateLt) {
			findRequest.createDateLt = createDateLt;
		}
		if(page<1){
			page=1
		}
		findRequest.queryOffset=(page-1)*this.devLoggingPagination.pageSize;
		findRequest.queryLimit=this.devLoggingPagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/dev/logging/search`,findRequest).subscribe(findDevLoggingResponse=>{
            this.loading=false;
			var rowCount = findDevLoggingResponse.rowCount;
			var pageCount = Math.ceil(rowCount/this.devLoggingPagination.pageSize);
			var pageList=[]
			for(var i=page-2;i<=page+2;i++){
				if(i>=1&&i<=pageCount){
					pageList.push(i);
				}
			}
			this.devLoggingPagination.currentPage=page;
			this.devLoggingPagination.rowCount=rowCount;
			this.devLoggingPagination.pageCount=pageCount;
			this.devLoggingPagination.pageList=pageList;
			this.devLoggingList= findDevLoggingResponse.resultList;
		},errorResponse=>{
            this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchLogging(){
		this.devLoggingPageChange(1);
	}
	viewLogging(devLogging){
		this.router.navigate(['view',devLogging.id],{ relativeTo: this.route })
	}
}


