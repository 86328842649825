import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-stat-menu',
  templateUrl: './stat-menu.component.html',
  styleUrls: ['./stat-menu.component.css']
})
export class StatMenuComponent implements OnInit {
  userMenuList = null;
  /*
  
          { path: "stat-token-status", component: StatTokenStatusComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-token-enroll", component: StatTokenEnrollComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-token-verify-error", component: StatTokenVerifyErrorComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-device-system", component: StatDeviceSystemComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-device-brand", component: StatDeviceBrandComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          { path: "stat-device-manufacturer", component: StatDeviceManufacturerComponent, canActivate: [RoleGuard], data: { expectedRoleList: ["STAT_STATISTIC"] } },
          
		{ value: "otp_token_status",label:"Trạng thái token" },
		{ value: "otp_token_enroll",label:"Số lượng token kích hoạt" },
		{ value: "otp_token_verify_error",label:"Số lượng xác thực lỗi" },
		{ value: "dev_device_system",label:"Số lượng thiết bị theo hệ điều hành" },
		{ value: "dev_device_brand",label:"Số lượng thiết bị theo hãng thiết bị"},
		{ value: "dev_device_manufacturer",label:"Số lượng thiết bị theo nhà sản xuất"}
          */
  menuAllList = [
    {
      "navigation": ['/stat/stat-token-status'],
      "title": "Trạng thái token",
      "permCodeList": [ "STAT_STATISTIC"]
    },
    {
      "navigation": ['/stat/stat-token-enroll'],
      "title": "Token kích hoạt",
      "permCodeList": [ "STAT_STATISTIC"]
    },
    {
      "navigation": ['/stat/stat-token-verify-error'],
      "title": "Xác thực lỗi",
      "permCodeList": [ "STAT_STATISTIC"]
    },
    {
      "navigation": ['/stat/stat-device-system'],
      "title": "Hệ điều hành",
      "permCodeList": [ "STAT_STATISTIC"]
    },
    {
      "navigation": ['/stat/stat-device-brand'],
      "title": "Hãng thiết bị",
      "permCodeList": [ "STAT_STATISTIC"]
    },
    {
      "navigation": ['/stat/stat-device-manufacturer'],
      "title": "Nhà sản xuất",
      "permCodeList": [ "STAT_STATISTIC"]
    },
  ]
  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.authService.getUserPerm().subscribe(userdetailResponse => {
      if (userdetailResponse.resultList) {
        var sysPermCodeList = userdetailResponse.resultList;
        if (sysPermCodeList != null) {
          var userMenuList = [];
          for (var menu of this.menuAllList) {
            for (var permCode of menu.permCodeList) {
              if (sysPermCodeList.includes(permCode)) {
                userMenuList.push(menu);
                break;
              }
            }
          }
          this.userMenuList = userMenuList;
        }
      }
    },errorResponse=>{
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
		})
  }

}
