<div class="card">
    <div class="card-body">
        <h5 class="card-title">Tìm kiếm đề nghị</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-select label="Loại đề nghị" placeholder="Chọn loại đề nghị" [formGroup]="searchForm" formControlName="action" [optionList]="requestActionOptionList"></app-input-select>
                </div>
                <div class="col">
                    <app-input-text label="Tài khoản" placeholder="Nhập tài khoản" [formGroup]="searchForm" formControlName="accountIdLike"></app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="searchForm" formControlName="status" [optionList]="requestStatusOptionList"></app-input-select>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="searchRequest()"[disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tìm kiếm</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Kết quả tìm kiếm</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Loại đề nghị</th>
                    <th scope="col">Tài khoản</th>
                    <th scope="col">Ngày tạo</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="otpRequestList && !loading">
                <tr *ngFor="let otpRequest of otpRequestList;index as otpRequestIndex">
                    <td scope="col">{{(otpRequestPagination.currentPage-1)*otpRequestPagination.pageSize + otpRequestIndex+1}}</td>
                    <td scope="col">{{requestActionOptionMap[otpRequest.action]}}</td>
                    <td scope="col">{{otpRequest.accountId}}</td>
                    <td scope="col">{{otpRequest.createDate | date:'dd/MM/yyyy, HH:mm:ss'}}</td>
                    <td scope="col">{{requestStatusOptionMap[otpRequest.status]}}</td>
                    <td scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-info" (click)="viewRequest(otpRequest)">Xem</button>
                            <button type="button" class="btn btn-success" *ngIf="otpRequest.status === 0" (click)="sendSupervisorRequest(otpRequest)">Gửi kiểm soát viên</button>
                            <button type="button" class="btn btn-success" *ngIf="hasSupervisorPerm&& otpRequest.status === 1" (click)="supervisorApproveRequest(otpRequest)">Phê duyệt</button>
                            <button type="button" class="btn btn-warning" *ngIf="hasSupervisorPerm&&otpRequest.status === 1" (click)="supervisorRejectRequest(otpRequest)">Từ chối</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="d-flex justify-content-between align-items-center" *ngIf="otpRequestPagination.pageCount > 1 && !loading">
            <nav>
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{'disabled':otpRequestPagination.currentPage == 1}">
                      <a class="page-link" (click)="otpRequestPageChange(otpRequestPagination.currentPage-1)">Trước</a>
                    </li>
                    <li class="page-item" *ngIf="otpRequestPagination.currentPage > 3">
                      <a class="page-link" (click)="otpRequestPageChange(1)">1</a>
                    </li>
                  <li class="page-item disabled" *ngIf="otpRequestPagination.currentPage > 3">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngFor="let page of otpRequestPagination.pageList"  [ngClass]="{'active':otpRequestPagination.currentPage == page}">
                    <a class="page-link" (click)="otpRequestPageChange(page)">{{page}}</a>
                  </li>
                  <li class="page-item disabled" *ngIf="otpRequestPagination.currentPage < otpRequestPagination.pageCount - 2">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngIf="otpRequestPagination.currentPage < otpRequestPagination.pageCount - 2">
                    <a class="page-link" (click)="otpRequestPageChange(otpRequestPagination.pageCount)">{{otpRequestPagination.pageCount}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled':otpRequestPagination.currentPage == otpRequestPagination.pageCount}">
                    <a class="page-link"  (click)="otpRequestPageChange(otpRequestPagination.currentPage+1)">Sau</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>
</div>

