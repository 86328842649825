<div class="form-group">
    <label *ngIf="label">{{label}}</label>
    <input type="text" class="form-control" [attr.name]="formControlName" [placeholder]="placeholder"  [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="valueChange($event)">
    
    <ng-container *ngIf="formControl&& formControl.invalid">
        <small *ngIf="formControl.errors.required" class="form-text text-danger">
            Trường thông tin bắt buộc.
        </small>
        <small *ngIf="formControl.errors.minlength" class="form-text text-danger">
            Trường thông tin dài tối thiểu {{formControl.errors.minlength.requiredLength}} ký tự(Thực tế dài {{formControl.errors.minlength.actualLength}} ký tự).
        </small>
        <small *ngIf="formControl.errors.maxlength" class="form-text text-danger">
            Trường thông tin dài tối đa {{formControl.errors.maxlength.requiredLength}} ký tự(Thực tế dài {{formControl.errors.maxlength.actualLength}} ký tự).
        </small>
    </ng-container>
</div>