import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip,Color } from 'ng2-charts';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';
@Component({
  selector: 'app-stat-statistic-chart',
  templateUrl: './stat-statistic-chart.component.html',
  styleUrls: ['./stat-statistic-chart.component.css']
})
export class StatStatisticChartComponent implements OnInit {
  error = null
  loading = false

  // Pie
  pieChartOptions: ChartOptions = {
    responsive: true,
  };
  pieChartLabels = [];
  pieChartData = [];
  pieChartType = 'pie';
  pieChartLegend = true;
  pieChartPlugins = [];
  pieChartColors = [
    {
      backgroundColor: [
        'rgba(255,0,0,.5)', 
        'rgba(0,255,0,.5)', 
        'rgba(0,0,255,.5)',
        'rgba(255,255,0,.5)', 
        'rgba(0,255,255,.5)', 
        'rgba(255,0,255,.5)',
        'rgba(192,0,0,.5)', 
        'rgba(0,192,0,.5)', 
        'rgba(0,0,192,.5)',
        'rgba(192,192,0,.5)', 
        'rgba(0,192,192,.5)', 
        'rgba(192,0,192,.5)',
        'rgba(128,0,0,.5)', 
        'rgba(0,128,0,.5)', 
        'rgba(0,0,128,.5)',
        'rgba(128,128,0,.5)', 
        'rgba(0,128,128,.5)', 
        'rgba(128,0,128,.5)',
        'rgba(64,0,0,.5)', 
        'rgba(0,64,0,.5)', 
        'rgba(0,0,64,.5)',
        'rgba(64,64,0,.5)', 
        'rgba(0,64,64,.5)', 
        'rgba(64,0,64,.5)'
      ],
    },
  ];
  //bar
  barChartOptions: ChartOptions = {
    responsive: true,
    scales:{
      yAxes:[
        {
          display: true,
          ticks: {
            suggestedMin: 0,
            beginAtZero: true
          }
        }
      ]
    }
  };
  barChartLabels= [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData= [];

  hasData=false;

  @Input("chartType") chartType: string = "pie"
  @Input("statisticType") statisticType: string = null
  @Input("dataInterval") dataInterval: string = null

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder, private httpService: BaseHttpService,
    private route: ActivatedRoute,
    private router: Router) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    var chartType = 'pie';
    var statisticType = null;
    var dataInterval = null;
    if (changes.chartType && changes.chartType.currentValue) {
      chartType = changes.chartType.currentValue;
    }
    if (changes.statisticType && changes.statisticType.currentValue) {
      statisticType = changes.statisticType.currentValue;
    }
    if (changes.dataInterval && changes.dataInterval.currentValue) {
      dataInterval = changes.dataInterval.currentValue;
    }
    var updateChart = false;
    if (changes.chartType && (changes.chartType.isFirstChange() ||
      (!changes.chartType.previousValue && changes.chartType.currentValue) ||
      (changes.chartType.previousValue !== changes.chartType.currentValue))) {
      updateChart = true;
    }
    if (changes.statisticType && (changes.statisticType.isFirstChange() ||
      (!changes.statisticType.previousValue && changes.statisticType.currentValue) ||
      (changes.statisticType.previousValue !== changes.statisticType.currentValue))) {
      updateChart = true;
    }
    if (changes.dataInterval && (changes.dataInterval.isFirstChange() ||
      (!changes.dataInterval.previousValue && changes.dataInterval.currentValue) ||
      (changes.dataInterval.previousValue !== changes.dataInterval.currentValue))) {
      updateChart = true;
    }
    if(updateChart==false){
      return;
    }
    this.hasData=false;
    if (statisticType) {
      var findRequest:any = {
        type: statisticType,
        dataInterval: dataInterval
      }
      if(chartType==='bar'){
        if(dataInterval==='quarter'){
          findRequest.dataDateGte=moment().subtract(2, 'years');
        }
        if(dataInterval==='month'){
          findRequest.dataDateGte=moment().subtract(18, 'months');
        }
        if(dataInterval==='day'){
          findRequest.dataDateGte=moment().subtract(30, 'days');
        }
      }
      this.loading = true;
      this.httpService.post<any>(`/api/admin/stat/statistic/search`, findRequest).subscribe(response => {
        this.loading = false;
        if (response.resultList && response.resultList.length>0) {
          this.hasData=true;
          if(chartType==='pie'){
            var pieChartLabels = [];
            var pieChartData = [];
            for (var otpStatistic of response.resultList) {
              pieChartLabels.push(otpStatistic.dataLabel)
              pieChartData.push(otpStatistic.value)
            }
            this.pieChartData = pieChartData;
            this.pieChartLabels = pieChartLabels;
          }
          if(chartType==='bar'){
            var barChartLabels = [];
            var barChartData = [];
            var barChartSeriesMap={};
            var barChartLabelFormat="DD/MM/YYYY";
            if(dataInterval==='year'){
              barChartLabelFormat="YYYY";
            }
            if(dataInterval==='quarter'){
              barChartLabelFormat="Q/YYYY";
            }
            if(dataInterval==='month'){
              barChartLabelFormat="MM/YYYY";
            }
            for (var otpStatistic of response.resultList) {
              var momentValue=moment(otpStatistic.dataDate);
              var barChartLabel=momentValue.format(barChartLabelFormat);
              var seriesLabel = otpStatistic.dataLabel;
              if(barChartLabels.includes(barChartLabel)==false){
                barChartLabels.push(barChartLabel);
                for(var series of barChartData){
                  series.data.push(null);
                }
              }
              if(seriesLabel in barChartSeriesMap){
                var data=barChartSeriesMap[seriesLabel];
                data[barChartLabels.indexOf(barChartLabel)]=otpStatistic.value
              }else{
                var data=[];
                for(var label of barChartLabel){
                  data.push(null);
                }
                data[barChartLabels.indexOf(barChartLabel)]=otpStatistic.value;
                barChartSeriesMap[seriesLabel]=data;
                barChartData.push({
                  data:data,
                  label:seriesLabel
                })
              }
            }
            this.barChartLabels=barChartLabels;
            this.barChartData=barChartData;
          }
        } else if (response.errorCode) {
          this.error = response.errorCode + ":" + response.errorMessage;
        }
      }, errorResponse => {
        this.loading = false;
        if(errorResponse.status==403){
          this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
          return;
        }
        if (errorResponse.status > 0) {
          if (errorResponse.error?.errorCode) {
            this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
            return;
          }
          this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
          return;
        }
        this.error = "Lỗi kết nối! Vui lòng thử lại sau";
      })
    }

  }

}
