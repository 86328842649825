import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseHttpService } from 'src/app/service/base-http.service';
import * as moment from 'moment';
@Component({
  selector: 'app-stat-token-status',
  templateUrl: './stat-token-status.component.html',
  styleUrls: ['./stat-token-status.component.css']
})
export class StatTokenStatusComponent implements OnInit {
	error = null
	loading = false
  showChart=false;
  // Pie
  pieChartOptions: ChartOptions = {
    responsive: true,
  };
  pieChartLabels = [];
  pieChartData = [];
  pieChartType = 'pie';
  pieChartLegend = true;
  pieChartPlugins = [];
  pieChartColors = [
    {
      backgroundColor: [
        'rgba(255,0,0,.5)', 
        'rgba(0,255,0,.5)', 
        'rgba(0,0,255,.5)',
        'rgba(255,255,0,.5)', 
        'rgba(0,255,255,.5)', 
        'rgba(255,0,255,.5)',
        'rgba(192,0,0,.5)', 
        'rgba(0,192,0,.5)', 
        'rgba(0,0,192,.5)',
        'rgba(192,192,0,.5)', 
        'rgba(0,192,192,.5)', 
        'rgba(192,0,192,.5)',
        'rgba(128,0,0,.5)', 
        'rgba(0,128,0,.5)', 
        'rgba(0,0,128,.5)',
        'rgba(128,128,0,.5)', 
        'rgba(0,128,128,.5)', 
        'rgba(128,0,128,.5)',
        'rgba(64,0,0,.5)', 
        'rgba(0,64,0,.5)', 
        'rgba(0,0,64,.5)',
        'rgba(64,64,0,.5)', 
        'rgba(0,64,64,.5)', 
        'rgba(64,0,64,.5)'
      ],
    },
  ];

	constructor(private formBuilder: FormBuilder,
		private httpService: BaseHttpService,
		private route: ActivatedRoute,
		private router: Router) { }
	ngOnInit(): void {
    this.showStatisticChart();
	}
	showStatisticChart() {
		if(this.loading){
			return;
		}
    this.error=null;
    var  type="otp_token_status";
    var dataInterval="all"
    var findRequest:any = {
      type: type,
      dataInterval: dataInterval
    }
    this.loading = true;
    this.showChart=false;
    this.httpService.post<any>(`/api/admin/stat/statistic/search`, findRequest).subscribe(response => {
      this.loading = false;
      if (response.resultList) {
        if(response.resultList.length>0){
          var pieChartLabels = [];
          var pieChartData = [];
          for (var otpStatistic of response.resultList) {
            pieChartLabels.push(otpStatistic.dataLabel)
            pieChartData.push(otpStatistic.value)
          }
          this.pieChartData = pieChartData;
          this.pieChartLabels = pieChartLabels;
          this.showChart=true;
        }else{
          this.error="Không có dữ liệu";
        }
      } else if (response.errorCode) {
        this.error = response.errorCode + ":" + response.errorMessage;
      }
    }, errorResponse => {
      this.loading = false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if (errorResponse.status > 0) {
        if (errorResponse.error?.errorCode) {
          this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
          return;
        }
        this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
        return;
      }
      this.error = "Lỗi kết nối! Vui lòng thử lại sau";
    })
	}

  downloadStatisticExcel(){

		if(this.loading){
			return;
		}
    this.error=null;
    var  type="otp_token_status";
    var dataInterval="all"
    var findRequest:any = {
      type: type,
      dataInterval: dataInterval
    }
    var findRequest:any = {
      type: type,
      dataInterval: dataInterval
    }
    this.loading = true;
    this.showChart=false;
    this.httpService.postDownload(`/api/admin/stat/statistic/excel`, findRequest).subscribe(response => {
      this.loading = false;
        var downloadURL = window.URL.createObjectURL(response);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "statistic.xlsx";
        link.click();
    }, errorResponse => {
      this.loading = false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if (errorResponse.status > 0) {
        if (errorResponse.error?.errorCode) {
          this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
          return;
        }
        this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
        return;
      }
      this.error = "Lỗi kết nối! Vui lòng thử lại sau";
    })
  }
	viewLogging(otpLogging) {
		this.router.navigate(['view', otpLogging.id], { relativeTo: this.route })
	}
}


