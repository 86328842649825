import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-dev-device-detail',
  templateUrl: './dev-device-detail.component.html',
  styleUrls: ['./dev-device-detail.component.css']
})
export class DevDeviceDetailComponent implements OnInit {
	error=null
	loading = false
  hasEditPerm = false;
  hasTokenPerm = false;
	submitSuccess = false
  detailForm: FormGroup = this.formBuilder.group({
    id: ["", [Validators.required, Validators.maxLength(50)]],
    name: ["", [Validators.required, Validators.maxLength(200)]],
    devModelId: ["", [Validators.required, Validators.maxLength(200)]],
    devBrand: ["", [Validators.required, Validators.maxLength(200)]],
    devManufacturer: ["", [Validators.required, Validators.maxLength(200)]],
    devSystemName: ["", [Validators.required, Validators.maxLength(200)]],
    devSystemVersion: ["", [Validators.required, Validators.maxLength(200)]],
    devEmulator: ["", [Validators.required, Validators.maxLength(200)]],
    note: ["", [Validators.maxLength(2000)]],
    status: ["", [Validators.required]]
  });
  deviceId=null;
  deviceAction="create";
  showTokenButton=false;
  statusOptionList=[
		{ value : 0, label : "Ngừng sử dụng" },
		{ value : 1, label : "Đang sử dụng" },
		{ value : 2, label : "Đã bị khóa" }
  ]

  constructor(private authService: AuthService,private formBuilder: FormBuilder,private httpService:BaseHttpService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.authService.hasAnyPerm(['DEV_DEVICE']).subscribe(hasPerm => {
      this.hasEditPerm = hasPerm;
    })
    this.authService.hasAnyPerm(['OTP_TOKEN','OTP_TOKEN_VIEW']).subscribe(hasPerm => {
      this.hasTokenPerm = hasPerm;
    })
    this.detailForm.disable()
    this.route.params.subscribe(params=>{
      this.error=null;
      this.showTokenButton=true;
      if(params.tokenId){
        this.showTokenButton=false;
      }
      if(params.deviceId){
        this.deviceAction="view";
        if(params.deviceAction){
          this.deviceAction=params.deviceAction;
        }
        if(this.deviceAction!=="view"){
          this.detailForm.get('note').enable();
        }
        this.deviceId=params.deviceId;
        this.loading=true;
        this.httpService.get<any>(`/api/admin/dev/device/${this.deviceId}`).subscribe(response=>{
          this.loading=false;
          if(response.result){
            this.detailForm.patchValue(response.result);
          }else if(response.errorCode){
            this.error=response.errorCode+":"+response.errorMessage;
          }
        },errorResponse=>{
          this.loading=false;
          if(errorResponse.status==403){
            this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
            return;
          }
          if(errorResponse.status>0){
            if(errorResponse.error?.errorCode){
              this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
              return;
            }
            this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
            return;
          }
          this.error="Lỗi kết nối! Vui lòng thử lại sau";
        })
      }
    })
  }

  submit(){
		if(this.loading){
			return;
		}
    this.error=null;
    var detailFormData=this.detailForm.getRawValue()
    if(this.deviceAction==="lock"){
      this.loading=true;
      this.httpService.post<any>(`/api/admin/dev/device/${this.deviceId}/lock`,{note:detailFormData["note"]}).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.deviceAction==="unlock"){
      this.loading=true;
      this.httpService.post<any>(`/api/admin/dev/device/${this.deviceId}/unlock`,{note:detailFormData["note"]}).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
    if(this.deviceAction==="disable"){
      this.loading=true;
      this.httpService.post<any>(`/api/admin/dev/device/${this.deviceId}/disable`,{note:detailFormData["note"]}).subscribe(response=>{
        this.loading=false;
      if(response.result){
        this.submitSuccess=true;
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      this.loading=false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
    }
  }
  cancel(){
      this.router.navigate(['../../'], { relativeTo: this.route });
  }
  token(){
    this.router.navigate(['token'], { relativeTo: this.route });
  }
}
