<div class="card">
    <div class="card-body">
        <h5 class="card-title">Thông tin người dùng</h5>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <app-input-text label="Họ tên" placeholder="Nhập họ tên" [formGroup]="detailForm" formControlName="fullname"></app-input-text>
            <app-input-text label="Tên đăng nhập" placeholder="Nhập tên đăng nhập" [formGroup]="detailForm" formControlName="username"></app-input-text>
            <app-input-password label="Mật khẩu cũ" placeholder="Nhập mật khẩu cũ" [formGroup]="detailForm" formControlName="oldPassword"></app-input-password>
            <app-input-password label="Mật khẩu" placeholder="Nhập mật khẩu" [formGroup]="detailForm" formControlName="password" ></app-input-password>
            <app-input-password label="Nhập lại mật khẩu" placeholder="Nhập lại mật khẩu" [formGroup]="detailForm" formControlName="confirmPassword" ></app-input-password>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Đổi mật khẩu</button>
            </div>
            </div>
        </form>
    </div>
</div>