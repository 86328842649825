import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import * as cryptoJS from 'crypto-js';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error=null;
  action=null;
  loginForm: FormGroup = this.formBuilder.group({
    username: ["", [Validators.required, Validators.maxLength(50)]],
    password: ["", [Validators.required, Validators.maxLength(100)]]
 });

  constructor(private formBuilder: FormBuilder,private authService:AuthService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    if(this.authService.isAuthenticated){
      this.authService.logout().subscribe(
        response=>{},
        errorResponse=>{}
      );
    }
    this.route.params.subscribe(parms=>{
      if(parms.action){
        this.action=parms.action;
      }
    })
  }

  login(){
    this.error=null;
    this.action=null;
    var loginData=this.loginForm.getRawValue();
    var passwordSHA256=cryptoJS.HmacSHA256(loginData.username, loginData.password);
    this.authService.login({username:loginData.username,password:`${passwordSHA256}`}).subscribe(response=>{
      if(response.result){
        this.router.navigate(['/']);
      }else if(response.errorCode){
        this.error=response.errorCode+":"+response.errorMessage;
      }
    },errorResponse=>{
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
    })
  }

}
