<div class="card" *ngIf="!submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông tin ứng dụng</h5>
        <div class="alert alert-danger" role="alert" *ngIf="applicationAction==='delete'">
          Bạn có chắc chắn muốn xóa ứng dụng
        </div>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <app-input-text label="Mã" placeholder="Nhập mã" [formGroup]="detailForm" formControlName="code"></app-input-text>
            <app-input-text label="Tên" placeholder="Nhập tên" [formGroup]="detailForm" formControlName="name"></app-input-text>
            <app-input-text label="Ocra suite giá trị thấp" placeholder="Nhập ocra suite giá trị thấp" [formGroup]="detailForm" formControlName="ocraSuiteLowValue"></app-input-text>
            <app-input-text label="Ocra suite giá trị cao" placeholder="Nhập ocra suite giá trị cao" [formGroup]="detailForm" formControlName="ocraSuiteHighValue"></app-input-text>
            <app-input-textarea label="Public key" placeholder="Nhập Public key" [formGroup]="detailForm" formControlName="publicKey" rows="5"></app-input-textarea>
            <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="detailForm" formControlName="status" [optionList]="statusOptionList"></app-input-select>
            <app-input-textarea label="Mô tả" placeholder="Nhập mô tả" [formGroup]="detailForm" formControlName="description" rows="5"></app-input-textarea>
            <app-input-textarea label="Ghi chú" placeholder="Nhập ghi chú" [formGroup]="detailForm" formControlName="note" rows="5"></app-input-textarea>
            <app-input-text label="Đường dẫn đồng bộ trạng thái" placeholder="Nhập đường dẫn đồng bộ trạng thái" [formGroup]="detailForm" formControlName="updateStatusUrl"></app-input-text>
            <app-input-select label="Trạng thái đồng bộ" placeholder="Chọn trạng thái đồng bộ" [formGroup]="detailForm" formControlName="syncStatus" [optionList]="syncStatusOptionList"></app-input-select>
            <app-input-select label="Key xác thực gọi API" placeholder="Chọn key xác thực gọi API" [formGroup]="detailForm" formControlName="authenticationKeyId" [optionList]="sysRsaKeyOptionList" *ngIf="sysRsaKeyOptionList"></app-input-select>
            <ng-container *ngIf="sysApplicationPermList">
                <div class="form-check" *ngFor="let sysApplicationPerm of sysApplicationPermList">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" [disabled]="applicationAction!=='create'&&applicationAction!=='edit'" [checked]="!sysApplicationPerm.deletedEntity" (change)="sysApplicationPerm.deletedEntity = !sysApplicationPerm.deletedEntity">{{sysApplicationPerm.sysPerm.code}} - {{sysApplicationPerm.sysPerm.name}}
                    </label>
                  </div>
            </ng-container>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
                <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="hasEditPerm&&applicationAction==='create'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Thêm mới</button>
                <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="hasEditPerm&&applicationAction==='edit'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Cập nhật</button>
                <button type="button" class="btn btn-danger" (click)="submit()" *ngIf="hasEditPerm&&applicationAction==='delete'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Xóa</button>
            </div>
            </div>
        </form>
    </div>
</div>
<div class="card" *ngIf="submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông báo</h5>
        <div class="alert alert-success" role="alert" *ngIf="applicationAction==='create'">
          Thêm mới thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="applicationAction==='edit'">
          Cập nhật thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="applicationAction==='delete'">
          Xóa thành công
        </div>
        <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
            </div>
        </div>
    </div>
</div>