import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { SearchService } from 'src/app/service/search.service';

@Component({
  selector: 'app-otp-menu',
  templateUrl: './otp-menu.component.html',
  styleUrls: ['./otp-menu.component.css']
})
export class OtpMenuComponent implements OnInit {
  userMenuList = null;
  menuAllList = [
    {
      "navigation": ['/otp/request'],
      "title": "Đề nghị",
      "permCodeList": ["OTP_SUPERVISOR","OTP_INITIALIZATION", "OTP_TOKEN"]
    },
    {
      "navigation": ['/otp/initialization'],
      "title": "Tạo mã kích hoạt",
      "permCodeList": ["OTP_INITIALIZATION"]
    },
    {
      "navigation": ['/otp/token'],
      "title": "Otp Token",
      "permCodeList": [ "OTP_TOKEN", "OTP_TOKEN_VIEW"]
    },
    {
      "navigation": ['/otp/logging'],
      "title": "Nhật ký",
      "permCodeList": ["OTP_LOGGING"]
    }
  ]
  constructor(private authService: AuthService,
		private searchService:SearchService,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.authService.getUserPerm().subscribe(userdetailResponse => {
      if (userdetailResponse.resultList) {
        var sysPermCodeList = userdetailResponse.resultList;
        if (sysPermCodeList != null) {
          var userMenuList = [];
          for (var menu of this.menuAllList) {
            for (var permCode of menu.permCodeList) {
              if (sysPermCodeList.includes(permCode)) {
                userMenuList.push(menu);
                break;
              }
            }
          }
          this.userMenuList = userMenuList;
        }
      }
    },errorResponse=>{
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
		})
  }

  navigation(routerLink){
    this.searchService.clear();
    this.router.navigate(routerLink, { relativeTo: this.route });
  }

}
