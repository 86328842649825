import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseHttpService } from 'src/app/service/base-http.service';
import * as moment from 'moment';

@Component({
  selector: 'app-stat-token-verify-error',
  templateUrl: './stat-token-verify-error.component.html',
  styleUrls: ['./stat-token-verify-error.component.css']
})
export class StatTokenVerifyErrorComponent implements OnInit {
	error = null
	loading = false
	searchForm: FormGroup = this.formBuilder.group({
		dataInterval: ["day", [Validators.required]],
		dataDateGte: [null, [Validators.required]],
		dataDateLte: [null, [Validators.required]]
	});
	reportDataIntervalOptionList = [
		{ value: "all", label: "Tất cả" },
		{ value: "year", label: "Năm" },
		{ value: "quarter", label: "Quý" },
		{ value: "month", label: "Tháng" },
		{ value: "day", label: "Ngày" }
	]
	reportDataIntervalOptionMap = {}
  showByTime=true;
  showChart=false;
  chartType="pie"
  // Pie
  pieChartOptions: ChartOptions = {
    responsive: true,
  };
  pieChartLabels = [];
  pieChartData = [];
  pieChartType = 'pie';
  pieChartLegend = true;
  pieChartPlugins = [];
  pieChartColors = [
    {
      backgroundColor: [
        'rgba(255,0,0,.5)', 
        'rgba(0,255,0,.5)', 
        'rgba(0,0,255,.5)',
        'rgba(255,255,0,.5)', 
        'rgba(0,255,255,.5)', 
        'rgba(255,0,255,.5)',
        'rgba(192,0,0,.5)', 
        'rgba(0,192,0,.5)', 
        'rgba(0,0,192,.5)',
        'rgba(192,192,0,.5)', 
        'rgba(0,192,192,.5)', 
        'rgba(192,0,192,.5)',
        'rgba(128,0,0,.5)', 
        'rgba(0,128,0,.5)', 
        'rgba(0,0,128,.5)',
        'rgba(128,128,0,.5)', 
        'rgba(0,128,128,.5)', 
        'rgba(128,0,128,.5)',
        'rgba(64,0,0,.5)', 
        'rgba(0,64,0,.5)', 
        'rgba(0,0,64,.5)',
        'rgba(64,64,0,.5)', 
        'rgba(0,64,64,.5)', 
        'rgba(64,0,64,.5)'
      ],
    },
  ];
  //bar
  barChartOptions: ChartOptions = {
    responsive: true,
    scales:{
      yAxes:[
        {
          display: true,
          ticks: {
            suggestedMin: 0,
            beginAtZero: true
          }
        }
      ]
    }
  };
  barChartLabels= [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData= [];

	constructor(private formBuilder: FormBuilder,
		private httpService: BaseHttpService,
		private route: ActivatedRoute,
		private router: Router) { }
	ngOnInit(): void {
		for (var reportDataIntervalOption of this.reportDataIntervalOptionList) {
			this.reportDataIntervalOptionMap[reportDataIntervalOption.value] = reportDataIntervalOption.label;
		}
    this.searchForm.patchValue({
      dataDateGte:moment().subtract(30,'days'),
      dataDateLte:moment().toISOString()
    })
    this.showStatisticChart();
	}

  dataIntervalChange(dataInterval){
    if(dataInterval==='all'){
      this.showByTime=false;
    }else{
      this.showByTime=true;
    }
  }
	showStatisticChart() {
		if(this.loading){
			return;
		}
    this.error=null;
    var searchFormData=this.searchForm.getRawValue();
    var  type="otp_token_verify_error";
    var  dataInterval=searchFormData["dataInterval"];
    var chartType="pie";
    if(dataInterval!=='all'){
      chartType="bar";
    }else{
      dataInterval='all'
    }
    var findRequest:any = {
      type: type,
      dataInterval: dataInterval
    }
    if(dataInterval!=='all'){
      var dataDateGte = searchFormData["dataDateGte"];
      if (dataDateGte) {
        findRequest.dataDateGte = dataDateGte;
      }
      var dataDateLte = searchFormData["dataDateLte"];
      if (dataDateLte) {
        findRequest.dataDateLte = dataDateLte;
      }
    }
    this.loading = true;
    this.showChart=false;
    this.httpService.post<any>(`/api/admin/stat/statistic/search`, findRequest).subscribe(response => {
      this.loading = false;
      if (response.resultList) {
        if(response.resultList.length>0){
        if(chartType==='pie'){
          var pieChartLabels = [];
          var pieChartData = [];
          for (var otpStatistic of response.resultList) {
            pieChartLabels.push(otpStatistic.dataLabel)
            pieChartData.push(otpStatistic.value)
          }
          this.chartType=chartType;
          this.pieChartData = pieChartData;
          this.pieChartLabels = pieChartLabels;
          this.showChart=true;
        }
        if(chartType==='bar'){
          var barChartLabels = [];
          var barChartData = [];
          var barChartSeriesMap={};
          var barChartLabelFormat="DD/MM/YYYY";
          if(dataInterval==='year'){
            barChartLabelFormat="YYYY";
          }
          if(dataInterval==='quarter'){
            barChartLabelFormat="Q/YYYY";
          }
          if(dataInterval==='month'){
            barChartLabelFormat="MM/YYYY";
          }
          for (var otpStatistic of response.resultList) {
            var momentValue=moment(otpStatistic.dataDate);
            var barChartLabel=momentValue.format(barChartLabelFormat);
            var seriesLabel = otpStatistic.dataLabel;
            if(barChartLabels.includes(barChartLabel)==false){
              barChartLabels.push(barChartLabel);
              for(var series of barChartData){
                series.data.push(null);
              }
            }
            if(seriesLabel in barChartSeriesMap){
              var data=barChartSeriesMap[seriesLabel];
              data[barChartLabels.indexOf(barChartLabel)]=otpStatistic.value
            }else{
              var data=[];
              for(var label of barChartLabel){
                data.push(null);
              }
              data[barChartLabels.indexOf(barChartLabel)]=otpStatistic.value;
              barChartSeriesMap[seriesLabel]=data;
              barChartData.push({
                data:data,
                label:seriesLabel
              })
            }
          }
          this.chartType=chartType;
          this.barChartLabels=barChartLabels;
          this.barChartData=barChartData;
          this.showChart=true;
        }
      }else{
        this.error="Không có dữ liệu";
      }
      } else if (response.errorCode) {
        this.error = response.errorCode + ":" + response.errorMessage;
      }
    }, errorResponse => {
      this.loading = false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if (errorResponse.status > 0) {
        if (errorResponse.error?.errorCode) {
          this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
          return;
        }
        this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
        return;
      }
      this.error = "Lỗi kết nối! Vui lòng thử lại sau";
    })
	}

  downloadStatisticExcel(){
		if(this.loading){
			return;
		}
    this.error=null;
    var searchFormData=this.searchForm.getRawValue();
    var  type="otp_token_verify_error";
    var  dataInterval=searchFormData["dataInterval"];
    var chartType="pie";
    if(dataInterval!=='all'){
      chartType="bar";
    }else{
      dataInterval='all'
    }
    var findRequest:any = {
      type: type,
      dataInterval: dataInterval
    }
    if(dataInterval!=='all'){
      var dataDateGte = searchFormData["dataDateGte"];
      if (dataDateGte) {
        findRequest.dataDateGte = dataDateGte;
      }
      var dataDateLte = searchFormData["dataDateLte"];
      if (dataDateLte) {
        findRequest.dataDateLte = dataDateLte;
      }
    }
    this.loading = true;
    this.showChart=false;
    this.httpService.postDownload(`/api/admin/stat/statistic/excel`, findRequest).subscribe(response => {
      this.loading = false;
        var downloadURL = window.URL.createObjectURL(response);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "statistic.xlsx";
        link.click();
    }, errorResponse => {
      this.loading = false;
      if(errorResponse.status==403){
        this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
        return;
      }
      if (errorResponse.status > 0) {
        if (errorResponse.error?.errorCode) {
          this.error = errorResponse.error.errorCode + (errorResponse.error.errorMessage ? ":" + errorResponse.error.errorMessage : "");
          return;
        }
        this.error = errorResponse.status + ":" + errorResponse.error.error + (errorResponse.error.message ? ":" + errorResponse.error.message : "");
        return;
      }
      this.error = "Lỗi kết nối! Vui lòng thử lại sau";
    })
  }
	viewLogging(otpLogging) {
		this.router.navigate(['view', otpLogging.id], { relativeTo: this.route })
	}
}


