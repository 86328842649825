<div class="container">
  <div class="d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img src="./assets/logo.jpg" />
      <div style="width:500px;height: 500px;">
        <form [formGroup]="loginForm">
          <div class="alert alert-danger" role="alert" *ngIf="error">
            {{error}}
          </div>
          <div class="alert alert-success" role="alert" *ngIf="action==='logout'">
              Logout thành công
          </div>
          <div class="alert alert-success" role="alert" *ngIf="action==='password'">
              Đổi mật khẩu thành công
          </div>
          <div class="alert alert-success" role="alert" *ngIf="action==='sessionExpired'">
              Phiên làm việc đã hết hạn
          </div>
          <div class="form-group">
            <label for="username">Tên đăng nhập</label>
            <input type="text" class="form-control" id="username" name="username" formControlName="username"
              placeholder="Tên đăng nhập">
          </div>
          <div class="form-group">
            <label for="password">Mật khẩu</label>
            <input type="password" class="form-control" id="password" name="password" formControlName="password"
              placeholder="Mật khẩu">
          </div>
          <div class="d-flex justify-content-center">
          <div class="btn-group">
            <button type="submit" class="btn btn-primary" (click)="login()">Đăng nhập</button>
          </div>
      </div>
        </form>
      </div>
    </div>
  </div>
</div>