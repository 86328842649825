import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { BaseHttpService } from 'src/app/service/base-http.service';

@Component({
  selector: 'app-sys-rsa-key-list',
  templateUrl: './sys-rsa-key-list.component.html',
  styleUrls: ['./sys-rsa-key-list.component.css']
})
export class SysRsaKeyListComponent implements OnInit {
	error=null
	loading = false
	hasEditPerm = false;
	sysRsaKeyList=null
	sysRsaKeyPagination={
		currentPage:1,
		pageSize:10,
		pageCount:0,
		rowCount:0,
		pageList:null
	}
	searchForm: FormGroup=this.formBuilder.group({
		idLike: ['', [Validators.maxLength(50)]],
		nameLike: ['', [Validators.maxLength(200)]],
		type: [null ],
		status: [null ]
	});
	rsaKeyTypeOptionList=[
		{ value : null, label : "Tất cả" },
		{value:"MASTER",label:"Key chính"},
		{value:"SLAVE",label:"Key phụ"},
		{value:"API_CALL_KEY",label:"Key xác thực gọi API"}
	]
	rsaKeyTypeOptionMap={}
	rsaKeyStatusOptionList=[
		{ value : null, label : "Tất cả" },
		{ value : 0, label : "Ngừng sử dụng" },
		{ value : 1, label : "Sử dụng" }
	]
	rsaKeyStatusOptionMap={}
	constructor(private authService: AuthService,private formBuilder: FormBuilder,
	private httpService:BaseHttpService,
	private route: ActivatedRoute,
	private router: Router ) { }
	ngOnInit(): void {
		this.authService.hasAnyPerm(['SYS_RSA_KEY']).subscribe(hasPerm => {
		  this.hasEditPerm = hasPerm;
		})
		for(var rsaKeyTypeOption of this.rsaKeyTypeOptionList){
			this.rsaKeyTypeOptionMap[rsaKeyTypeOption.value]=rsaKeyTypeOption.label;
		}
		for(var rsaKeyStatusOption of this.rsaKeyStatusOptionList){
			this.rsaKeyStatusOptionMap[rsaKeyStatusOption.value]=rsaKeyStatusOption.label;
		}
		this.searchRsaKey();
	}

	sysRsaKeyPageChange(page){
		if(this.loading){
			return;
		}
		this.error=null;
		var searchFormData=this.searchForm.getRawValue()
		var findRequest:any = {}
		var idLike = searchFormData["idLike"];
		if (idLike) {
			findRequest.idLike = `%${idLike}%`;
		}
		var nameLike = searchFormData["nameLike"];
		if (nameLike) {
			findRequest.nameLike = `%${nameLike}%`;
		}
		var type = searchFormData["type"];
		if(type !== null){
			findRequest.type= type;
		}
		var status = searchFormData["status"];
		if(status !== null){
			findRequest.status= status;
		}
		if(page<1){
			page=1
		}
		findRequest.queryOffset=(page-1)*this.sysRsaKeyPagination.pageSize;
		findRequest.queryLimit=this.sysRsaKeyPagination.pageSize;
		this.loading=true;
		this.httpService.post<any>(`/api/admin/sys/rsa-key/search`,findRequest).subscribe(findOtpRsaKeyResponse=>{
			this.loading=false;
			var rowCount = findOtpRsaKeyResponse.rowCount;
			var pageCount = Math.ceil(rowCount/this.sysRsaKeyPagination.pageSize);
			var pageList=[]
			for(var i=page-2;i<=page+2;i++){
				if(i>=1&&i<=pageCount){
					pageList.push(i);
				}
			}
			this.sysRsaKeyPagination.currentPage=page;
			this.sysRsaKeyPagination.rowCount=rowCount;
			this.sysRsaKeyPagination.pageCount=pageCount;
			this.sysRsaKeyPagination.pageList=pageList;
			this.sysRsaKeyList= findOtpRsaKeyResponse.resultList;
		},errorResponse=>{
			this.loading=false;
			if(errorResponse.status==403){
			  this.router.navigate(['/login', { action: 'sessionExpired' }], { relativeTo: this.route });
			  return;
			}
      if(errorResponse.status>0){
        if(errorResponse.error?.errorCode){
          this.error=errorResponse.error.errorCode+(errorResponse.error.errorMessage?":"+errorResponse.error.errorMessage:"");
          return;
        }
        this.error=errorResponse.status+":"+errorResponse.error.error+(errorResponse.error.message?":"+errorResponse.error.message:"");
        return;
      }
      this.error="Lỗi kết nối! Vui lòng thử lại sau";
		});
	}
	searchRsaKey(){
		this.sysRsaKeyPageChange(1);
	}
	viewRsaKey(sysRsaKey){
		this.router.navigate(['view',sysRsaKey.id],{ relativeTo: this.route })
	}
	insertRsaKey(){
		this.router.navigate(['create'],{ relativeTo: this.route })
	}
	editRsaKey(sysRsaKey){
		this.router.navigate(['edit',sysRsaKey.id],{ relativeTo: this.route })
	}
	deleteRsaKey(sysRsaKey){
		this.router.navigate(['delete',sysRsaKey.id],{ relativeTo: this.route })
	}
}


