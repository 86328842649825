<div class="card">
    <div class="card-body">
        <h5 class="card-title">Tìm kiếm nhật ký</h5>
        <form [formGroup]="searchForm">
            <div class="row">
                <div class="col">
                    <app-input-text label="Mã ứng dụng" placeholder="Nhập mã ứng dụng" [formGroup]="searchForm" formControlName="createApplicationCodeLike"></app-input-text>
                </div>
                <div class="col">
                    <app-input-text label="Người thực hiện" placeholder="Nhập người thực hiện" [formGroup]="searchForm" formControlName="createUsernameLike"></app-input-text>
                </div>
            </div>
            <div class="row">
                    <div class="col">
                        <app-input-select label="Thao tác" placeholder="Chọn thao tác" [formGroup]="searchForm" formControlName="action" [optionList]="loggingActionOptionList"></app-input-select>
                    </div>
                <div class="col">
                    <app-input-text label="Dữ liệu" placeholder="Nhập dữ liệu" [formGroup]="searchForm" formControlName="loggingDataLike"></app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-input-date label="Từ ngày" placeholder="Nhập từ ngày"[formGroup]="searchForm" formControlName="createDateGt"></app-input-date>
                </div>
                <div class="col">
                    <app-input-date label="Đến ngày" placeholder="Nhập đến ngày"[formGroup]="searchForm" formControlName="createDateLt"></app-input-date>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-center">
        <div class="btn-group">
            <button type="button" class="btn btn-primary" (click)="searchLogging()" [disabled]="loading"><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Tìm kiếm</button>
        </div>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h5 class="card-title">Kết quả tìm kiếm</h5>
        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{error}}
        </div>
        <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Thao tác</th>
                    <th scope="col">Dữ liệu</th>
                    <th scope="col">Từ ứng dụng</th>
                    <th scope="col">Người thực hiện</th>
                    <th scope="col">Ngày thực hiện</th>
                    <th scope="col">
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="devLoggingList && !loading">
                <tr *ngFor="let devLogging of devLoggingList;index as devLoggingIndex">
                    <td scope="col">{{(devLoggingPagination.currentPage-1)*devLoggingPagination.pageSize + devLoggingIndex+1}}</td>
                    <td scope="col">{{loggingActionOptionMap[devLogging.action]}}</td>
                    <td scope="col">{{devLogging.loggingData}}</td>
                    <td scope="col">{{devLogging.createApplicationCode}}</td>
                    <td scope="col">{{devLogging.createUsername}}</td>
                    <td scope="col">{{devLogging.createDate | date:'dd/MM/yyyy, HH:mm:ss'}}</td>
                    <td scope="col">
                        <div class="btn-group">
                            <button type="button" class="btn btn-info" (click)="viewLogging(devLogging)">Xem</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
        <div class="d-flex justify-content-between align-items-center" *ngIf="devLoggingPagination.pageCount>1 && !loading">
            <nav>
                <ul class="pagination">
                    <li class="page-item" [ngClass]="{'disabled':devLoggingPagination.currentPage == 1}">
                      <a class="page-link" (click)="devLoggingPageChange(devLoggingPagination.currentPage-1)">Trước</a>
                    </li>
                    <li class="page-item" *ngIf="devLoggingPagination.currentPage > 3">
                      <a class="page-link" (click)="devLoggingPageChange(1)">1</a>
                    </li>
                  <li class="page-item disabled" *ngIf="devLoggingPagination.currentPage > 3">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngFor="let page of devLoggingPagination.pageList"  [ngClass]="{'active':devLoggingPagination.currentPage == page}">
                    <a class="page-link" (click)="devLoggingPageChange(page)">{{page}}</a>
                  </li>
                  <li class="page-item disabled" *ngIf="devLoggingPagination.currentPage < devLoggingPagination.pageCount - 2">
                    <a class="page-link" >...</a>
                  </li>
                  <li class="page-item" *ngIf="devLoggingPagination.currentPage < devLoggingPagination.pageCount - 2">
                    <a class="page-link" (click)="devLoggingPageChange(devLoggingPagination.pageCount)">{{devLoggingPagination.pageCount}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{'disabled':devLoggingPagination.currentPage == devLoggingPagination.pageCount}">
                    <a class="page-link"  (click)="devLoggingPageChange(devLoggingPagination.currentPage+1)">Sau</a>
                  </li>
                </ul>
              </nav>
        </div>
    </div>
</div>

