<div class="card" *ngIf="!submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông tin đề nghị</h5>
        <div class="alert alert-success" role="alert" *ngIf="requestAction==='teller-approve'">
          Bạn có chắc chắn muốn gửi đề nghị
        </div>
        <div class="alert alert-success" role="alert" *ngIf="requestAction==='supervisor-approve'">
          Bạn có chắc chắn muốn phê duyệt đề nghị
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="requestAction==='supervisor-reject'">
          Bạn có chắc chắn muốn từ chối đề nghị
        </div>
        <form [formGroup]="detailForm">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{error}}
            </div>
            <app-input-select label="Loại đề nghị" placeholder="Chọn loại đề nghị" [formGroup]="detailForm" formControlName="action" [optionList]="requestActionOptionList"></app-input-select>
            <app-input-text label="Tài khoản" placeholder="Nhập tài khoản" [formGroup]="detailForm" formControlName="accountId"></app-input-text>
            <app-input-text label="Ứng dụng" placeholder="Nhập ứng dụng" [formGroup]="detailForm" formControlName="createApplicationCode"></app-input-text>
            <app-input-date label="Ngày tạo" placeholder="Nhập ngày tạo" [formGroup]="detailForm" formControlName="createDate"></app-input-date>
            <app-input-select label="Trạng thái" placeholder="Chọn trạng thái" [formGroup]="detailForm" formControlName="status" [optionList]="statusOptionList"></app-input-select>
            <app-input-textarea label="Ghi chú" placeholder="Nhập ghi chú" [formGroup]="detailForm" formControlName="note" rows="5"></app-input-textarea>
            <ng-container *ngIf="initializationCode">
              <h1 class="display-4 text-center">Mã kích hoạt của khách hàng là:</h1>
              <h1 class="display-4 text-center">{{initializationCode}}</h1>
              <ng-container *ngIf="qrImageUrl">
                  <h1 class="display-4 text-center">Qr code:</h1>
                  <div  class="text-center">
                      <img [src]="qrImageUrl" [alt]="initializationCode"/>
                  </div>
              </ng-container>
            </ng-container>
            <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
                <button type="button" class="btn btn-success" (click)="submit()" *ngIf="requestAction==='teller-approve'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Gửi</button>
                <button type="button" class="btn btn-success" (click)="submit()" *ngIf="hasSupervisorPerm&&requestAction==='supervisor-approve'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Phê duyệt</button>
                <button type="button" class="btn btn-warning" (click)="submit()" *ngIf="hasSupervisorPerm&&requestAction==='supervisor-reject'" [disabled]="loading || detailForm.status === 'INVALID' "><span *ngIf="loading"  class="spinner-border spinner-border-sm"></span>Từ chối</button>
            </div>
        </div>
        </form>
    </div>
</div>
<div class="card" *ngIf="submitSuccess">
    <div class="card-body">
        <h5 class="card-title">Thông báo</h5>
        <div class="alert alert-success" role="alert" *ngIf="requestAction==='teller-approve'">
          Gửi thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="requestAction==='supervisor-approve'">
          Phê duyệt thành công
        </div>
        <div class="alert alert-success" role="alert" *ngIf="requestAction==='supervisor-reject'">
          Từ chối thành công
        </div>
        <div class="d-flex justify-content-center">
            <div class="btn-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()">Trở về</button>
            </div>
        </div>
    </div>
</div>