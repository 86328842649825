import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import {DpDatePickerModule} from 'ng2-date-picker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InputTextComponent } from './components/common/input-text/input-text.component';
import { InputTextareaComponent } from './components/common/input-textarea/input-textarea.component';
import { InputPasswordComponent } from './components/common/input-password/input-password.component';
import { InputDateComponent } from './components/common/input-date/input-date.component';
import { InputSelectComponent } from './components/common/input-select/input-select.component';
import { InputAutocompleteComponent } from './components/common/input-autocomplete/input-autocomplete.component';
import { MenuComponent } from './components/system/menu/menu.component';
import { LoginComponent } from './components/system/login/login.component';
import { DashboardComponent } from './components/system/dashboard/dashboard.component';
import { OtpMenuComponent } from './components/otp/otp-menu/otp-menu.component';
import { SystemMenuComponent } from './components/system/system-menu/system-menu.component';
import { SysLoggingListComponent } from './components/system/sys-logging/sys-logging-list/sys-logging-list.component';
import { SysLoggingDetailComponent } from './components/system/sys-logging/sys-logging-detail/sys-logging-detail.component';
import { SysUserDetailComponent } from './components/system/sys-user/sys-user-detail/sys-user-detail.component';
import { SysUserListComponent } from './components/system/sys-user/sys-user-list/sys-user-list.component';
import { SysPermListComponent } from './components/system/sys-perm/sys-perm-list/sys-perm-list.component';
import { SysPermDetailComponent } from './components/system/sys-perm/sys-perm-detail/sys-perm-detail.component';
import { OtpLoggingDetailComponent } from './components/otp/otp-logging/otp-logging-detail/otp-logging-detail.component';
import { OtpLoggingListComponent } from './components/otp/otp-logging/otp-logging-list/otp-logging-list.component';
import { OtpTokenDetailComponent } from './components/otp/otp-token/otp-token-detail/otp-token-detail.component';
import { OtpTokenListComponent } from './components/otp/otp-token/otp-token-list/otp-token-list.component';
import { OtpInitializationComponent } from './components/otp/otp-initialization/otp-initialization.component';
import { DevDeviceListComponent } from './components/dev/dev-device/dev-device-list/dev-device-list.component';
import { DevDeviceDetailComponent } from './components/dev/dev-device/dev-device-detail/dev-device-detail.component';
import { DeviceMenuComponent } from './components/dev/device-menu/device-menu.component';
import { DevLoggingListComponent } from './components/dev/dev-logging/dev-logging-list/dev-logging-list.component';
import { DevLoggingDetailComponent } from './components/dev/dev-logging/dev-logging-detail/dev-logging-detail.component';
import { DevModelDetailComponent } from './components/dev/dev-model/dev-model-detail/dev-model-detail.component';
import { DevModelListComponent } from './components/dev/dev-model/dev-model-list/dev-model-list.component';
import { DevBrandListComponent } from './components/dev/dev-brand/dev-brand-list/dev-brand-list.component';
import { DevBrandDetailComponent } from './components/dev/dev-brand/dev-brand-detail/dev-brand-detail.component';
import { DevManufacturerDetailComponent } from './components/dev/dev-manufacturer/dev-manufacturer-detail/dev-manufacturer-detail.component';
import { DevManufacturerListComponent } from './components/dev/dev-manufacturer/dev-manufacturer-list/dev-manufacturer-list.component';
import { DevSystemListComponent } from './components/dev/dev-system/dev-system-list/dev-system-list.component';
import { DevSystemDetailComponent } from './components/dev/dev-system/dev-system-detail/dev-system-detail.component';
import { DevSystemVersionListComponent } from './components/dev/dev-system-version/dev-system-version-list/dev-system-version-list.component';
import { DevSystemVersionDetailComponent } from './components/dev/dev-system-version/dev-system-version-detail/dev-system-version-detail.component';
import { SysApplicationListComponent } from './components/system/sys-application/sys-application-list/sys-application-list.component';
import { SysApplicationDetailComponent } from './components/system/sys-application/sys-application-detail/sys-application-detail.component';
import { SysRsaKeyListComponent } from './components/system/sys-rsa-key/sys-rsa-key-list/sys-rsa-key-list.component';
import { SysRsaKeyDetailComponent } from './components/system/sys-rsa-key/sys-rsa-key-detail/sys-rsa-key-detail.component';
import { UserPasswordComponent } from './components/system/user-password/user-password.component';
import { SysBranchListComponent } from './components/system/sys-branch/sys-branch-list/sys-branch-list.component';
import { SysBranchDetailComponent } from './components/system/sys-branch/sys-branch-detail/sys-branch-detail.component';
import { SysRoleDetailComponent } from './components/system/sys-role/sys-role-detail/sys-role-detail.component';
import { SysRoleListComponent } from './components/system/sys-role/sys-role-list/sys-role-list.component';
import { OtpRequestListComponent } from './components/otp/otp-request/otp-request-list/otp-request-list.component';
import { OtpRequestDetailComponent } from './components/otp/otp-request/otp-request-detail/otp-request-detail.component';
import { StatStatisticChartComponent } from './components/stat/stat-statistic-chart/stat-statistic-chart.component';
import { StatMenuComponent } from './components/stat/stat-menu/stat-menu.component';
import { StatTokenStatusComponent } from './components/stat/stat-token-status/stat-token-status.component';
import { StatTokenEnrollComponent } from './components/stat/stat-token-enroll/stat-token-enroll.component';
import { StatTokenVerifyErrorComponent } from './components/stat/stat-token-verify-error/stat-token-verify-error.component';
import { StatDeviceSystemComponent } from './components/stat/stat-device-system/stat-device-system.component';
import { StatDeviceBrandComponent } from './components/stat/stat-device-brand/stat-device-brand.component';
import { StatDeviceManufacturerComponent } from './components/stat/stat-device-manufacturer/stat-device-manufacturer.component';

@NgModule({
  declarations: [
    AppComponent,
    InputDateComponent,
    InputTextComponent,
    InputTextareaComponent,
    InputPasswordComponent,
    InputSelectComponent,
    InputAutocompleteComponent,
    MenuComponent,
    LoginComponent,
    DashboardComponent,
    OtpMenuComponent,
    SystemMenuComponent,
    SysLoggingListComponent,
    SysLoggingDetailComponent,
    SysUserDetailComponent,
    SysUserListComponent,
    SysPermListComponent,
    SysPermDetailComponent,
    OtpLoggingDetailComponent,
    OtpLoggingListComponent,
    OtpTokenDetailComponent,
    OtpTokenListComponent,
    OtpInitializationComponent,
    DevDeviceListComponent,
    DevDeviceDetailComponent,
    DeviceMenuComponent,
    DevLoggingListComponent,
    DevLoggingDetailComponent,
    DevModelDetailComponent,
    DevModelListComponent,
    DevBrandListComponent,
    DevBrandDetailComponent,
    DevManufacturerDetailComponent,
    DevManufacturerListComponent,
    DevSystemListComponent,
    DevSystemDetailComponent,
    DevSystemVersionListComponent,
    DevSystemVersionDetailComponent,
    SysApplicationListComponent,
    SysApplicationDetailComponent,
    SysRsaKeyListComponent,
    SysRsaKeyDetailComponent,
    UserPasswordComponent,
    SysBranchListComponent,
    SysBranchDetailComponent,
    SysRoleDetailComponent,
    SysRoleListComponent,
    OtpRequestListComponent,
    OtpRequestDetailComponent,
    InputSelectComponent,
    InputAutocompleteComponent,
    StatStatisticChartComponent,
    StatMenuComponent,
    StatTokenStatusComponent,
    StatTokenEnrollComponent,
    StatTokenVerifyErrorComponent,
    StatDeviceSystemComponent,
    StatDeviceBrandComponent,
    StatDeviceManufacturerComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ChartsModule,
    DpDatePickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
